import React from 'react'

import { FormattedMessage } from 'react-intl'
import axios from 'axios';
import moment from 'moment';
import { StopOutlined, CloseCircleOutlined, QuestionCircleOutlined, FormOutlined, DownOutlined, UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { Modal, Row, Col, Input, Upload, Dropdown, Menu, Popover, Button, Table, Spin, Typography, message, Card, Tag } from "antd"
import domain from '../../../domain';


const { Title, Text } = Typography


function TranslationFiles(props) {
    
    const uploadFile = async ({ request_id, onSuccess, onError, file }) => {
        const formData = new FormData()

        formData.append("file", file, file.name)

        const token = localStorage.getItem("token")
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.defaults.xsrfCookieName = "csrftoken"
        axios.defaults.headers = {
            //"Content-Type": "application/json",
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`
        }

        await axios.post(`${domain}/api/file/translation/upload/${request_id}/`, formData)
        .then(res => {
            onSuccess("ok")
            message.success(res.data.message)
            props?.refetch()

        })
        .catch(err => {
            err?.response?.data?.message?.map(mes => {
                message.error(mes)
            })
            onError("Something went wrong")
        })

    }
    
    const filesColumns = [
        {
            title: <FormattedMessage id='File name'/>,
            key: 'name',
            dataIndex: 'name'
        }, 
        {
            title: <FormattedMessage id='Language given'/>,
            key: 'original',
            dataIndex: 'original'
        },
        {
            title: <FormattedMessage id='Translation required'/>,
            key: 'target',
            dataIndex: 'target'
        },
        {
            title: <FormattedMessage id='Date Requested'/>,
            defaultSortOrder: 'ascend',
            sorter: (a, b) => moment(b.date_requested).unix() - moment(a.date_requested).unix(),
            render: row => {
               
                const date_requested = new Date(row.date_requested)
                const symbol = localStorage.getItem("symbol")
                return (
                    <>
                        {date_requested.toLocaleString(symbol)}
                    </>
                )
            }
           
        },
        {
            title: <FormattedMessage id='Original File'/>,
            render: row => {
                return (
                   
                    <a href={row.original_file} 
                        style={{marginLeft: '10px'}} 
                        download >
                        Download <DownloadOutlined/>
                    </a>
                   
                )
            }
           
        },
        {
            title: <FormattedMessage id='Translated File'/>,
            render: row => {
                return (
                    // if translated 
                    (row.state === "t") ? (
                        <a href={row.translated_file} style={{marginLeft: '10px'}} download >
                            Download <DownloadOutlined/>
                        </a>
                    ) : (
                        <>
                            <Upload 
                                
                                customRequest={e => uploadFile({...e, request_id: row.request_id }) }
                                maxCount={1}
                                >
                                <Button  
                                    icon={<UploadOutlined />}>Upload File</Button>
                            </Upload>
                        </>
                        
                    )
                )
            }
           
        },
        {
            title: <FormattedMessage id='State'/>,
            render: row => {
                return (
                    <>
                        {row.state === 'd' && (
                            <>
                                 <Tag >
                                    Waiting for Translation
                                </Tag>
                            </>
                        )}

                        {row.state === "t" && (
                            <> 
                            {/* geekblue */}
                                <Tag color="processing">
                                    Waiting for Manager
                                </Tag>
                            </>
                        )}
                    
                        {row.state === "r" && (
                            <>
                                {/* manager rejected */}
                                <Tag color="warning">
                                    Manager Rejected
                                </Tag>
                                <a>
                                    <Popover placement="bottom" 
                                                
                                                title={<FormattedMessage id='Reason of rejection'/>} 
                                                content={row.rejection_message} 
                                                trigger="click">
                                        <QuestionCircleOutlined />
                                    </Popover>
                                </a>
                            </>
                        )}

                    </>
                )
            }
        }

    ]
    
    
    return (
        <>
            <Card className="news-card">
                <Title level={5} strong>
                    <FormattedMessage id="Files to be translated"/>
                </Title>
                {!props?.isFetching ? (
                    <Table
                        size="small"
                        dataSource={props?.files}
                        columns={filesColumns}  
                        pagination={{ pageSize: 5 }}   
                        rowKey="request_id"
                    />
                    ) : (
                        <div style={{minHeight:'300px', textAlign:'center'}}>
                            
                            <Spin style={{marginTop: "75px"}} size="large" />
                        
                        </div>
                )}
            </Card>
        </>
    )
}

export default TranslationFiles