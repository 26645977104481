import React, { useState, useEffect } from 'react'
import { Skeleton, Input, message, Select, Row, Col, Button, Typography } from "antd"

import { FormattedMessage } from 'react-intl'

import { useGetOptionsQuery, useGetUserCompanyQuery } from "../../services/mainApi"

import axios from "axios"

import domain from '../../domain'

const { Option } = Select;

const { Text } = Typography

function Company() {
    const { data: options, isFetching:fetchB } = useGetOptionsQuery()
    const { data: company, isFetching, refetch } = useGetUserCompanyQuery()

    const [name, setName] = useState(null)
    const [stcNumber, setStcNumber] = useState(null)
    const [phNumber, setPhNumber] = useState(null)
    const [offEmail, setOffEmail] = useState(null)
    const [webUrl, setWebUrl] = useState(null)
    const [description, setDescription] = useState(null)
    const [company_type, setCompanyType] = useState(null)
    const [location, setLocation] = useState(null)
 
    useEffect(() => {
        setName(company?.name)
        setStcNumber(company?.stock_number)
        setPhNumber(company?.phone_number)
        setOffEmail(company?.office_email)
        setDescription(company?.description)
        setCompanyType(company?.show_company_type)
        setLocation(company?.show_location)
    }, [isFetching])
    
    
    const Submit = async () => {
        if (name === null || description === null || location === null || company_type === null) {
            message.error('Empty fields!')            
        } else {
            const token = localStorage.getItem("token")
        const context = {
            name : name,
            stock_number : stcNumber,
            office_email : offEmail,
            phone_number : phNumber,
            description : description,
            company_type : company_type,
            location : location
        }
        
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.defaults.xsrfCookieName = "csrftoken"
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`
        }

        await axios.post(`${domain}/api/company/`, context)
        .then(res => {
            if (!res.data.err) {
                message.success(res.data.message)
                refetch()
            } else {
                message.error(res.data.message)
            }
           
        })
        .catch(err => {
            
            err?.response?.data?.message?.map(errors => {
               
                message.error(errors.join(", "))
            })
        
        })
     
            
        }
    }

    return (
    <Row gutter={[24, 24]} className="form-row">
    
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            
                <FormattedMessage id='Company Name'>
                    {(msg) => {
                        return (
                            <>
                            <Text>*{msg}</Text>
                            
                                <Input 
                                    disabled={isFetching}
                                    label={msg}
                                    placeholder={msg}
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                    />
                           
                            </>
                        )
                    }}
                </FormattedMessage>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <FormattedMessage id='Stock Number'>
                    {(msg) => {
                        return (
                            <>
                            <Text>*{msg}</Text>
                            <Input 
                                disabled={isFetching}
                                label={msg}
                                placeholder={msg}
                                value={stcNumber}
                                onChange={e => setStcNumber(e.target.value)}
                                />
                            </>
                        )
                    }}
                </FormattedMessage>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <FormattedMessage id='Phone Number'>
                    {(msg) => {
                        return (
                            <>
                            <Text>*{msg}</Text>
                            <Input 
                                disabled={isFetching}
                                label={msg}
                                placeholder={msg}
                                value={phNumber}
                                onChange={e => setPhNumber(e.target.value)}
                                />
                            </>
                        )
                    }}
                </FormattedMessage>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <FormattedMessage id='Office Email'>
                    {(msg) => {
                        return (
                            <>
                            <Text>*{msg}</Text>
                            <Input 
                                disabled={isFetching}
                                label={msg}
                                placeholder={msg}
                                value={offEmail}
                                onChange={e => setOffEmail(e.target.value)}
                                />
                            </>
                        )
                    }}
                </FormattedMessage>
            </Col>

            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <FormattedMessage id='Company Description'>
                    {(msg) => {
                        return (
                            <>
                            <Text>*{msg}</Text>
                            <Input.TextArea 
                                disabled={isFetching}
                                label={msg}
                                placeholder={msg}
                                value={description}
                                onChange={e => setDescription(e.target.value)}
                                />
                            </>
                        )
                    }}
                </FormattedMessage>
            </Col>

            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <FormattedMessage id='Location'>
                    {(msg) => {
                        return (
                            <>
                            <Text>*{msg}</Text>
                            <Select
                                disabled={isFetching}
                                showSearch
                                style={{ width: "100%" }}
                                placeholder={msg}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                onChange={(e) => setLocation(e)}
                                value={location}
                            >
                                {options?.countries?.map((item, i) => ( 
                                <Option key={i} value={item}>{item}</Option>
                                ))}
                                
                            </Select> 
                            </>
                        )
                    }}
                </FormattedMessage>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <FormattedMessage id='Company Type'>
                    {(msg) => {
                        return (
                            <>
                            <Text>*{msg}</Text>
                            <Select
                                disabled={isFetching}
                                name={"company type"}
                                showSearch
                                style={{ width: "100%" }}
                                placeholder={msg}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                onChange={(e) => setCompanyType(e)}
                                value={company_type}
                            >
                                {options?.company_types?.map((item, i) => ( 
                                <Option key={i} value={item}>{item}</Option>
                                ))}
                                
                            </Select> 
                            </>
                        )
                    }}
                </FormattedMessage>
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Button onClick={e => Submit()}>
                    <FormattedMessage id="Save Changes" />
                </Button>
            </Col>
   
    </Row>
  )
}

export default Company