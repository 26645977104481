import React, { Fragment, useEffect, useState } from 'react'

import { message, Modal, Result, Row,  Divider, Col, Typography, Button } from "antd"

import axios from 'axios'
import { FormattedMessage } from 'react-intl'

import domain from "../../../domain"


const { Title } = Typography


function BuyNegotiation(props) {

    const [buyInformation, setBuyInformation] = useState(false)
    
    const [inProgress, setInProgress] = useState(false)
    

    const handleInformation = async () => {
        setInProgress(true)
        const token = localStorage.getItem("token")
            
            axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
            axios.defaults.xsrfCookieName = "csrftoken"
            axios.defaults.headers = {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`
            }
       
            await axios.post(`${domain}/api/package/buy/information/`, {offer_id : props.offerId})
            .then(res => {
              if (res.data.error) {
                message.error(res.data.message)
              } else {
                message.success(res.data.message)
                // this will refresh the entire page
                props.setReload(!props.reload)
                props.refetch()
    
              }
            })
            setBuyInformation(false)
        setInProgress(false)
      }

    return (
    <>
        <Modal
            visible={buyInformation}
            centered
            footer={null}
            onCancel={() => { setBuyInformation(false) }}
            >

            <FormattedMessage id= 'Are you sure?'>
                {(title) => {
                    return (
                        <FormattedMessage id={`You will unlock the profile of the seller and the files associated with this offer.`}>
                            {(subTitle) => {
                                return (
                                
                                <Result
                                    
                                    title={title}
                                    subTitle={subTitle}
                                    extra={[
                                        <>
                                    
                                        <Button disabled={inProgress || props?.isFetching} type="primary" key="console" onClick={e => { handleInformation() }}>
                                            <FormattedMessage id='Yes, buy it!' />
                                        </Button>
                                    
                                        
                                        </>
                                    ]}
                                    />
                                )
                            }}
                    </FormattedMessage>
                    )
                }}
            </FormattedMessage>
        </Modal>


            <Row gutter={[6,6]}>
                <Col l={24} lg={24} md={24} sm={24} xs={24}>
                    <Title level={4}>
                    <FormattedMessage id="Buy Information Package"/> 
                    </Title>
                </Col>
                <Col l={24} lg={24} md={24} sm={24} xs={24}>
                <Button type="primary"
                            disabled={inProgress}
                            style={{width: "100%" }}
                            onClick={() => setBuyInformation(true)}
                            >
                            <FormattedMessage id="Information Package for "/> {props?.info_price} IPP
                </Button>
                </Col>
            </Row>
            <Row gutter={[6,6]} className='mb-4'>
                <Col l={24} lg={24} md={24} sm={24} xs={24}>
                <Divider/>
                <Title level={5}>
                    <FormattedMessage id="info-1"/>
                </Title>
                <Divider className='man-divider'/>
                <Title level={5}>
                    <FormattedMessage id="info-2"/>
                </Title>
                <Divider className='man-divider'/>
                <Title level={5}>
                    <FormattedMessage id="info-3"/>
                </Title>
                

                </Col>
            </Row>
    </>
  )
}

export default BuyNegotiation