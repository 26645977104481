import React, { useState, useEffect } from 'react'
import { Alert, Divider, Modal, Input, Dropdown, Menu, Popover, Button, Row, Col, Table, Spin, Typography, message, Card, Tag } from "antd"
import { FormattedMessage } from 'react-intl'
import { Navigate, Link } from 'react-router-dom'
import moment from 'moment';
import axios from "axios"
import domain from "../../../domain"

import { ExperimentOutlined, FormOutlined, QuestionCircleOutlined } from '@ant-design/icons';


const { SubMenu } = Menu;
const { Title, Text } = Typography
const { TextArea } = Input;



function TranslationMessage(props) {
    const [inProgress, setInProgress] = useState(false)

    const [message_item, setMessageItem] = useState({})
    const [messageModal, setMessageModal] = useState(false)
    const [messageOriginalContent, setMessageOriginalContent] = useState("")
    


    const messageColumns = [
        {
            title: <FormattedMessage id='Message'/>,
            key: 'content',
            dataIndex: 'content'
        },
        {
            title: <FormattedMessage id='Language given'/>,
            key: 'original',
            dataIndex: 'original'
        },
        {
            title: <FormattedMessage id='Translation required'/>,
            key: 'target',
            dataIndex: 'target'
        },
        {
            title: <FormattedMessage id='Actions'/>,
            render: row => (
                <>
                    <Dropdown overlay={
                        <Menu>
                         
                         <Menu.Item key={'overview'}>
                         <a onClick={e => {
                             setMessageModal(true)
                             setMessageOriginalContent(row?.content)
                             setMessageItem(row)
                         } }>
                             <ExperimentOutlined /> <FormattedMessage id="Overview"/>
                         </a>
                         </Menu.Item>
                     </Menu>
                    } placement="bottomRight" arrow trigger={['click']}>
                        <Button className='btn-icon' color='primary'>
                            <FormOutlined />
                        </Button>
                    </Dropdown>
                </>
            )
        }
    ]

    const sendMessageTranslation = async () => {
        setInProgress(true)
        
        const token = localStorage.getItem("token")
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.defaults.xsrfCookieName = "csrftoken"
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`
        }


        await axios.post(`${domain}/translator/dashboard/message-translation/`, message_item)
        .then(res => {
            if (res.data.error) { 
                message.error(res.data.message)
                
            } else {
                message.success(res.data.message)
                setMessageItem({})
                setMessageOriginalContent("")
                props?.refetch()
                setMessageModal(false)

            }
        })
        .catch(err => {
            message.error(err)
        })

        setInProgress(false)
    }

    return (
        <>
            <Modal
                visible={messageModal}
                title={`${message_item.original} -> ${message_item.target}`}
                centered
                footer={
                    <Button type="primary" 
                            disabled={inProgress}
                            onClick={() => { sendMessageTranslation() }}>
                        <FormattedMessage id="Submit"/>
                    </Button>
                }
                onCancel={() => { setMessageModal(false) }}
            
            >

                

                <Row gutter={[24,24]}>
        
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    {message_item?.rejection_message && (<Alert message={message_item?.rejection_message} type="error" className='mb-1'/>)}
                            <Title level={5}><FormattedMessage id="Original Message"/></Title>
                            <Text>{messageOriginalContent}</Text>
                
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24} className='mt-1'>
            
                            <FormattedMessage id="Edit Message">
                                {(msg) => {
                                    return (
                                        <>
                                        <Title level={5}>{msg}</Title>
                                        <TextArea rows={4} placeholder={msg} value={message_item.content} onChange={e => { 
                                            setMessageItem({...message_item, content : e.target.value}) 
                                        }}/>
                                        </>
                                    )
                                }}
                            </FormattedMessage>
            
                    </Col>
                </Row>
            </Modal>

            <Card className="news-card">
                <Title level={5} strong>
                    <FormattedMessage id="Message Translation requests"/>
                </Title>
                    {!props?.isFetching ? (
                        <Table
                            size="small"
                            dataSource={props?.messages}
                            columns={messageColumns}  
                            pagination={{ pageSize: 5 }}   
                        />
                        ) : (
                            <div style={{minHeight:'300px', textAlign:'center'}}>
                                
                                <Spin style={{marginTop: "75px"}} size="large" />
                            
                            </div>
                    )}
            </Card>
        </>
    )
}

export default TranslationMessage