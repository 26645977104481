import React, { useState, useEffect } from 'react'
import { Button, Upload, Alert, Popover, Table, Menu, Dropdown, Skeleton,  Row, Tag, Divider, Col, Typography, Checkbox, message, Avatar, Space, Form } from "antd"
import { UploadOutlined, DownOutlined, DownloadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl'
import axios from 'axios';
import domain from '../../../domain';


const { Title, Text } = Typography



function DetailNda(props) {
    const [whatToDo, setWhatToDo] = useState("")
    const [status, setStatus] = useState("")
    const [type, setType] = useState("")

    const [show, setShow] = useState(false)
    const [inProgress, setInProgress] = useState(false)

   
    useEffect(() => {
        if (props?.access_vars?.show_info && !props?.access_vars?.show_externals) {
            setShow(true)

            if (props?.access_vars?.info_state === 'd') {
                setWhatToDo(<FormattedMessage id="Download, sign the nda and upload it."/>)
                setStatus(<FormattedMessage id="Drafted, waiting for upload." />)
                setType("info")
                
            } else if (props?.access_vars?.info_state === 'u') {
                setWhatToDo(<FormattedMessage id="Waiting for the seller to review the uploaded nda." />)
                setStatus(<FormattedMessage id="NDA uploaded, waiting for seller." />)
                setType("info")

            } else if (props?.access_vars?.info_state === 's') {
                setWhatToDo(<FormattedMessage id="Seller uploaded, waiting for Manager to review it."/>)
                setStatus(<FormattedMessage id="NDA uploaded, waiting for Manager." />)
                setType("info")

            } else if (props?.access_vars?.info_state === 'ma') {
                setWhatToDo(<FormattedMessage id="Everything is set, please accept or reject this request."/>)
                setStatus(<FormattedMessage id="Accepted, waiting for your verification" />)
                setType("info")
                
            } else if (props?.access_vars?.info_state === 'a') {
                setWhatToDo(<FormattedMessage id="Everything is set" />)
                
                const date_activated = new Date(props?.nda_serializer.date_activated)
                const symbol = localStorage.getItem("symbol")
                setStatus(`${<FormattedMessage id="Active since:" />} ${date_activated.toLocaleString(symbol)}`)
                setType("success")
                
            } else if (props?.access_vars?.info_state === 'sr') {
                setWhatToDo(<FormattedMessage id="Seller has rejected your request, read their reason of rejection and update your document" />)
                setStatus(<FormattedMessage id="Seller Rejected" />)
                setType("warning")
                
            } else if (props?.access_vars?.info_state === 'mr') {
                setWhatToDo(<FormattedMessage id="Manager has rejected this request, please wait for the seller to do the adjustments." />)
                setStatus(<FormattedMessage id="Manager Rejected" />)
                setType("warning")
            } else if (props?.access_vars?.info_state === 'ur') {
                setWhatToDo(<FormattedMessage id="Seller has been notified, please wait for his response." />)
                setStatus(<FormattedMessage id="User Rejected" />)
                setType("warning")
            } else if (props?.access_vars?.info_state === 'b') {
                setWhatToDo(<FormattedMessage id="Seller has banned you here. :(" />)
                setStatus(<FormattedMessage id="Banned" />)
                setType("danger")

            } else {
                console.log(props?.access_vars?.info_state)
            }

        } 
    }, [props?.done, props?.access_vars?.info_state])
    
    const userUploadRequest = async ({ file, onSuccess }) => {
        setInProgress(true)

        const formData = new FormData()

        formData.append("file", file, file.name)

        const token = localStorage.getItem("token")
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.defaults.xsrfCookieName = "csrftoken"
        axios.defaults.headers = {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`
        }

        await axios.post(`${domain}/api/information/user/upload/${props?.access_vars?.info_id}/`, formData, { timeout : 50000 })
        .then(res => {
            
            onSuccess("ok")
            props?.setReload(!props?.reload)
            
            message.success(res.data.message)
        })
        .catch(err => {
            err?.response?.data?.message?.map(mes => {
                message.error(mes)
            })
            setInProgress(false)
        })

        
    };  

    const userVerifyRequest = async (rejected) => {
        
        setInProgress(true)
    
        const token = localStorage.getItem("token")
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.defaults.xsrfCookieName = "csrftoken"
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`
        }

        await axios.post(`${domain}/api/information/user/verify/${props.nda_serializer.package_id}/`, { rejected })
        .then(res => {
         
            props?.setReload(!props?.reload)
            
            message.success(res.data.message)
        })
        .catch(err => {
            err?.response?.data?.message?.map(mes => {
                message.error(mes)
            })  
            setInProgress(false)

        })

    }

    return (
        <Row gutter={[12, 12]}>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}> 
                
                <Title level={5}>
                    
                  <FormattedMessage id="NDA"/>

                    {show && (
                        <a style={{ marginLeft: "5px"}}>
                            <Popover placement="bottom" 
                                        title={<FormattedMessage id='What to do:'/>} 
                                        content={whatToDo} 
                                        trigger="click">
                                <QuestionCircleOutlined />
                            </Popover>
                        </a>
                    )}
                
                </Title>
                {status !== "" && (
                   <>
                     <Alert message={status} type={type} />
                   </>
                )}

                

            </Col>
                {props?.access_vars?.info_state === 'ma' && (
                            <>
                             
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Button type='primary'
                                            disabled={inProgress}
                                            onClick={e => userVerifyRequest(false) }
                                            style={{ width: "100%"}}>
                                        Accept
                                    </Button>
                                </Col>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Button type='primary'
                                            disabled={inProgress}
                                            onClick={e => userVerifyRequest(true) }
                                            danger
                                            style={{ width: "100%"}}>
                                        Reject
                                    </Button>
                                </Col>
                           
                            </>
                        )}
            
            

            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                {props?.done ? (
                    <>  
                        {!props?.access_vars?.show_externals && (
                            <>
                                <Divider />
                            </>
                        )}
                        

                        <Title level={5} style={{ marginBottom: "0"}}>
                            {props?.nda_protected ? (
                                <>
                                    
                                    {/* TODO: maybe user should not see this if he hasnt bought information package  */}
                                    
                                    {props?.access_vars?.show_info && (
                                        <>

                                            <FormattedMessage id="NDA protected"/>

                                            <a href={props?.nda} style={{marginLeft: '10px'}} download >
                                                <DownloadOutlined/>
                                            </a>

                                            


                                        </>
                                    )}
                                </>
                            ) : (
                                <>

                                    <FormattedMessage id="Not NDA protected"/>
                                </>
                            )}
                                
                          
                        </Title>
                            
                        {props?.nda_serializer?.nda_user && (
                            <>
                                <Title level={5}>
                                  
                                         <a href={props?.nda_serializer?.nda_user} 
                                            download >
                                            User NDA <DownloadOutlined/>
                                        </a>

                                        {props?.access_vars?.info_state === 'sr' && (
                                            <a style={{ marginLeft: "5px", "color": "red"}}>
                                                <Popover placement="bottom" 
                                                            
                                                            title={<FormattedMessage id='Reason of rejection'/>} 
                                                            content={props?.nda_serializer?.rejection_message} 
                                                            trigger="click">
                                                    <QuestionCircleOutlined />
                                                </Popover>
                                            </a>
                                        )}
                                </Title>
                            </>
                        )}
                            {/* if not drafted, else show this all the time */}
                        {props?.nda_serializer?.nda_seller && (
                            <>
                                <Title level={5} >
                                    <a href={props?.nda_serializer?.nda_seller} 
                                        download >
                                        Seller NDA <DownloadOutlined/>
                                    </a>
                                </Title>
                            </>
                        )}

                       


                    </>
                ) : (
                    <Skeleton.Input active={true} size={"medium"} block={true}/>
                )}
                
            </Col>

            {(props?.access_vars?.info_state === 'd' || props?.access_vars?.info_state === 'sr') && (
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Upload 
                        style={{width: "100%"}}
                        customRequest={userUploadRequest}
                        maxCount={1}
                        >
                        <Button  
                        disabled={inProgress}
                        style={{width: "100%"}}
                        icon={<UploadOutlined />}>Upload NDA</Button>
                    </Upload>
                </Col>
            )}
            
             
       
      </Row>
    )
}

export default DetailNda