import React from 'react'
import { Link } from "react-router-dom"
import { useParams } from "react-router-dom"

import { Row, Col, Typography, Button } from "antd"

import { FormattedMessage } from 'react-intl'


const { Title } = Typography 

function EditLink() {
    const { offerId } = useParams() 
    return (
    <>
        <Row gutter={[6,6]}>
            <Col l={24} lg={24} md={24} sm={24} xs={24}>
                <Title level={4}>
                        <FormattedMessage id="Package Overview"/>
                </Title>
            </Col>
            <Col l={24} lg={24} md={24} sm={24} xs={24}>
                <Link to={`/offers/edit/${offerId}`} >
                    <Button type="primary"

                                style={{width: "100%"}}
                                >
                            <FormattedMessage id="Edit Offer"/>
                    </Button>
                </Link>
            </Col>
        </Row>
    </>
  )
}

export default EditLink