import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import domain from "../domain"

const baseUrl = domain


const createRequest = (url) => ({ url, headers: {"Content-Type": "application/json"} })

export const mainApi = createApi({
    reducerPath : "mainApi",              // what is this reducer for 
    baseQuery: fetchBaseQuery({ baseUrl }), // base query
    endpoints: (builder) => ({
        getOptions: builder.query({
            // count is a given parameter when calling the endpoint
            query: () => createRequest(`/api/options/`)
        }),
        getLanguages: builder.query({
            // count is a given parameter when calling the endpoint
            query: () => createRequest(`/api/languages/`)
        }),
        getOffers: builder.query({
            // count is a given parameter when calling the endpoint
            query: (simplified) => createRequest(simplified ? `/api/offer/list/simple/` : `/api/offer/list/`)
        }),
        getOfferToEdit: builder.query({
            
            query: (offerId) => createRequest(`/seller/dashboard/offer/edit/${offerId}/`)
        }),
        getHotOffers: builder.query({
            // count is a given parameter when calling the endpoint
            query: () => createRequest(`/api/hot-offers/`)
        }),
        getUserCompany: builder.query({
            query: () => createRequest(`/api/company/`)
        }),
        getUserInfo: builder.query({
            query: () => createRequest(`/users/profile/info/`)
        }),
        getUserOffers: builder.query({
            query: () => createRequest(`/users/profile/offers/`)
        }),
        getSellerDashboard: builder.query({
            // count is a given parameter when calling the endpoint
            query: () => createRequest(`/seller/dashboard/`)
        }),
        getManagerDashboard: builder.query({
            // count is a given parameter when calling the endpoint
            query: () => createRequest(`/manager/dashboard/`)
        }),
        getTranslatorDashboard: builder.query({
            // count is a given parameter when calling the endpoint
            query: () => createRequest(`/translator/dashboard/`)
        }),
        getOfferOverview: builder.query({
            // count is a given parameter when calling the endpoint
            query: (offerId) => createRequest(`/manager/dashboard/overview/${offerId}/`)
        }),
    
        
    })
})
// hook from redux to collect all the data
// this kind of activates the getCryptos function in cryptoApi
export const {
    useGetOptionsQuery,
    useGetLanguagesQuery,
    useGetOffersQuery,
    useGetOfferToEditQuery,
    useGetSellerDashboardQuery, 
    useGetManagerDashboardQuery,
    useGetTranslatorDashboardQuery,
    useGetHotOffersQuery,
    useGetUserOffersQuery,
    useGetUserCompanyQuery,
    useGetUserInfoQuery,
    useGetOfferOverviewQuery
} = mainApi