import React, { useEffect, useState } from 'react'
import { Link  } from "react-router-dom"
import { Space, Tabs, Row, Col, Avatar, Typography, Form } from 'antd';
import { UserOutlined, BankOutlined, SettingOutlined, AppstoreAddOutlined } from '@ant-design/icons';

import About from './profileAbout';
import Settings from './profileSettings';
import Company from './profileCompany';
import Offers from './profileOffers';

import axios from "axios"
import domain from "../../domain"

import { useGetUserInfoQuery } from "../../services/mainApi"
import { FormattedMessage } from 'react-intl'


const { Title, Text } = Typography

const { TabPane } = Tabs;

function Profile(props) {
    const { data, isFetching, refetch } = useGetUserInfoQuery()  
  
    const [userData, setUserData] = useState({})

    useEffect(async () => {
      props.setBc2(<FormattedMessage id="Profile" />)
      props.setBc3(<FormattedMessage id="Detail" />)
      
      if (isFetching) {
          setUserData(JSON.parse(localStorage.getItem("userData")))
      } else {
          setUserData(data)
      }

    
    }, [isFetching])

  
  return (
       <>
       <Row gutter={[12, 12]} className='form-row'>
          {userData && (
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            
            <Space align="center" size={20}>
              <div className='profile-img'>
                <img style={{height:'100px', width: '100px', borderRadius: ".357rem!important"}} className='rounded img-fluid' src={userData?.picture} alt='profile image' />
              </div>
              <div className='profile-title ml-3'>
                <Title level={4}>
                  {`${userData?.title !== null ? `${userData?.title}, ` : ('')} ${userData?.last_name} ${userData?.first_name}`}  
                </Title>
                <Text>{userData?.status}</Text>
              </div>
            </Space>
              
          </Col>
          )}
       </Row>
      <Tabs defaultActiveKey="1">
        <TabPane style={{borderBottomStyle:'none'}}
          tab={
            <span>
              <UserOutlined />
              
              <FormattedMessage id="About"/>
            </span>
          }
          key="1"
        >
          <About {...userData}/>
        </TabPane>
        <TabPane
          tab={
            <span>
              <AppstoreAddOutlined />
                <FormattedMessage id="Offers Associated"/>
              
            </span>
          }
          key="2"
        >
           <Offers />

        </TabPane>
        <TabPane
          tab={
            <span>
              <BankOutlined />
              
              <FormattedMessage id="Company"/>
              
            </span>
          }
          key="3"
        >
           <Company />
        </TabPane>
        <TabPane
          tab={
            <span>
              <SettingOutlined />
              
              <FormattedMessage id="Settings"/>

            </span>
          }
          key="4"
        >
           <Settings {...{...userData, refetch}}/>
        </TabPane>
      </Tabs>
       
       </>
  )
}

export default Profile