import React from 'react'
import  { Row, Col, Input, Form } from "antd"

import { FormattedMessage } from 'react-intl'

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'


function OfferTextEdit(props) {
    
    return (
        <>
         
            <Row gutter={[12,12]} className="form-row">
                <FormattedMessage id='Title'>
                    {(msg) => (
                        
                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Input 
                                name='title'
                                value={props?.title}
                                placeholder={msg}
                                onChange={(e) => {  props?.setTitle(e.target.value) }}
                                />
                        </Col>
                    )}
                </FormattedMessage>
                
                <FormattedMessage id='Status'>
                    {(msg) => (
                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Input 
                                name='status'
                                value={props?.status}
                                placeholder={msg}
                                onChange={(e) => { props?.setStatus(e.target.value) }}
                                />
                        </Col>
                    )}
                </FormattedMessage>
                {props?.description && (
                    <FormattedMessage id='Description'>
                        {(msg) => (
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                <CKEditor
                                    editor={ Editor }
                                    data={props?.description}
                                    name={msg}
                                    onChange={ ( e, editor ) => props?.setDescription(editor.getData())}
                                />
                            </Col>
                        )}
                    </FormattedMessage>
                )}
               
            
            </Row>
        
            
        </>
  )
}

export default OfferTextEdit