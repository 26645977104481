import React, { useState, useEffect } from 'react'
import { Form, Input, Button, message, Typography } from 'antd';
import { connect } from 'react-redux'
import { Navigate, Link } from "react-router-dom";
import Particles from 'react-tsparticles'

import { ConsoleSqlOutlined, GlobalOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl'

import * as actions from "../../app/auth/actions"

const { Title, Text } = Typography

function Login(props) {    
   
    const onFinish = (values) => {
      props.login(values.email, values.password)
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
  

    const validateMessages = {
      required: '${label} is required!',
      
     
    };

    const particleColor = '#7367F0'
    return (
    <>
      {props.auth.login_success && (
        <Navigate to="/" push />
      )}
      <Particles
        style={{width:'100%', height:'100vh', backgroundColor:"#fff"}}
        params={{
          particles: {
          number: { value: 80, limit: 120, density: { enable: true, value_area: 800 } },
          color: { value: particleColor },
          shape: {
            type: "circle",
            stroke: { width: 0, color: particleColor },
            polygon: { nb_sides: 5 }
          },
          opacity: {
            value: 0.5,
            random: false,
            anim: { enable: false, speed: 1, opacity_min: 0.1, sync: false }
          },
          size: {
            value: 3,
            random: true,
            anim: { enable: false, speed: 40, size_min: 0.1, sync: false }
          },
          line_linked: {
            enable: true,
            distance: 150,
            color: particleColor,
            opacity: 0.4,
            width: 1
          },
          move: {
            enable: true,
            speed: 2,
            direction: "none",
            random: false,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: { enable: false, rotateX: 600, rotateY: 1200 }
          }
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: { enable: true, mode: "grab" },
            onclick: { enable: true, mode: "push" },
            resize: true
          },
          modes: {
            grab: { distance: 400, line_linked: { opacity: 1 } },
            bubble: { distance: 400, size: 40, duration: 2, opacity: 8, speed: 3 },
            repulse: { distance: 200, duration: 0.4 },
            push: { particles_nb: 4 },
            remove: { particles_nb: 2 }
          }
        },
        retina_detect: true

      }} />
      <div className='login-card'>
        

          <Form
            style={{width: "100%"}}
            name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 12 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            validateMessages={validateMessages}
          >
            <div style={{width: "100%", textAlign: "center"}}>
              <Title level={2} style={{color: "#7367f0"}}>
                    IP2B
              </Title>
            </div>

            <div style={{width: "100%", textAlign: "center", marginBottom: "34px"}}>
              <Title level={5} strong >
                      <FormattedMessage id='Welcome to IP2B' />
                </Title>
            </div>
              
            
            
        
            <Form.Item
              className='login-form'
              name={['email']}
              label="Email"
              rules={[{ required: true, message: <FormattedMessage id="Email is required!"/> }]}

            >
              <Input placeholder="username@email.com" />
            </Form.Item>

            <Form.Item
              style={{ marginBottom : 0}}
              className='login-form'

              name={['password']}
              label="Password"
              rules={[
                  {
                      required : true,
                      message: <FormattedMessage id="Password is required!"/>
                  },
                  { 
                      min: 8, 
                      message: <FormattedMessage id="Minumum of 8 characters!"/>
                  },
              ]}
            >
              <Input.Password />

                
            </Form.Item>

            
            <div style={{textAlign: "center", margin: "10px 0 20px 0"}}>
                <Link   to="/password/forgot/">
                      
                    <FormattedMessage id='Forgot password' />
    
                </Link>
            </div>
      

            <div style={{textAlign: "center", marginBottom: "20px"}}>
              <Button type="primary" htmlType="submit" style={{ margin: "10px 0 0 0"}}>
                  <FormattedMessage id='Log in' />
              </Button>
            </div>
             
           

          </Form>
            <a className="ml-1" style={{}} href="#/impressum">Impressum</a>
            <a style={{ 'float' : "right",  }} onClick={e => props.setLangModal(true)}>
                <GlobalOutlined /> <FormattedMessage id='Language_Name' />
            </a>
      </div>
    
    </>
  )
}

const mapStateToProps = state => {
  return {
    auth : state.auth
  }
}

const mapDispatchToProps = dispatch => {
  return {
    login : (email, password) => dispatch(actions.login(email, password))
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(Login)
