import React, { useState, useEffect } from 'react'
import { Modal, Layout, Breadcrumb } from 'antd';
import { Routes, Navigate, Route } from "react-router-dom";
import { Helmet } from "react-helmet";

import { OfferEdit, Register, RechargeIPP, Impressum, TranslatorDashboard, ManagerDashboard, SellerTimeline, Login, CustomLayout, Homepage, Offers, OfferDetailOverview, OfferCreate, Profile, SellerDashboard } from "./components"

import icon from "./images/favicon.ico"

import ChangeLang from './utils/intl/ChangeLang';
import PasswordReset from './components/authentication/PasswordReset';
import PasswordForgot from './components/authentication/PasswordForgot';

import { FormattedMessage } from 'react-intl'


import './main.less';



const App = () => {
  const [title, setTitle] = useState("IP2B")
  const [meta_description, setMetaDescription] = useState("Intellectual Property to Business")
  
  const [bc1, setBc1] = useState("IP2B")
  const [bc2, setBc2] = useState(<FormattedMessage id="List"/>)
  const [bc3, setBc3] = useState(<FormattedMessage id="App" />)

  const props = {...{ setBc1, setBc2, setBc3 }}
  const [langModal, setLangModal] = useState(false)

  
  return (
    <>
      <Modal
          className='language-modal'
          centered
          visible={langModal}
          onCancel={() => setLangModal(false)}
          width={'100%'}
          footer={null}
      >
          <ChangeLang />
      </Modal>

      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={meta_description}></meta>
        <link rel="icon" id="favicon" href={icon} />
      </Helmet>
        <Routes>
            
            <Route path="" element={
              <CustomLayout {...{ bc1, bc2, bc3, setLangModal }}>
                   <Homepage {...props} />
              </CustomLayout>
            } />
            <Route path="/offers" element={
              <CustomLayout {...{ bc1, bc2, bc3, setLangModal }}>
                  <Offers {...props} />
              </CustomLayout>
            } />
            <Route path="/offers/create" element={
              <CustomLayout {...{ bc1, bc2, bc3, setLangModal }}>
                  <OfferCreate {...props} />
              </CustomLayout>
            } />
            
            <Route path="/offers/edit/:offerId" element={
              <CustomLayout {...{ bc1, bc2, bc3, setLangModal }}>
                  <OfferEdit {...props}  />
              </CustomLayout>
            } />
            <Route path="/offers/detail/:offerId" element={
              <CustomLayout {...{ bc1, bc2, bc3, setLangModal }}>
                   <OfferDetailOverview {...props} />
              </CustomLayout>
            } />
            <Route path="/profile" element={
              <CustomLayout {...{ bc1, bc2, bc3, setLangModal }}>
                   <Profile {...props}/>
              </CustomLayout>
            } />
  
            <Route path="/personel/seller-dashboard" element={
              <CustomLayout {...{ bc1, bc2, bc3, setLangModal }}>
                   <SellerDashboard {...props}/>
              </CustomLayout>
            } />

            <Route path="/personel/manager-dashboard" element={
              <CustomLayout {...{ bc1, bc2, bc3, setLangModal }}>
                   <ManagerDashboard {...props}/>
              </CustomLayout>
            } />

            <Route path="/personel/translator-dashboard" element={
              <CustomLayout {...{ bc1, bc2, bc3, setLangModal }}>
                   <TranslatorDashboard {...props}/>
              </CustomLayout>
            } />


            <Route path="/personel/offer-overview/:offerId" element={
              <CustomLayout {...{ bc1, bc2, bc3, setLangModal }}>
                   <OfferDetailOverview {...props} overview/>
              </CustomLayout>
            } />


            <Route path="/personel/timeline/:packageId" element={
              <CustomLayout {...{ bc1, bc2, bc3, setLangModal }}>
                   <SellerTimeline {...props}/>
              </CustomLayout>
            } />
            
            <Route path="/recharge-ipp" element={
              <CustomLayout {...{ bc1, bc2, bc3, setLangModal }}>
                   <RechargeIPP {...props}/>
              </CustomLayout>
            } />

            <Route path="/login" element={<Login {...{setLangModal}} />} />
            

            <Route path="/:symb/register/:email/:encrypted/:status" element={<Register {...{ setLangModal }} />} />
            
            <Route path="/password/reset/:symb/:email/:encrypted/" element={<PasswordReset {...{ setLangModal }} />} />

            <Route path="/password/forgot/" element={<PasswordForgot {...{ setLangModal }}/>}/>

            <Route path="/impressum" element={<Impressum/>} />

            <Route path='*' element={<Login {...{setLangModal}}/>}/>
            
        </Routes>
      </>
  )
}

export default App