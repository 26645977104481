import React from 'react'
import { useParams } from "react-router-dom"

import { Row, Col } from "antd"

import CustomTimeline from "../../../utils/Timeline/CustomTimeline"


function SellerTimeline(props) {
  const { packageId } = useParams() 
  return (
    <>
        <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <CustomTimeline online={true} userPage={false} packageId={packageId} />
            </Col>
        </Row>
    </>
  )
}

export default SellerTimeline