import React from 'react'
import  { Row, Col,  Select } from "antd"

import { FormattedMessage } from 'react-intl'


import { useGetOptionsQuery } from "../../../../services/mainApi"

const { Option } = Select;

function OfferSelectEdit(props) {
    const { data: options, isFetching } = useGetOptionsQuery()
    

    // this ones should have a default value when editing offer
    const filteredOptions = options?.tags.filter(o => !props?.tags?.includes(o));

    return (
    <>
        <Row gutter={[12, 12]} className="form-row">
                <FormattedMessage id="Category">
                    {(msg) => (
                        <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                            <Select
                                    disabled={isFetching || props?.isFetching || props?.category === null}
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder={msg}
                                    optionFilterProp="children"
                                    value={props?.category}
                                    onChange={e => props.selectCategory(e)}
                                    filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                >
                                {options?.categories.map(item => ( 
                                <Option key={item} value={item}>{item}</Option>
                                ))}
                            </Select> 
                        </Col>
                    )}
                </FormattedMessage>
                <FormattedMessage id="Language">
                    {(msg) => (
                         <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                            <Select
                                    disabled
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder={msg}
                                    value={props?.language}
                                    optionFilterProp="children"
                                    onChange={e => props.selectLanguage(e)}
                                    filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                >
                                {options?.languages?.map(item => ( 
                                <Option key={item} value={item}>{item}</Option>
                                ))}
                            </Select> 
                        </Col>
                    )}
                </FormattedMessage>
                <FormattedMessage id="Tags">
                    {(msg) => (
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Select mode="tags" 
                                    disabled={isFetching || props?.isFetching}
                                    style={{ width: '100%' }} 
                                    value={props?.tags}
                                    onChange={(e) => { props?.selectTag(e) }} 
                                    placeholder={msg}
                                    tokenSeparators={[',']}
                                    >
                                {filteredOptions?.map(item => (
                                    <Select.Option key={item} value={item}>
                                        {item}
                                    </Select.Option>
                                ))}
                        </Select>
                        </Col>
                    )}
                </FormattedMessage>
        </Row>
    </>
  )
}

export default OfferSelectEdit