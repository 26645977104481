import React, { useState, useEffect } from 'react'
import { Modal, Alert, Button, Row, Tag, Col, Card, Typography, Space, Form } from "antd";
import {
    PaymentElement,
    useStripe,
    useElements
  } from "@stripe/react-stripe-js"



function PaymentStatus() {
    const stripe = useStripe()
    const elements = useElements()
    const [message, setMessage] = useState("")
    const [status, setStatus] = useState("")

    
    useEffect(() => {
        if (!stripe) {
          return
        }
    
        const clientSecret = new URLSearchParams(window.location.search).get(
          "payment_intent_client_secret"
        )
    
        if (!clientSecret) {
          return
        }
    
        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
          
          switch (paymentIntent.status) {
            
            case "succeeded":
              setMessage("Payment succeessful!")
              setStatus("success")
              break
            case "processing":
                setMessage("Your payment is being processed.")
                setStatus("info")
                break
            case "requires_payment_method":
              setMessage("Your payment was not successful, please try again.")
              setStatus("warning")
              break
            default:
              setMessage("Something went wrong.")
              setStatus("error")
              break
          }
        })
      }, [stripe])


    return (
        <>
            {message !== "" && (
                <Col xl={24} lg={24} md={24} sm={24} xs={24} >
                    <Alert message={message} type={status} />
                </Col>
            )}
           
        </>
    )
}

export default PaymentStatus