import React from 'react'
import { Col, Row, Typography, Button } from "antd"
import { FormattedMessage } from 'react-intl'


import InviteModal from './inviteModal'


const { Title, Text } = Typography


function About(props) {
    

    const date_joined = new Date(props.date_joined)
    

    return (
    <>
      <Row gutter={[24, 24]} className="form-row">
        
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Title style={{margin: "20px 0"}} strong level={5}><FormattedMessage id="About" />: {props?.title} {props?.first_name} {props?.last_name}</Title>
              <div>
                <Title level={5}>Ipp:</Title>
                <Text>{props?.ipp}</Text>
              </div>
              <div className='mt-2'>
                <Title level={5}><FormattedMessage id="Status"/>:</Title>
                <Text>{props?.status}</Text>
              </div>
              <div className='mt-2'>
                <Title level={5}><FormattedMessage id="Is Seller"/>:</Title>
                <Text>{props?.is_seller ? (<FormattedMessage id="Yes"/>) : (<FormattedMessage id="No"/>)}</Text>
              </div>
              <div className='mt-2'>
                <Title level={5}><FormattedMessage id="Works for"/>:</Title>
                <Text>{props?.company}</Text>
              </div>
              <div className='mt-2'>
                <Title level={5}><FormattedMessage id="Date joined"/>:</Title>
                <Text>{date_joined.toLocaleString()}</Text>
              </div>
              {/* TODO: change this to agent */}
              {(props?.status === 'Agent' || props?.status === 'Manager') ? (
                <div className='mt-2'> 
                  <InviteModal />
                </div>
              ) : null}
        </Col>
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Title style={{margin: "20px 0"}} strong level={5}><FormattedMessage id="Social Information"/></Title>
              <div>
                <Title level={5}>WeChat Id:</Title>
                <Text>{props?.WeChat_ID}</Text>
              </div>
              <div className='mt-2'>
                <Title level={5}><FormattedMessage id="Lives in"/>:</Title>
                <Text>{props?.country} {props?.city}</Text>
              </div>
              <div className='mt-2'>
                <Title level={5}><FormattedMessage id="Born on"/>:</Title>
                <Text>{props?.date_of_birth}</Text>
              </div>
              <div className='mt-2'>
                <Title level={5}><FormattedMessage id="Email address"/>:</Title>
                <Text>{props?.email}</Text>
              </div>
              <div className='mt-2'>
                <Title level={5}><FormattedMessage id="Phone number"/>:</Title>
                <Text>{props?.phone}</Text>
              </div>
              <div className='mt-2'>
                <Title level={5}><FormattedMessage id="Spoken Languages"/>:</Title>
                <Text>{props?.spoken_languages?.join(', ')}</Text>
              </div>
          
        </Col> 
      </Row>
    </>
  )
}

export default About