import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { Modal, Row, Col, Input, Upload, Dropdown, Menu, Popover, Button, Table, Spin, Typography, message, Card, Tag } from "antd"
import { StopOutlined, CloseCircleOutlined, QuestionCircleOutlined, FormOutlined, DownOutlined, UploadOutlined, DownloadOutlined } from '@ant-design/icons';

import { FormattedMessage } from 'react-intl'
import axios from 'axios';
import moment from 'moment';

import domain from '../../../domain';



const { Title, Text } = Typography
const { TextArea } = Input;


function NdaTable(props) {
    const [inProgress, setInProgress] = useState(false)

    const [rejectionModal, setRejectionModal] = useState(false)
    const [rejection, setRejection] = useState("")
    const [nda, setNda] = useState({})


    const sellerUploadRequest = async ({ package_id, onSuccess, onError, file }) => {

        const formData = new FormData()

        formData.append("file", file, file.name)

        const token = localStorage.getItem("token")
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.defaults.xsrfCookieName = "csrftoken"
        axios.defaults.headers = {
            //"Content-Type": "application/json",
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`
        }

        await axios.post(`${domain}/api/information/seller/upload/${package_id}/`, formData, { timeout : 50000 })
        .then(res => {
            
            onSuccess("ok")
            
            message.success(res.data.message)
            props?.refetch()

        })
        .catch(err => {
            err?.response?.data?.message?.map(mes => {
                message.error(mes)
            })
            onError("Something went wrong")
        })


    }; 

    const rejectNda = async () => {
        setInProgress(true)
    
        const token = localStorage.getItem("token")
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.defaults.xsrfCookieName = "csrftoken"
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`
        }

        await axios.post(`${domain}/api/information/seller/reject/${nda.package_id}/`, { rejection })
        .then(res => {
         
            message.success(res.data.message)
            props?.refetch()
            setRejectionModal(false)
            setRejection("")
        })
        .catch(err => {
            err?.response?.data?.message?.map(mes => {
                message.error(mes)
            })
            
        })
        setInProgress(false)

    }
    


    const ndaColumns = [
        {
            title: <FormattedMessage id='Title'/>,
            key: 'title',
            dataIndex: 'title',
           
        },
        {
            title: <FormattedMessage id='Date Bought'/>,
            defaultSortOrder: 'ascend',
            sorter: (a, b) => moment(b.date_bought).unix() - moment(a.date_bought).unix(),
            render: row => {
               
                const date_bought = new Date(row.date_bought)
                const symbol = localStorage.getItem("symbol")
                return (
                    <>
                        {date_bought.toLocaleString(symbol)}
                    </>
                )
            }
           
        },
        {
            title: <FormattedMessage id='Date Activated'/>,
            render: row => {
                let formatted_date = "Not yet"

                if (row.state === 'a') {
                    const date_activated = new Date(row.date_activated)
                    const symbol = localStorage.getItem("symbol")
                    formatted_date = date_activated.toLocaleString(symbol)
                    
                } 
                
                return (
                    <>
                        {formatted_date}
                    </>
                )
            }
           
        },

        {
            title: <FormattedMessage id='Nda User'/>,
            render: row => {
                return (
                    row.nda_user ? (
                        <a href={row.nda_user} 
                            disabled={row.state === 'sr'}
                            style={{marginLeft: '10px'}} 
                            download >
                            Download <DownloadOutlined/>
                        </a>
                    ) : (
                        <>
                            {row.nda_protected ? (
                                <>
                                    No file, waiting for user to upload
                                </>
                            ) : (
                                <>
                                    Not NDA protected
                                </>
                            )}
                            
                        </>
                    )
                )
            }
           
        },
        {
            title: <FormattedMessage id='Nda Seller'/>,
            render: row => {
                return (
                    // if uploaded and not manager rejected and not user rejected
                    (row.nda_seller && row.state !== "mr" && row.state !== "ur") ? (
                        <a href={row.nda_seller} style={{marginLeft: '10px'}} download >
                            Download <DownloadOutlined/>
                        </a>
                    ) : (
                        row.nda_protected ? (
                            <>
                                <Upload 
                                    
                                    customRequest={e => sellerUploadRequest({...e, package_id : row.package_id }) }
                                    maxCount={1}
                                    >
                                    <Button  
                                        icon={<UploadOutlined />}>Upload NDA</Button>
                                </Upload>
                            </>
                        ) : (
                            <>
                                Not NDA protected
                            </>
                        )
                        
                    )
                )
            }
           
        },
        {
            title: <FormattedMessage id='State'/>, 
            render: row => {
                return (
                    
                    <>
                        {/* user uploaded */}
                        {row.state === 'd' && (
                            <>
                                <Tag>
                                    Waiting for user to upload
                                </Tag>
                            </>
                        )}
                        {row.state === 'u' && (
                            <> 
                                <Tag color="processing">
                                    User Uploaded
                                </Tag>
                                
                            </>
                        )}
                        {row.state === 's' && (
                            <>  
                                <Tag color="cyan">
                                    Seller uploaded, waiting for manager
                                </Tag>
                                
                            </>
                        )}
                        {row.state === 'ma' && (
                            <>
                                <Tag color="geekblue">
                                    Manager accepted, waiting for user to confirm
                                </Tag>
                                
                            </>
                        )}

                        {row.state === 'a' && (
                            <>
                                <Tag  color="success">
                                    Active 
                                </Tag>
                            </>
                        )}
                        

                        {row.state === 'b' && (
                            <>
                               <Tag color="error">
                                    Banned
                               </Tag>
                            </>
                        )}

                        
                        {row.state === 'sr' && (
                            <> 
                                <Tag color="warning">
                                    Seller Rejected
                                </Tag>
                               
                            </>
                        )}

                        {row.state === 'mr' && (
                            <>
                                <Tag color="warning">
                                    Manager Rejected
                                </Tag>
                                <a>
                                    <Popover placement="bottom" 
                                                
                                                title={<FormattedMessage id='Reason of rejection'/>} 
                                                content={row.rejection_message} 
                                                trigger="click">
                                        <QuestionCircleOutlined />
                                    </Popover>
                                </a>
                            </>
                        )}

                        {row.state === 'ur' && (
                            <>
                                <Tag color="warning">
                                    User Rejected
                                </Tag>
                            </>
                        )}

                    </>
                )
            }
        }, 
        {
            title: <FormattedMessage id='Actions'/>,
            width:'100px',
            render: row => {

                return (
                    <>
                        <Dropdown overlay={
                            <Menu>
                             
                                <Menu.Item key={'Ban'}>
                                    <a onClick={e => console.log(row) }>
                                        <StopOutlined />  <FormattedMessage id='Ban'/>
                                    </a>
                                </Menu.Item>

                                {row.state === 'u' && (
                                    <Menu.Item key={'Ban'}>
                                        <a onClick={e => {
                                            setNda(row)
                                            setRejectionModal(true)
                                        } }>
                                          <CloseCircleOutlined />  <FormattedMessage id='Reject'/>
                                        </a>
                                    </Menu.Item>
                                )}


                            </Menu>
                            } placement="bottomRight" arrow trigger={['click']}>
                            <Button className='btn-icon' color='primary'>
                                <FormOutlined />
                            </Button>
                        </Dropdown>

                    </>
                )
            }
        }
    ]

     
    
    return (
        <>

            <Modal
                visible={rejectionModal}
                title={null}
                centered 
                footer={
                    <Button type="primary" danger
                            disabled={inProgress}
                            onClick={() => { rejectNda() }}>
                        <FormattedMessage id="Reject"/>
                    </Button>
                }
                onCancel={() => { setRejectionModal(false) }}
                >
                
                <Row gutter={[24,24]}>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    
                        <FormattedMessage id="Reason of rejection">
                            {(msg) => {
                                return (
                                    <>
                                    <Title level={5}>
                                        <FormattedMessage id="Rejection Message" />
                                    </Title>
                                    <TextArea rows={4} placeholder={msg} value={rejection} onChange={e => { 
                                        setRejection(e.target.value)
                                    }}/>
                                    </>
                                )
                            }}
                        </FormattedMessage>
                    </Col>

                </Row>


            </Modal>

            <Card className="news-card">
                <Title level={5} strong>
                    <FormattedMessage id="Information Packages" />
                </Title>
                    {!props.isFetching ? (
                        <Table
                            size="small"
                            dataSource={props?.nda_table}
                            columns={ndaColumns}  
                            pagination={{ pageSize: 5 }} 
                            rowKey="package_id"  
                        />
                        ) : (
                            <div style={{minHeight:'300px', textAlign:'center'}}>
                                
                                <Spin style={{marginTop: "75px"}} size="large" />
                            
                            </div>
                    )}
            </Card>
        </>
    )
}

export default NdaTable