import React, { useState, useEffect } from 'react'
import { Tabs, Divider, Select, Checkbox, Input, Modal, Avatar, Space, Dropdown, Menu, Popover, Button, Row, Col, Table, Spin, Typography, message, Card, Tag } from "antd"
import { FormattedMessage, useIntl } from 'react-intl'
import { Navigate, Link } from 'react-router-dom'
import moment from 'moment';
import axios from "axios"
import domain from "../../../domain"
import HTMLReactParser from "html-react-parser"

import { ExperimentOutlined, DoubleRightOutlined, EditOutlined, PrinterOutlined, FormOutlined, QuestionCircleOutlined } from '@ant-design/icons';


const { Title, Text } = Typography
const { TextArea } = Input;
const { Option } = Select;

function VerifyMessagesTable(props) {
    const intl = useIntl()

    const [message_modal, setMessageModal] = useState(false)

    const [message_item, setMessageItem] = useState({})
    const [messageOriginalContent, setMessageOriginalContent] = useState("")
    const [messageOriginalLang, setMessageOriginalLang] = useState(null)

    const [wantTranslation, setWantTranslation] = useState(false)
    const [targetLang, setTargetLang] = useState(null)

    const verifyMessage = async() => {
        props?.setInProgress(true)
        const token = localStorage.getItem("token")
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.defaults.xsrfCookieName = "csrftoken"
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`
        }

        const context = {
            ...message_item,
            modified : (message_item.content !== messageOriginalContent) || (message_item.language_given !== messageOriginalLang)
        }

        console.log(context)

        await axios.post(`${domain}/manager/dashboard/verify/message/`, context)
        .then(res => {
           
            if (res.data.error) { 
                message.error(res.data.message)
            } else {
                message.success(res.data.message)
                setMessageModal(false)
                setMessageItem({})
                props?.refetch()
            }   
        })
        .catch(err => {
            message.error(err)
        })
        props?.setInProgress(false)
    }

    const requestTranslation = async() => {
        if (targetLang !== null) {
            props?.setInProgress(true)
            const token = localStorage.getItem("token")
            axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
            axios.defaults.xsrfCookieName = "csrftoken"
            axios.defaults.headers = {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`
            }
            const context = {
                ...message_item, 
                target: targetLang,
                modified : (message_item.content !== messageOriginalContent) || (message_item.language_given !== messageOriginalLang),
            }

            console.log(context)
            
            await axios.post(`${domain}/manager/dashboard/request/translation/`, context)
            .then(res => {
                if (res.data.error) { 
                    message.error(res.data.message)
                } else {
                    message.success(res.data.message)
                    setMessageModal(false)
                    setWantTranslation(false)
                    setMessageItem({})
                    props?.refetch()
                }
            })
            .catch(err => {
                message.error(err)
            })

            props?.setInProgress(false)

        } else {
            const translatedMessage = intl.formatMessage({id: "Language not selected!"})
            message.error(translatedMessage)
        }
    }

    const messageColumns = [
        {
            title: <FormattedMessage id="Content"/>,
            key: 'content',
            dataIndex: 'content'
        },
        {
            title: <FormattedMessage id='Sender'/>,
            
            render: row => (
              <div className='d-flex align-items-center'>
              <Space>
                <Avatar size={32} src={row.sender.picture} />
    
                <div className='user-info text-truncate'>
                    <span className='d-block font-weight-bold text-truncate'>{row.sender.username}</span><br/>
                    <small>

                    <Tag color={row?.sent_from_status === 's' ? 'blue' : 'green'}>
                        {row?.sent_from_status === 's' ? (<FormattedMessage id="Seller"/>) : (<FormattedMessage id="User"/>)}
                    </Tag>

                    </small>
                </div>
                </Space>
              </div>
            )
        },
        {
            title: <FormattedMessage id="Date Sent"/>,
            
            defaultSortOrder: 'ascend',
            sorter: (a, b) => moment(b.date_sent).unix() - moment(a.date_sent).unix(),
            render: row => {
                console.log(row)
                const date = new Date(row.date_sent)
                return (
                    <>
                        {date.toLocaleString()}
                    </>
                )
            }
        },
        {
            title: <FormattedMessage id="Language given"/>,
            key: 'language_given',
            dataIndex: 'language_given'
        },
        {
            title: <FormattedMessage id='Actions'/>,
            render: row => (
                <>
                    <Dropdown overlay={
                        <Menu>
                         
                            <Menu.Item key={'message-overview'}>
                            <a onClick={e => {
                                setMessageItem(row)
                                setMessageOriginalContent(row.content)
                                setMessageOriginalLang(row.language_given)
                                setMessageModal(true)
                            }}>
                                <ExperimentOutlined /> <FormattedMessage id="Message Overview"/>
                            </a>
                            </Menu.Item>
                        </Menu>
                    } placement="bottomRight" arrow trigger={['click']}>
                        <Button className='btn-icon' color='primary'>
                            <FormOutlined />
                        </Button>
                    </Dropdown>
                </>
            )
        }
    ]
    return (
        <>
            <Modal
            visible={message_modal}
            title={<FormattedMessage id="Message"/>}
            centered
            footer={wantTranslation ? (
                <Button type="primary" 
                        disabled={targetLang === null || targetLang === message_item.language_given || props?.inProgress}
                        onClick={() => { requestTranslation() }}>
                    <FormattedMessage id="Request Translation"/>
                </Button>
            ) : (
                <Button type="primary" onClick={() => { verifyMessage() }} disabled={props?.inProgress}>
                    <FormattedMessage id="Verify Message"/>
                </Button>
            )}
            onCancel={() => { setMessageModal(false) }}
            
            >

                

                <Row gutter={[24,24]}>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>

                            <Title level={5}><FormattedMessage id="Offer title"/></Title>
                            <Text>{message_item.offer_title}</Text>
                
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        
                            <Title level={5}><FormattedMessage id="Original Message"/></Title>
                            <Text>{messageOriginalContent}</Text>
                
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24} className='mt-1'>
            
                            <FormattedMessage id="Edit Message">
                                {(msg) => {
                                    return (
                                        <>
                                        <Title level={5}>{msg}</Title>
                                        <TextArea rows={4} placeholder={msg} value={message_item.content} onChange={e => { 
                                            setMessageItem({...message_item, content : e.target.value}) 
                                        }}/>
                                        </>
                                    )
                                }}
                            </FormattedMessage>
            
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            
                            
                            <Checkbox onChange={e => setWantTranslation(e.target.checked)} checked={wantTranslation} >
                                <FormattedMessage id='Require Translation'/>
                            </Checkbox>
            
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>   
                            <FormattedMessage id="Language given">
                                {(msg) => {
                                    return (
                                        <>
                                        <Title level={5}>{msg}</Title>
                                        <Select
                                            showSearch
                                            style={{ width: "100%" }}
                                            placeholder={msg}
                                            optionFilterProp="children"
                                            onChange={e => setMessageItem({...message_item, language_given : e})}
                                            defaultValue={message_item.language_given}
                                            filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                        >
                                        {props?.options?.languages?.map(item => ( 
                                        <Option key={item} value={item}>{item}</Option>
                                        ))}
                                    </Select> 
                                        </>
                                    )
                                }}
                            </FormattedMessage>
                    </Col>
                    {wantTranslation && (
                        <>
                        
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>   
                            <FormattedMessage id="Target language">
                                {(msg) => {
                                    return (
                                        <>
                                        <Title level={5}>{msg}</Title>
                                        <Select
                                            showSearch
                                            style={{ width: "100%" }}
                                            placeholder={msg}
                                            optionFilterProp="children"
                                            onChange={e => setTargetLang(e)}
                                            filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                        >
                                        {props?.options?.languages?.map(item => ( 
                                        <Option key={item} value={item}>{item}</Option>
                                        ))}
                                    </Select> 
                                        </>
                                    )
                                }}
                            </FormattedMessage>
                        </Col>
                        </>
                    )}
                </Row>
            </Modal>

            <Card className="news-card">
                <Title level={5} strong>
                    <FormattedMessage id="Messages to be verificated"/>
                </Title>
                    {!props.isFetching ? (
                        <Table
                            size="small"
                            dataSource={props?.messages}
                            columns={messageColumns}  
                            pagination={{ pageSize: 5 }} 
                            rowKey="message_id" 
                        />
                        ) : (
                            <div style={{minHeight:'300px', textAlign:'center'}}>
                                
                                <Spin style={{marginTop: "75px"}} size="large" />
                            
                            </div>
                    )}
            </Card>
        </>
    )
}

export default VerifyMessagesTable