import React, { useState, useEffect } from 'react'
import { Switch, Alert, Divider, Modal, Input, Dropdown, Menu, Popover, Button, Row, Col, Table, Spin, Typography, message, Card, Tag } from "antd"
import { FormattedMessage } from 'react-intl'
import { Navigate, Link } from 'react-router-dom'
import moment from 'moment';
import axios from "axios"
import domain from "../../../domain"
import HTMLReactParser from "html-react-parser"

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

import { CloseOutlined, unCheckedChildren, CheckOutlined, ExperimentOutlined, FormOutlined, QuestionCircleOutlined } from '@ant-design/icons';


const { SubMenu } = Menu;
const { Title, Text } = Typography
const { TextArea } = Input;

function TranslationUpdate(props) {
    const [inProgress, setInProgress] = useState(false)
    const [translation_id, setTranslationId] = useState(null)

    const [modal, setModal] = useState(false)
    
    const [original, setOriginal] = useState({})
    const [old, setOld] = useState({}) // old original

    const [title, setTitle] = useState("")
    const [status, setStatus] = useState("")
    const [description, setDescription] = useState("")

    
    // these ones, will be able to be changed through toggle 
    const [showTarget, setShowTarget] = useState({})
    // const [showStatus, setShowStatus] = useState("")
    // const [showDescription, setShowDescription] = useState("")


    // if false, then show old original
    const [showOriginal, setShowOriginal] = useState(true)



    useEffect(() => {   
        if (showOriginal) {
            setShowTarget(original)
            
        } else {
            setShowTarget(old) 
        }
    }, [showOriginal])



    const updateColumns = [
        {
            title: <FormattedMessage id="Offer title"/>,
            key: 'title',
            dataIndex: 'title'
        },
        {
            title: <FormattedMessage id="Language given"/>,
            key: 'original_lang',
            dataIndex: 'original_lang'
        },
        {
            title: <FormattedMessage id="Translation required"/>,
            key: 'target',
            dataIndex: 'target'
        },
        {
            title: <FormattedMessage id='Actions'/>,
            render: row => (
                <>
                    <Dropdown overlay={
                        <Menu>
                         
                         <Menu.Item key={'overview'}>
                         <a onClick={e => {
                            setOriginal(row.original)
                            setOld(row.old_original)
                            
                            setTitle(row.current.title)
                            setStatus(row.current.status)
                            setDescription(row.current.description)
                            
                            setShowTarget(row.original)
                            setTranslationId(row.translation_id)
                            setModal(true)
                         } }>
                         <ExperimentOutlined /> <FormattedMessage id="Overview"/>
                         </a>
                         </Menu.Item>
                     </Menu>
                    } placement="bottomRight" arrow trigger={['click']}>
                        <Button className='btn-icon' color='primary'>
                            <FormOutlined />
                        </Button>
                    </Dropdown>
                </>
            )
        }
    ]


    const updateTranslation = async () => {
        setInProgress(true)

        const context = {
            translation_id,
            title, 
            status, 
            description 
        }
        console.log(context)

     
        const token = localStorage.getItem("token")
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.defaults.xsrfCookieName = "csrftoken"
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`
        }
        
        await axios.post(`${domain}/translator/dashboard/edit-translation/`, context)
        .then(res => {
       
            message.success(res.data.message)
            
            props?.refetch()
            setModal(false)
        })
        .catch(err => {
            err?.response?.data?.message?.map(mes => {
                message.error(mes)
            })
        })


        setInProgress(false)
    }

    return (
    <>
        <Modal
        style={{marginTop: "100px"}}
          className='translation-modal'
          width={'100%'}
          visible={modal}
          centered
          footer={
              <>
                  <Button type="primary" 
                        disabled={inProgress}
                        
                        onClick={() => { updateTranslation() }}>
                    <FormattedMessage id="Submit"/>
                </Button>
              </>
          }
          onCancel={() => { 
              setModal(false)
           }}
         
        >
            <Row gutter={[24,24]}>
                
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Switch 
                        size='large'
                        style={{paddingLeft: "10px", paddingRight: "10px"}}
                        defaultChecked={showOriginal}
                        disabled={inProgress}
                        checkedChildren={'CURRENT'}
                        unCheckedChildren={'OLD VERSION'}
                        onChange={e => { setShowOriginal(!showOriginal) }} />

                </Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                       {/*  {targetOffer?.rejection_message && (<Alert message={targetOffer?.rejection_message} type="error" className='mb-1'/>)} */}
                        <Title level={5}><FormattedMessage id="Offer title"/></Title>
                        <Text>{showTarget?.title}</Text>
              
                </Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
      
                        <Title level={5}><FormattedMessage id="Status"/></Title>
                        <Text>{showTarget?.status}</Text>
            
                </Col>

                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
      
                        <Title level={5}><FormattedMessage id="Description"/></Title>
                        {showTarget?.description && (HTMLReactParser(showTarget?.description))}
            
                </Col>
                <Divider />
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <FormattedMessage id="Offer title">
                        {msg => {
                            return (
                            <>
                            <Title level={5}>{msg}</Title>
                            <Input 
                                    name='title'
                                    value={title}
                                    placeholder={msg}
                                    onChange={(e) => {  
                                        setTitle(e.target.value) 
                                    }}
                                    />
                            </>
                            )
                        }}
                    </FormattedMessage>
                </Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
      
                    <FormattedMessage id="Status">
                            {msg => {
                                return (
                                <>
                                <Title level={5}>{msg}</Title>
                                <Input 
                                        name='status'
                                        value={status}
                                        placeholder={msg}
                                        onChange={(e) => {  setStatus(e.target.value) }}
                                        />
                                </>
                                )
                            }}
                        </FormattedMessage>
            
                </Col>

                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <FormattedMessage id="Description">
                            {msg => {
                                return (
                                <>
                                <Title level={5}>{msg}</Title>
                                <CKEditor
                                    editor={ Editor }
                                    data={description}
                                    name={msg}
                                    onChange={ ( e, editor ) => setDescription(editor.getData()) }
                                />
                                </>
                                )
                            }}
                    </FormattedMessage>
                </Col>


            </Row>
        </Modal>

        <Card className="news-card">
            <Title level={5} strong>
                <FormattedMessage id="Translations to be updated"/>
            </Title>
            {!props?.isFetching ? (
                <Table
                    size="small"
                    dataSource={props?.needs_update}
                    columns={updateColumns}  
                    pagination={{ pageSize: 5 }}   
                    rowKey="translation_id"
                />
                ) : (
                    <div style={{minHeight:'300px', textAlign:'center'}}>
                        
                        <Spin style={{marginTop: "75px"}} size="large" />
                    
                    </div>
            )}
        </Card>
    </>
  )
}

export default TranslationUpdate