import React from 'react'
import  { InputNumber, Button, Row, Col,  Upload, Typography, Form} from "antd"
import { FormattedMessage } from 'react-intl'


const { Title, Text } = Typography

function OfferInformationPrice(props) {
    return (
    <>
        <Row gutter={[12, 12]} className="document-card">
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Title level={4}>
                        <FormattedMessage id="Information Package Price"/>
                    </Title>
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <InputNumber addonBefore="IPPs"   
                                status={Number(props?.info_price) === props?.info_price && props?.info_price % 1 !== 0  ? "error" : "none" }
                                min={0} 
                                addonAfter={`~ ${props?.info_price * 0.125} €`} 
                                step={10}
                                value={props?.info_price}  
                                onChange={e => props?.setInfoPrice(Math.ceil(e))}/>
            </Col>
        </Row>
    </>
  )
}

export default OfferInformationPrice