import React, { Fragment, useEffect, useState } from 'react'
import { useParams } from "react-router-dom"

import { Carousel, Image, Alert, Table, Menu, Dropdown, Skeleton,  Row, Tag, Divider, Col, Typography, Checkbox, message, Avatar, Space } from "antd"
import { DownOutlined, DownloadOutlined } from '@ant-design/icons';


import { Helmet } from "react-helmet";
import axios from 'axios'
import { FormattedMessage } from 'react-intl'
import HTMLReactParser from "html-react-parser"
import { Swiper, SwiperSlide } from 'swiper/react'
import { Keyboard, Pagination, Navigation } from "swiper";


import domain from "../../../domain"
import CustomTimeline from "../../../utils/Timeline/CustomTimeline"
import OfferOverview from './OfferOverview';
import BuyNegotiation from './BuyNegotiation';
import BuyInformation from './BuyInformation';
import ChangeAutoRenew from './ChangeAutoRenew';
import EditLink from './EditLink';


import ExternalsTable from './ExternalsTable';
import { useGetUserInfoQuery } from "../../../services/mainApi"
import DetailFiles from './DetailFiles';
import DetailNda from './DetailNda';


// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import '../../editor.css'



const { Title, Text } = Typography


function OfferDetail(props) {
  
  const { data:userData, isFetching, refetch } = useGetUserInfoQuery()
  
  const { offerId } = useParams() 
  const [detail, setDetail] = useState(null)

  const [reload, setReload] = useState(false)

  const [bookmarked, setBookmark] = useState(false)
  const [inProgress, setInProgress] = useState(false)

  
  const [files, setFiles] = useState([])
  const [files_translation_requests, setFilesTranslationRequests] = useState([])
  
  useEffect(() => {
    
    if (props.overview) {
      props.setBc2(<FormattedMessage id="Offer" />)
      props.setBc3(<FormattedMessage id="Detail" />)
      
    } else {
      props.setBc2(<FormattedMessage id="Offer" />)
      props.setBc3(<FormattedMessage id="Overview" />)
    }

    
    setInProgress(true)


    async function fetchDetail() {
      await axios.get(`${domain}/api/offer/detail/${offerId}/`).then(res => {
        setDetail(res.data)
        setBookmark(res.data.bookmarked)
        setInProgress(false)

        setFiles(res.data.files)
        setFilesTranslationRequests(res.data.files_translation_requests)
        
      })
    }
    fetchDetail()


  }, [reload])

  

  const callBookmark = async () => {
    setInProgress(true)
    
    const token = localStorage.getItem("token")
 
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
    axios.defaults.xsrfCookieName = "csrftoken"
    axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
    }
    const context = {
      offer_id : offerId,
      bookmarking : !bookmarked
    }

    await axios.post(`${domain}/api/offer/bookmark/`, context)
    .then(res => {
        message.success(res.data.message)
        setBookmark(!bookmarked)
    })
    .catch(err => {
        err?.response?.data?.message?.map(mes => {
          message.error(mes)
        })
        console.log(err.response)
    })
    
    setInProgress(false)
  }


  return (
    <>
    
    <Row gutter={[24,24]}>
      
      <Col xl={18} lg={18} md={24} sm={24} xs={24}>
        {/* show this error if offer visited while not being verified yet */}
        {(detail?._status === 'd' && !props.overview) && (
          <Col xl={24} lg={24} md={24} sm={24} xs={24} className="mb-2"><Alert message="This offer has not been verified yet by manager!" type="error" /></Col>
        )}
          <Row className='mb-4'>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <div className={'manual-slider-container'}>

                        <Image.PreviewGroup>
                            <Swiper slidesPerView={1}
                                    spaceBetween={30}
                                    keyboard={{
                                      enabled: true,
                                    }}
                                    pagination={{
                                      clickable: true,
                                    }}
                                   
                                    navigation={true}
                                    modules={[Keyboard, Pagination]}
                                    className="mySwiper">
                                {!detail?.slides && (
                                  <Skeleton.Input style={{ "height" : "415px"}} active={true} size={'large'} block={true}/>
                                )}
                                  {detail?.slides?.map((source, index )=> {
                                      return (
                                          <SwiperSlide key={index} className={'single-slider-container'}>
                                                <h1 style={{ "color" :  "#283046"}}>
                                                  {source.ref}
                                                </h1>
                                                <Image src={source.url} alt={source.ref} className='img-fluid' />
                                                
                                              
                                             
                                          </SwiperSlide>
                                        
                                      )
                                  })}  
                          </Swiper>
                      </Image.PreviewGroup>
                  </div>
              </Col>
          </Row>
          <Row  gutter={[12, 12]}>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    {!detail?.translation?.title && (
                      <Skeleton.Input active={true} size={'large'} block={true}/>
                    )}
                    <Title level={3}>{detail?.translation?.title}</Title>
                    <Text level={5}>{detail?.translation?.status}</Text>
                </Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    {!detail?.translation?.title && (
                      <Skeleton active />
                    )}
                    <Text>
                        {detail?.tags?.map((tag, index) => <Tag key={index} style={{margin: "8px 8px 0 0"}} color="geekblue">{tag}</Tag>  )}
                    </Text>
                </Col>
                {detail !== null && (
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <div className='ck-content'>
                          {HTMLReactParser(detail?.translation?.description)}
                      </div>
                  </Col>
                )}
          </Row>
          <Divider/>
          
          {(detail !== null && !props.overview) && (
              <Row gutter={[12, 12]}>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Checkbox 
                          onChange={() => { callBookmark() }}
                          checked={bookmarked}
                          disabled={inProgress}
                            >
                        <FormattedMessage id={bookmarked ? 'Remove Bookmark' : 'Bookmark'} /> 
                      </Checkbox>
                  </Col>
              </Row>
          )}

         
            {/* show this if info state is active, or if visited by seller/manager */}
         {((detail?.access_vars?.show_info && detail?.access_vars?.info_state === 'a') || 
            detail?.access_vars?.show_externals) && (
            <>
              <Row className='mt-3'>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                      <Title level={4} >
                          <FormattedMessage id="Seller Info"/>
                      </Title>
                      
                      <Space className='mt-2'>
                        <Avatar size={48} src={detail?.information_package?.seller?.picture} />

                        <div className='ml-1'>
                            <Title level={5} className='mb-0'>{detail?.information_package?.seller?.name}</Title> 
                            <small style={{opacity: '0.5', textTransform: 'uppercase'}}>Seller</small>
                        </div>
                      </Space>
                      <Title level={5} className='mt-2'>
                          <FormattedMessage id="Spoken Languages"/>
                      </Title>
                      <Text>
                        {detail?.information_package?.seller?.spoken_languages.join(', ')}
                      </Text>
                      
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                      <Title level={4} >
                          <FormattedMessage id="Company Info"/>
                      </Title>
                      <Title level={5} className='mt-2 mb-0'>
                        {detail?.information_package?.company?.show_company_type}, {detail?.information_package?.company?.name} {detail?.information_package?.company?.show_location}  
                      </Title> 
                      <Text>{detail?.information_package.company.description}</Text><br/>

                      <Title level={5} className='mt-1 mb-0'>
                        <FormattedMessage id="Company Type"/>
                      </Title> 
                      <Text>{detail?.information_package.company?.show_company_type}</Text><br/>

                      <Title level={5} className='mt-1 mb-0'>
                        <FormattedMessage id="Stock Number"/>
                      </Title> 
                      <Text>{detail?.information_package.company.stock_number}</Text><br/>
                      
                      <Text className='mt-1'><a href={detail?.information_package?.company?.website_url} >{detail?.information_package?.company?.website_url}</a></Text>
                     
                  </Col>
              </Row>
            </>
         )}
          {detail?.access_vars?.show_externals && (
              <ExternalsTable externals={detail?.externals}/>
          )}
          {/* show nego will never be true if  visited by manager */}
          {detail?.access_vars?.show_nego && (
            <>
              <Row className='mt-3'>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <CustomTimeline online={detail?.negotiation_package?.is_online} userPage={true} packageId={detail?.negotiation_package?.package_id} />
                </Col>
              </Row>
            </>
              
          )}


      </Col>

      <Col xl={6} lg={6} md={24} sm={24} xs={24}>
            {(!detail?.access_vars) && (
                <Skeleton active />
            )}
            {/* logic stands here that in both cases of visited by creator  and visited by manager, would display visited by seller, but when visited by manager the overview will be true    */}
            
            {detail?.access_vars?.show_externals ? (
              props.overview ? (
                <OfferOverview {...{reload, setReload, offerId, status: detail?._status, files}}/>
              ) : (
                <>
                  {/* {userData.status !== 'Manager' && (
                    <EditLink />
                  )} */}
                  
                  <EditLink />
                </>
               
              )
            ) :  (
              <>
                {(!detail?.access_vars?.show_info && detail !== null) && (
                      <BuyInformation {...{reload, setReload, offerId, refetch, info_price : detail?.information_price, isFetching : inProgress }}/>  
                )}
                {/* this means that admin/seller will never be able to access this, since they have show_externals to true */}
                {(!detail?.access_vars?.show_nego && !detail?.access_vars?.show_externals && detail !== null) && (
                  
                      <BuyNegotiation {...{reload, setReload, offerId, refetch, isFetching : inProgress}} />
                
                )}
                {detail?.access_vars?.show_nego && (
                      <ChangeAutoRenew {...{reload, setReload, offerId, auto_renew : detail?.negotiation_package?.auto_renew, isFetching : inProgress}} />
                )}
              </>
            ) }
            
            
            
            {(detail?.access_vars?.show_info && !detail?.access_vars?.show_externals) && (
                <>
                  <Divider/>
                  <DetailNda {...{ reload, setReload, 
                              nda_protected : detail?.nda_protected,  
                              nda : detail?.nda, 
                              nda_serializer : detail?.information_package?.info_serializer,
                              done : detail !== null, 
                              access_vars : detail?.access_vars }}/>
                </>
            )}
            
            
            <Divider/>

            <DetailFiles {...{ files, setFiles, 
                                overview : props?.overview, 
                                access_vars : detail?.access_vars, 
                                status: detail?._status, 
                                reload, setReload,
                                files_translation_requests}} />
            
            <Divider/>

            <Row gutter={[12, 12]}>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}> 
                    <Title level={5}>
                      <FormattedMessage id="Patent Family"/>
                    </Title>
                </Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    {!detail?.access_vars && (
                      <Skeleton.Input active={true} size={"medium"} block={true}/>
                    )}
                    {detail?.access_vars?.show_info ? (
                      detail?.has_patents ? (
                      detail?.patents?.map((patent_family, family_index) => {
                        return (
                            

                            <Dropdown key={family_index} overlay={
                              <Menu>
                                <Menu.ItemGroup title={`Patents in the family of ${patent_family?.main}`}>
                                  {patent_family?.pdfs?.map((child, index) => {
                                      return (
                                        <Menu.Item key={index}>
                                        <Tag color={child.name === patent_family?.main ? '#7367f0' : "#87d068"} >
                                            {child.name} 
                                            {((detail?.access_vars?.show_info && detail?.access_vars?.info_state === 'a') || 
                                              (detail?.access_vars?.show_externals)) && (
                                              <a href={child.url} style={{marginLeft: '10px'}} download >
                                                <DownloadOutlined/>
                                              </a>
                                            )}
                                            
                                          </Tag>
                                        </Menu.Item>
                                      )
                                  })}
                                  
                                </Menu.ItemGroup>

                                {patent_family?.file && (
                                  <Menu.ItemGroup title={`File for ${patent_family?.main}`}>
                                  
                                    <Menu.Item>
                                          <Tag color="#7367f0">
                                              {patent_family?.main} 
                                              {detail?.show_info && (
                                                <a href={patent_family?.file} style={{marginLeft: '10px'}} download >
                                                  <DownloadOutlined/>
                                                </a>
                                              )}
                                              
                                            </Tag>
                                        
                                    </Menu.Item>
                                </Menu.ItemGroup>
                                )}
                                
                              </Menu>}>
                           
                              
                                <Title level={5} className="ant-dropdown-link">
                                <FormattedMessage id="Family for"/> {patent_family.main} <DownOutlined />
                                </Title>
                             
                            </Dropdown>
                             
                        )
                      })
                    ) : (
                      <Title level={5}><FormattedMessage id="No patents"/></Title>
                    )
                    ) : (
                      detail !== null && (<Title level={5}><FormattedMessage id="Buy information package to get more information about the patents"/></Title>)
                    )}
                </Col>
            </Row>

      </Col>
    </Row>
      
    </>
  )
}


export default OfferDetail