import React from 'react'
import  { Switch, Button, Row, Col,  Upload, Typography, Form} from "antd"
import { UploadOutlined} from '@ant-design/icons';

import { FormattedMessage } from 'react-intl'

const { Title, Text } = Typography

function OfferNDA(props) {

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
          onSuccess("ok");
        }, 0);
      };

    
    return (
        
        <>
            <Row gutter={[12, 12]} className="document-card">
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Title level={4}>
                        <FormattedMessage id="NDA Protection"/>
                    </Title>
                    <Text>
                        <FormattedMessage id="Is this offer NDA protected?"/>
                    </Text>
                    <FormattedMessage id="Yes">
                        {(yes) => (
                            <FormattedMessage id="No">
                                {(no) => (
                                
                                    <Title level={5}>
                                        <Switch 
                                            size='large'
                                            style={{paddingLeft: "10px", paddingRight: "10px"}}
                                            defaultChecked={props?.nda_protected}
                                            checkedChildren={yes}
                                            unCheckedChildren={no}
                                            onChange={e => { props?.setNdaProtected(!props?.nda_protected) }} />
                                    </Title>
                                 
                                )}
                            </FormattedMessage>
                        )}
                    </FormattedMessage>

                    
                       

                </Col>
                {props?.nda_protected && (
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Upload 
                            customRequest={dummyRequest}
                            onChange={e => { props?.uploadNda(e.fileList[0]) }}
                            maxCount={1}
                            >
                            <Button icon={<UploadOutlined />}>
                               {" "} <FormattedMessage id="Upload NDA" />
                            </Button>
                        </Upload>
                    </Col>
                )}
                
            </Row>
        </>

    )
}

export default OfferNDA