import React, { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { Row, Col } from "antd"

import { useGetSellerDashboardQuery} from "../../../services/mainApi"
import { FormattedMessage } from 'react-intl'

import OffersTable from './OffersTable'
import TimelinesTable from './TimelinesTable'
import NdaTable from './NdaTable'



const SellerDashboard = (props) => {
    const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("userData"))) 
  
    const {data : seller, isFetching, refetch} = useGetSellerDashboardQuery()

    useEffect(() => {
        props.setBc2(<FormattedMessage id="Seller" />)
        props.setBc3(<FormattedMessage id="Dashboard" />)
    }, [])

    // so that it wont redirect if userdata is not loaded yet
    if (!userData.is_seller) return (<Navigate to="/offers" push />)
    
    return (
        <>
            <Row gutter={[24, 24]} className="form-row">
                <Col key={"offers-table"} xl={24} lg={24} md={24} sm={24} xs={24}>
                    <OffersTable statistics={seller?.statistics} refetch={refetch} isFetching={isFetching} />
                </Col>

                
                <Col key={"timelines-table"} xl={24} lg={24} md={24} sm={24} xs={24}>
                    <TimelinesTable timeline_info={seller?.timeline_info} isFetching={isFetching} />
                </Col>

                <Col key={"nda-table"} xl={24} lg={24} md={24} sm={24} xs={24}>
                    <NdaTable nda_table={seller?.nda_table} refetch={refetch} isFetching={isFetching}/>
                </Col>

            </Row>
        </>
    )
}

export default SellerDashboard