import React, { useState } from 'react'
import  { Checkbox,  Button, Space, message, Row, Col, Input, Upload } from "antd"
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import { FormattedMessage } from 'react-intl'


function OfferThumbnailEdit(props) {
    const [loading, setLoading] = useState(false)


    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
          onSuccess("ok");
        }, 0);
    };

    function getBase64Thumbnail(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    function thumbnailBeforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
          } else {
            
                props?.setThumbnail({
                    ...props?.thumbnail, 
                    changed : true, 
                    file : file
                })
 
          }
          return isJpgOrPng;
    }

    const handleThumbnailChange = (info) => {
       
        if (info.file.status === 'uploading') {
            setLoading(true)
            return;
        }
   
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64Thumbnail(info.file.originFileObj, thumbnail =>
                {
            
                    props?.setThumbnail({
                        ...props?.thumbnail, 
                        url : thumbnail
                    })
                },
            );
        }
    }


    return (
    <>
      
        <Row className='form-row'>
                <Space align="start">
                                
                        <Row style={{"width" : "422px"}}  gutter={[0,6]}>
                            <Col lg={24}>
                                <Upload
                                    
                                    name={`thumbnail`}
                                    listType="picture-card"
                                    className="avatar-uploader thumbnailer"
                                    showUploadList={false}
                                    
                                    beforeUpload={(file) => { thumbnailBeforeUpload(file) }}
                                    onChange={(file) => { handleThumbnailChange(file) }}
                                    customRequest={dummyRequest}
                                >
                                    {props?.thumbnail?.url ? <img src={props?.thumbnail?.url} alt="avatar" style={{ height: "100%", maxWidth: "100%" }} /> : (
                                        <div>
                                            {loading ? <LoadingOutlined /> : <PlusOutlined />}
                                            <div style={{ marginTop: '20px' }}>
                                                Upload
                                            </div>
                                        </div> 
                                    )}
                                </Upload> 
                            </Col>
                            <Col lg={24}>
                                <Input 
                                    name='status'
                                    value={props?.thumbnail?.ref}
                                    placeholder={"reference"}
                                    onChange={(e) => { props?.setThumbnail({
                                        ...props?.thumbnail, 
                                        ref : e.target.value
                                    }) }}
                                    />
                            </Col>
                            
                        </Row>
                   
                </Space>
        </Row>
                     
    </>
  )
}

export default OfferThumbnailEdit