// ** React Imports
import React, { Fragment, useState, useEffect, forwardRef } from 'react'
import { FormattedMessage } from 'react-intl'

// ** Add New Modal Component
import AddExternal from './AddExternal'
// ** Third Party Components


import { CaretDownOutlined, DoubleRightOutlined } from '@ant-design/icons';

import { Input, Table, Row, Col, Button, Avatar, Tag, Space, Typography } from "antd"

import { useGetOptionsQuery } from "../../services/mainApi"

const { Title } = Typography




const SelectExternals = (props) => {
  const { data: options, isFetching, refetch } = useGetOptionsQuery()

  const [externals, setExternals] = useState(props?.externals)
  
  // ** States
  const [modal, setModal] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [filteredData, setFilteredData] = useState([])
  

  useEffect(() => {
      setExternals(options?.extra)
  }, [isFetching])

  // ** Function to handle Modal toggle
  const handleModal = () => setModal(!modal)

  // ** Function to handle filter
  const handleFilter = e => {
    const value = e.target.value

    let updatedData = []
    setSearchValue(value)

    if (value.length) {
      updatedData = externals.filter(item => {
        
        const startsWith =
          item.name.toLowerCase().startsWith(value.toLowerCase()) ||
          item.email.toLowerCase().startsWith(value.toLowerCase()) ||
          item.country.toLowerCase().startsWith(value.toLowerCase())
          
        const includes =
          item.name.toLowerCase().includes(value.toLowerCase()) ||
          item.email.toLowerCase().includes(value.toLowerCase()) ||
          item.country.toLowerCase().includes(value.toLowerCase())
          

        if (startsWith) {
          return startsWith
        } else if (!startsWith && includes) {
          return includes
        } else return null
      })
      setFilteredData(updatedData)
      setSearchValue(value)
    }
  }


  const columns = [
    {
    
      title: <FormattedMessage id='Name' />,
      selector: 'name',
      render: row => (
        <>
          <div className='d-flex align-items-center'>
            <Space>
              <Avatar size={32} src={row.picture} />

                <div className='user-info text-truncate ml-1'>
                    <span className='d-block font-weight-bold text-truncate'>{row.name}</span>
                    <small>{row.title}</small>
                </div>
            </Space>
            </div>
        </>
        
      )
    },
    {
      title: <FormattedMessage id='Email' />,
      dataIndex: 'email',
    
    },
    {
      title: <FormattedMessage id='Phone' />,
      dataIndex: 'phone',
  
    },
    {
      title: <FormattedMessage id='Country' />,
      dataIndex: 'country',
 
    },
    {
      title: 'WeChat ID',
      dataIndex: 'WeChat_ID',
    
    },
    {
      title: <FormattedMessage id='Joined IP2B' />,
      sortable: true,
     
      render: row => {
        return (
            <Tag color={row.created_profile ? ("green") : ("red")}><FormattedMessage id={row.created_profile ? ('Yes') : ('No')}/></Tag>
        ) 
        }
    }
  ]

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      props.setData(selectedRows)
    },
    getCheckboxProps: (record: DataType) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };


  return (
    <Fragment>
        <Row gutter={[12, 12]} className='mb-2'>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Title level={4}>
            <FormattedMessage id='Select Externals for this offer' />
            <Button style={{float: 'right'}} color='primary' onClick={handleModal}>
                +<FormattedMessage id='Add Record' />
            </Button>
            </Title>
        
            
          </Col>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <FormattedMessage id='Search' >
                {(msg) => {
                    return (
                        <Input
                            placeholder={msg}
                            value={searchValue}
                            onChange={handleFilter}
                            />
                    )
                }}
                
            </FormattedMessage>
          </Col>
        </Row>
        <Row className='form-row'>
          

        {/*  */}
          <Col  xl={24} lg={24} md={24} sm={24} xs={24}>
            <Table
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection,
                }}
                rowKey="email"
                size="small"
                pagination={{ pageSize: 10 }}
                columns={columns}
                dataSource={searchValue.length ? filteredData : externals} 
              />
          </Col>
        </Row>
        
    
      <AddExternal open={modal} refetch={refetch} handleModal={handleModal} setExternalsData={setExternals} countries={props?.countries}/>
      
    </Fragment>
  )
}

export default SelectExternals
