import React, { useState, useContext } from 'react'

import { IntlContext } from './Internalization'
import { FormattedMessage } from 'react-intl'
import { message, Col, Row, Radio, Select, Typography } from "antd"
import { useGetLanguagesQuery } from "../../services/mainApi"

import {useGetOptionsQuery} from '../../services/mainApi'

import axios from "axios"
import domain from "../../domain"

const { Title } = Typography
const { Option } = Select;

function ChangeLang() {
    const { data: options, isFetching:fetchB, refetch:refetchOptions } = useGetOptionsQuery()
    const { data: languages, isFetching:fetchingLanguages } = useGetLanguagesQuery()

    const [loggedIn, setLoggedIn] = useState(localStorage.getItem("token") !== null)
    
    const context = useContext(IntlContext)

    const changeLang = async (symbol) => {
        
        context.switchLanguage(symbol)

        if (loggedIn) {
            const token = localStorage.getItem("token")
       
            axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
            axios.defaults.xsrfCookieName = "csrftoken"
            axios.defaults.headers = {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`
            }
           

            await axios.post(`${domain}/users/change/lang/`, {
                symbol : symbol
            })
            .then(res => {
                if (!res.data.error) {
                    
                    refetchOptions()
                } else {
                    message.error("Something went wrong!")
                }
            
            })
        }
        localStorage.setItem("symbol", symbol)
    }

    return (
        <>
     
            <Row>

                <Col xl={20} lg={20} md={20} sm={20} xs={20} className='change-lang-container'>
                
                    <Title level={2}>
                        <FormattedMessage id="Select your preferred language"/>
                    </Title>

                
                    
                    <Radio.Group className='change-lang-button-container'  onChange={e => { changeLang(e.target.value) }} defaultValue={context.locale}>
                        <Row gutter={[12, 12]}>

                            {fetchingLanguages ? (
                                <>  
                                    <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                                        <Radio.Button  key={"en"} value={"en"}>English</Radio.Button>
                                    </Col>

                                    <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                                        <Radio.Button  key={"de"} value={"de"}>Deutsch</Radio.Button>
                                    </Col>

                                    <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                                        <Radio.Button  key={"zh"} value={"zh"}>中国人</Radio.Button>
                                    </Col>
                                   
                                </>
                            ) : (
                                languages?.map((item, i)=> ( 
                                    <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                                        <Radio.Button  key={i} value={item.symbol}>{item.name}</Radio.Button>
                                    </Col>
                                ))
                            )}
                        </Row>
                    </Radio.Group>
                </Col>
            </Row>
            
        </>
    )
}

export default ChangeLang