import React from 'react'

import { Image, Row, Skeleton, Col,  Card, Typography, Tag } from "antd"
import { Link } from "react-router-dom"

import { useGetHotOffersQuery } from "../../services/mainApi"

const { Title, Text } = Typography

function HotOffers() {

    const {data: offers, isFetching} = useGetHotOffersQuery()
    return (
    <>
        <Row gutter={[32, 32]} className="post-card-container" >
            {isFetching && (
                [...Array(8).keys()].map((item, i) => {
                    return (
                    <Col xs={24} sm={12} lg={6} className="post-card" key={i} >
                        <Card hoverable > 
                            <Skeleton loading={isFetching} active paragraph={{ rows: 4 }}/>                   
                        </Card>
                    </Col>
                    )
                    })
                
            )}

          {offers?.map((offer, i) => (
            <Col xs={24} sm={12} lg={6} 
                  className="post-card" 
                  key={i} 
                  style={{ "display" : "flex"}}
                  >
                    <Link  key={i} to={`/offers/detail/${offer.offer_id}`} style={{ "display" : "flex", "width" : "100%" }}>
                        <Card 
                            title={
                              <Title level={4} className='offer-title'>
                                  {offer.translation.title}
                              </Title>
                            }
                            hoverable
                            style={{width: "100%"}}
                            >
                            
                            
                              
                              <Text>
                                  {offer.translation.status}
                              </Text>
                              <Title level={5} style={{color: "#2f2b5f"}} strong >
                                  {offer.category[localStorage.getItem("symbol")]}
                              </Title>

                              <div style={{ textAlign: "center"}}>
                                 <img style={{ maxWidth: "100%", height: "200px", margin: "10px 0 20px 0"}} src={offer.thumbnail}/>

                              </div>
                            
                            
                        </Card>
                    </Link>
            </Col>
          ))}
      </Row>
    </>
  )
}

export default HotOffers