import React, { useState, useEffect }  from 'react'

import { Tag, message, Button, Modal, Input, Typography, Row, Col, Select, Checkbox } from "antd"


import { FormattedMessage, useIntl } from 'react-intl'
import axios from "axios"
import domain from "../../domain"


import { useGetOptionsQuery, useGetOffersQuery } from "../../services/mainApi"


const { Option } = Select;
const { Text, Title } = Typography


function InviteModal() {
    const intl = useIntl()

    const { data: offers, isFetching } = useGetOffersQuery()
    const { data: options, isFetching:fetchB } = useGetOptionsQuery()
    
    
    const symbol = localStorage.getItem("symbol")
    const [modal, setModal] = useState(false)

    const [first, setFirst] = useState(null)
    const [last, setLast] = useState(null)
    const [title, setTitle] = useState(null)
    const [email, setEmail] = useState(null)
    const [WeChatId, setWeChatID] = useState(null)
    const [city, setCity] = useState(null)
    const [phone, setPhone] = useState(null)
    const [country, setCountry] = useState(null)

    const [selectedOffer, selectOffer] = useState(null)

    const [company, setCompany] =  useState(null)
    const [hasCompany, setHasCompany] = useState(false)
    const [selectedLang, selectLang] = useState(null)


    // if true, disable submit button
    const [inProgress, setInProgress] = useState(false)

    const Submit = async () => {

        if (first === null || last === null || email === null ||
            country === null || phone === null || selectedLang === null ||
            (hasCompany && company === null)) {

            const translatedMessage = intl.formatMessage({id: "Missing fields!"})
            message.error(translatedMessage)

        } else {
            setInProgress(true)

            const context = { 
                first_name : first, 
                last_name : last, 
                email : email.toLowerCase(), 
                title : title, 
                WeChat_ID : WeChatId,
                city : city, 
                phone : phone,
                country : country,
                company : company,
                language : selectedLang, 
                has_company : hasCompany,
                has_offer : selectedOffer !== null,
                offer_id: selectedOffer 
            }

            const token = localStorage.getItem("token")
            axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
            axios.defaults.xsrfCookieName = "csrftoken"
            axios.defaults.headers = {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`
            }

            await axios.post(`${domain}/api/invite/user/`, context)
            .then(res => {
               
                message.success(res.data.message)
        
                setFirst(null)
                setLast(null)
                setPhone(null)
                setTitle(null)
                setEmail(null)
                setCity(null)
                setCountry(null)
                setCompany(null)
                
                setModal(false)
                
            })
            .catch(err => {
                err?.response?.data?.message?.map(mes => {
                    message.error(mes)
                })
            })

            setInProgress(false)
            

        }
    }


    return (
    <>
        <Modal
            title={<FormattedMessage id='New Record' />}
            centered
            visible={modal}
            footer={
                <Button type="primary" onClick={() => { Submit() }} disabled={inProgress}>
                    <FormattedMessage id="Invite user"/>
                </Button>
            }

            onCancel={() => setModal(false)}
            >
          
            <Row gutter={[24,12]}>

                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <FormattedMessage id='Email'>
                        {(msg) => {
                            return (
                                <>
                                <Text>*{msg}</Text>
                                <Input 
                        
                                    label={msg}
                                    placeholder='username@email.com' 
                                    value={email}
                                    onChange={e => { setEmail(e.target.value) }} 
                                    />
                                </>
                            )
                        }}
                    </FormattedMessage>
                </Col>

                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <FormattedMessage id='First name'>
                            {(msg) => {
                                return (
                                    <>
                                    <Text>*{msg}</Text>
                                    <Input 
                            
                                        label={msg}
                                        placeholder={msg}
                                        value={first}
                                        onChange={e => { setFirst(e.target.value) }}
                                        />
                                    </>
                                )
                            }}
                    </FormattedMessage>
                </Col>

                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <FormattedMessage id='Last name'>
                        {(msg) => {
                            return (
                                <>
                                <Text>*{msg}</Text>
                                <Input 
                            
                                    label={msg}
                                    placeholder={msg}
                                    value={last}
                                    onChange={e => setLast(e.target.value)}
                                    />
                                </>
                            )
                        }}
                    </FormattedMessage>
                </Col>

                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <FormattedMessage id='Title'>
                        {(msg) => {
                            return (
                                <>
                                <Text>{msg} <FormattedMessage id='(Optional)'/></Text>
                                <Input 
                        
                                    label={msg}
                                    placeholder='Dr/Ing/Prof'
                                    value={title}
                                    onChange={e => setTitle(e.target.value)}
                                    />
                                </>
                            )
                        }}
                    </FormattedMessage>
                </Col>
                
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <FormattedMessage id='Phone number'>
                        {(msg) => {
                            return (
                                <>
                                <Text>*{msg}</Text>
                                <Input 

                                    label={msg}
                                    placeholder={msg}
                                    onChange={e => { setPhone(e.target.value) }} 
                                    value={phone}
                                    />
                                </>
                            )
                        }}
                    </FormattedMessage>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Text>WeChatID <FormattedMessage id='(Optional)'/></Text>
                        
                    <Input 
                    
                        label={"WeChatID"}
                        placeholder={"WeChatID"}
                        value={WeChatId}
                        onChange={e => { setWeChatID(e.target.value) }}
                        />
                </Col>

                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <FormattedMessage id="Language">
                        {(msg) => (
                            <>
                                <Text>*{msg}</Text>
                                <Select
                                        showSearch
                                        style={{ width: "100%" }}
                                        placeholder={msg}
                                        value={selectedLang}
                                        optionFilterProp="children"
                                        onChange={e => selectLang(e)}
                                        filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                    >
                                    {options?.languages?.map(item => ( 
                                        <Option key={item} value={item}>{item}</Option>
                                    ))}
                                </Select> 
                            </>
                            
                        )}
                    </FormattedMessage>
                </Col>

                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <FormattedMessage id='City'>
                        {(msg) => {
                            return (
                                <>
                                <Text>{msg} <FormattedMessage id='(Optional)'/></Text>
                                <Input 
                        
                                    label={msg}
                                    placeholder={msg} 
                                    value={city}
                                    onChange={e => { setCity(e.target.value) }}
                                    />
                                </>
                            )
                        }}
                    </FormattedMessage>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <FormattedMessage id='Country'>
                        {(msg) => {
                            return (
                                <>
                                <Text>*{msg}</Text>
                                <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder={msg}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                    onChange={(e) => setCountry(e)}
                                    value={country}
                                >
                                    {options?.countries?.map((item, i) => ( 
                                    <Option key={i} value={item}>{item}</Option>
                                    ))}
                                    
                                </Select> 
                                </>
                            )
                        }}
                    </FormattedMessage>
                </Col>
                
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <FormattedMessage id="Offer Interested">
                        {(msg) => (
                            <>
                                <Text>{msg} <FormattedMessage id="(Optional)"/></Text>
                                <Select
                                        showSearch
                                        style={{ width: "100%" }}
                                        placeholder={msg}
                                        value={selectedOffer}
                                        optionFilterProp="children"
                                        onChange={e => selectOffer(e)}
                                        
                                    >
                                    {offers?.map(item => ( 
                                        <Option key={item.offer_id} value={item.offer_id}>
                                            {item.translation.title}   <Tag style={{margin: "8px 8px 0 0"}} color="purple">{item.category[symbol]}</Tag> 
                                        </Option>
                                    ))}
                                </Select> 
                            </>
                            
                        )}
                    </FormattedMessage>
                </Col>

                
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Checkbox onChange={e => setHasCompany(e.target.checked)} checked={hasCompany} >
                        <FormattedMessage id='Has existing company'/>
                    </Checkbox>
                </Col>
                {hasCompany && (
                    <>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <FormattedMessage id='Company'>
                                {(msg) => {
                                    return (
                                        <>
                                        <Text>*{msg}</Text>
                                        <Select
                                            showSearch
                                            style={{ width: "100%" }}
                                            placeholder={msg}
                                            optionFilterProp="children"
                                            
                                            onChange={(e) => setCompany(e)}
                                            value={company}
                                        >
                                            {options?.companies?.map((item, i) => ( 
                                                <Option key={i} value={item.id}>{item.name}</Option>
                                            ))}
                                            
                                        </Select> 
                                        </>
                                    )
                                }}
                            </FormattedMessage>
                        </Col>
                    </>
                )}
            
            </Row>
        </Modal>
        
        <Button color='success' onClick={() => {  setModal(true) }}>
            <FormattedMessage id="Invite User"/>
        </Button>
    </>
  )
}

export default InviteModal