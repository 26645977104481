import React from 'react'
import { Table, Space, Avatar, Spin, Badge, Card, Button, Tag, Row, Col, Typography } from "antd" 
import { Link } from "react-router-dom"

import { useGetUserOffersQuery } from "../../services/mainApi"
import { CaretDownOutlined, DoubleRightOutlined } from '@ant-design/icons';

import { FormattedMessage } from 'react-intl'
import moment from 'moment';

const { Text, Title } = Typography


function Offers() {
    const { data: offers, isFetching } = useGetUserOffersQuery()

    const bookmarkColumns = [
        {
          title: <FormattedMessage id='offer title'/>,
          key: 'title',
          dataIndex: 'title',
         
        },
        {
            title: <FormattedMessage id='status'/>,
            key: 'status',
            dataIndex: 'status',
            sortable: true,
          
        },
        {
          title: <FormattedMessage id='category'/>,
          allowOverflow: true,
      
          
          render: row => {
            return (
              <Tag color="#7367f0">{row.category}</Tag>
            )
          }
        },
        {
          title: <FormattedMessage id='redirect'/>,
          width:'100px',
          render: row => {
            return (
              <Link style={{overflowX: 'hide'}} to={`/offers/detail/${row.offer_id}`}>
                    <Button className='btn-icon'  color='primary'>
                        <DoubleRightOutlined />
                  </Button>
              </Link>
  
            )
          }
        }
        
    ]

    const informationColumns = [
        {
            title:  <FormattedMessage id='offer title'/>,
            key: 'title',
            dataIndex: 'title',
            sortable: true,
            width: 140,
        },
       
        {
          title: <FormattedMessage id='Date Bought'/>,
          defaultSortOrder: 'ascend',
          sorter: (a, b) => moment(b.date_bought).unix() - moment(a.date_bought).unix(),
          render: row => {
             
              const date_bought = new Date(row.date_bought)
              const symbol = localStorage.getItem("symbol")
              return (
                  <>
                      {date_bought.toLocaleString(symbol)}
                  </>
              )
          }
          
        },
        {
            title: <FormattedMessage id='Date Activated'/>,
            render: row => {
                let formatted_date = "Not yet"

                if (row.state === 'a') {
                    const date_activated = new Date(row.date_activated)
                    const symbol = localStorage.getItem("symbol")
                    formatted_date = date_activated.toLocaleString(symbol)
                    
                } 
                
                return (
                    <>
                        {formatted_date}
                    </>
                )
            }
          
        },
        {
          title: <FormattedMessage id='State'/>, 
          render: row => {
              return (
                  
                  <>
                      {/* user uploaded */}
                      {row.state === 'd' && (
                          <>
                              <Tag>
                                  Waiting for user to upload
                              </Tag>
                          </>
                      )}
                      {row.state === 'u' && (
                          <> 
                              <Tag color="processing">
                                  User Uploaded
                              </Tag>
                              
                          </>
                      )}
                      {row.state === 's' && (
                          <>  
                              <Tag color="cyan">
                                  Seller Uploaded
                              </Tag>
                              
                          </>
                      )}
                      {row.state === 'ma' && (
                          <>
                              <Tag color="geekblue">
                                  Manager Accepted
                              </Tag>
                              
                          </>
                      )}

                      {row.state === 'a' && (
                          <>
                              <Tag  color="success">
                                  Active 
                              </Tag>
                          </>
                      )}
                      

                      {row.state === 'b' && (
                          <>
                             <Tag color="error">
                                  Banned
                             </Tag>
                          </>
                      )}

                      
                      {row.state === 'sr' && (
                          <> 
                              <Tag color="warning">
                                  Seller Rejected
                              </Tag>
                             
                          </>
                      )}

                      {row.state === 'mr' && (
                          <>
                              <Tag color="warning">
                                  Manager Rejected
                              </Tag>
                          </>
                      )}

                      {row.state === 'ur' && (
                          <>
                              <Tag color="warning">
                                  User Rejected
                              </Tag>
                          </>
                      )}

                  </>
              )
          }
      }, 
        {
          title: <FormattedMessage id='redirect'/>,
          render: row => {
            return (
                <Link style={{overflowX: 'hide'}}  to={`/offers/detail/${row.offer_id}`}>
                    <Button className='btn-icon'  color='primary'>
                        <DoubleRightOutlined />
                  </Button>
              </Link>
    
            )
          }
        }
    ]

    const negotiationColumns = [
        {
            title: <FormattedMessage id='offer title'/>,
            key: 'title',
            dataIndex: 'title',
            sortable: true,
        
        },
        {
            title: <FormattedMessage id='date bought'/>,
            key: 'date_bought',
            dataIndex: 'date_bought',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => moment(b.date_bought).unix() - moment(a.date_bought).unix(),
            
    
       
        },
        {
            title: <FormattedMessage id='date expiring'/>,
            key: 'date_expiring',
            dataIndex: 'date_expiring',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => moment(b.date_expiring).unix() - moment(a.date_expiring).unix(),
            
          
       
        },
        {
            title: <FormattedMessage id='auto-renew'/>,
            allowOverflow: true,
         
            render: row => {
              
              return (
           
                <Tag color={row.auto_renew ?  ("green") : ("red")} ><FormattedMessage id={row.auto_renew ? ('Yes') : ('No')}/></Tag>
              )
            }
          },
          {
            title: <FormattedMessage id='expired'/>,
            allowOverflow: true,
           
            render: row => {
              return (
                <Tag color={row.online ? ("green") : ("red") } ><FormattedMessage id={!row.online ? ('Yes') : ('No')}/></Tag>
              )
            }
          },
          {
            title: <FormattedMessage id='redirect'/>,
        
            render: row => {
              return (
                <Link style={{overflowX: 'hide'}} to={`/offers/detail/${row.offer_id}`}>
                    <Button className='btn-icon'  color='primary'>
                            <DoubleRightOutlined />
                    </Button>
                </Link>
              )
            }
          }
          
    ]

    return (
    <>
        <Row gutter={[24, 24]} className="form-row">
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <Card className="news-card">
                <Title level={5} strong>
                    <FormattedMessage id="Offers Bookmarked" />
                </Title>
                    {!isFetching ? (
                        <Table
                            size="small"
                            dataSource={offers.bookmarks}
                            columns={bookmarkColumns}  
                            pagination={{ pageSize: 5 }}   
                            rowKey="offer_id"
                        />
                        ) : (
                            <div style={{minHeight:'150px', textAlign:'center'}}>
                                
                                <Spin style={{marginTop: "50px"}} size="large" />
                            
                            </div>
                    )}
                </Card>
               
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                
                <Card className="news-card">
                <Title level={5} strong>
                    <FormattedMessage id="Offers with Information Package" />
                </Title>
                {!isFetching ? (
                        <Table
                            size="small"
                            dataSource={offers.information}
                            columns={informationColumns}
                            pagination={{ pageSize: 5 }}
                            rowKey="offer_id"

                       />
                        ) : (
                            <div style={{minHeight:'150px', textAlign:'center'}}>
                                    <Spin style={{marginTop: "50px"}} size="large" />
                            </div>
                    )}
                    </Card>
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                
                <Card className="news-card">
                <Title level={5} strong>
                    <FormattedMessage id="Offers with Negotiation Package" />
                </Title>
                {!isFetching ? (
                        <Table
                            size="small"
                            dataSource={offers.negotiation}
                            columns={negotiationColumns}
                            pagination={{ pageSize: 5 }}
                            rowKey="offer_id"

                        />
                        ) : (
                            <div style={{minHeight:'150px', textAlign:'center'}}>
                                
                                <Spin style={{marginTop: "50px"}} size="large" />
                            
                            </div>
                    )}
                </Card>
            </Col>
        </Row>
    </>
  )
}

export default Offers