import React, { useState, useEffect } from 'react'
import { Alert, Divider, Modal, Input, Dropdown, Menu, Popover, Button, Row, Col, Table, Spin, Typography, message, Card, Tag } from "antd"
import { FormattedMessage } from 'react-intl'
import { Navigate, Link } from 'react-router-dom'
import moment from 'moment';
import axios from "axios"
import domain from "../../../domain"
import HTMLReactParser from "html-react-parser"

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

import { ExperimentOutlined, FormOutlined, QuestionCircleOutlined } from '@ant-design/icons';

const { SubMenu } = Menu;
const { Title, Text } = Typography
const { TextArea } = Input;

function TranslationGiven(props) {
    const [inProgress, setInProgress] = useState(false)

    const [rejection, setRejection] = useState(null)


    const [targetOffer, setTargetOffer] = useState({})
    
    const [title, setTitle] = useState("")
    const [status, setStatus] = useState("")
    const [description, setDescription] = useState()

    const [targetModal, setTargetModal] = useState(false)
    

    const [message_item, setMessageItem] = useState({})
    const [message_content, setMessageContent] = useState("")
    const [messageModal, setMessageModal] = useState(false)
   
    const translationColumns = [
        {
            title: <FormattedMessage id='Original Title'/>,
            key: 'original_title',
            dataIndex: 'original_title'
        },
        {
            title: <FormattedMessage id="Date created"/>,
            
            defaultSortOrder: 'ascend',
            sorter: (a, b) => moment(b.date_created).unix() - moment(a.date_created).unix(),
            render: row => {
             
                return (
                    <>
                        {row.date_created}
                    </>
                )
            }
        },
        {
            title: <FormattedMessage id='Original language'/>,
            key: 'original',
            width: 200,
            dataIndex: 'original'
        },
        {
            title: <FormattedMessage id='Translation language'/>,
            key: 'target',
            width: 200,
            dataIndex: 'target'
        },
        {
            title: <FormattedMessage id='Status' />,
            width: 125,
            render: row => (
                <>  
                {/* if failed then red, else just show under inspection */}
                    <Tag id={`popBottom-${row.id}`} color={row.status == 'f' ?  ("red") : ('gold')} >
                        {row.status == 'f' ? (<FormattedMessage id='Rejected' />) : (<FormattedMessage id='Under Inspection'/>) }
                    </Tag>
                    {row.status == 'f' && (
                        <Popover placement="bottom" title={<FormattedMessage id='Reason of rejection'/>} content={row.rejection_message} trigger="click">
                            <QuestionCircleOutlined />
                        </Popover>
                    )}
                </>
            )
        },
        {
            title: <FormattedMessage id='Type' />,
            width: 125,
            render: row => (
                <>
                    {row.is_offer ? (<FormattedMessage id='Offer'/>) : (<FormattedMessage id='Message'/>)}
                </>
            )
        },
        {
            title: <FormattedMessage id='Actions'/>,
            render: row => (
                <>
                    {row.status == 'f' && (
                        <Dropdown overlay={
                        <Menu>
                         
                         <Menu.Item key={'overview'}>
                         <a onClick={e => {
                             if (row.is_offer) {
                                setTitle(row.target_title)
                                setStatus(row.target_status)
                                setDescription(row.target_description)
                              
                                setTargetOffer(row)
                                setTargetModal(true)

                             } else {
                                setMessageModal(true)
                                // setting the message content seperately
                                setMessageContent(row?.target_title)
                                setMessageItem(row)
                             }
                         } }>
                             <ExperimentOutlined /> <FormattedMessage id="Overview"/>
                         </a>
                         </Menu.Item>
                     </Menu>
                    } placement="bottomRight" arrow trigger={['click']}>
                        <Button className='btn-icon' color='primary'>
                            <FormOutlined />
                        </Button>
                    </Dropdown>
                    )}
                </>
            )
        }
    ]

    const closeTargetModal = () => {
        setTitle("")
        setStatus("")
        setDescription("")
        setTargetOffer({})
        setTargetModal(false)
    }

    // message translation
    const sendMessageTranslation = async () => {
        setInProgress(true)
        
        const token = localStorage.getItem("token")
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.defaults.xsrfCookieName = "csrftoken"
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`
        }

        const context = {
            ...message_item, 
            content : message_content, 
        }

        await axios.post(`${domain}/translator/dashboard/message-translation/`, context)
        .then(res => {
          
            message.success(res.data.message)
            props?.refetch()
            setMessageModal(false)
            setMessageItem({})

            
        })
        .catch(err => {
            message.error(err)
        })

        setInProgress(false)
    }

    // offer translation
    const editTranslation = async () => {
        setInProgress(true)
        

        const context = {
            title: title, 
            description : description, 
            status : status, 
            translation_id: targetOffer.translation_id
        }

        const token = localStorage.getItem("token")
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.defaults.xsrfCookieName = "csrftoken"
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`
        }

        await axios.post(`${domain}/translator/dashboard/edit-translation/`, context)
        .then(res => {
            console.log(res.data.message)
            message.success(res.data.message)
            
            props?.refetch()
            closeTargetModal()
        })
        .catch(err => {
            err?.response?.data?.message?.map(mes => {
                message.error(mes)
            })
            closeTargetModal()
        })


        setInProgress(false)
    }



    return (
    <>
        {/* message translation modal */}
        <Modal
          visible={messageModal}
          title={`${message_item?.original} -> ${message_item?.target}`}
          centered
          footer={
            <Button type="primary" 
                    disabled={inProgress}
                    onClick={() => { sendMessageTranslation() }}>
                <FormattedMessage id="Submit"/>
            </Button>
         }
          onCancel={() => { setMessageModal(false) }}
         
        >

            

            <Row gutter={[24,24]}>
      
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                {message_item?.rejection_message && (<Alert message={message_item?.rejection_message} type="error" className='mb-1'/>)}
                        <Title level={5}><FormattedMessage id="Original Message"/></Title>
                        <Text>{message_item.original_title}</Text>
            
                </Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24} className='mt-1'>
        
                        <FormattedMessage id="Edit Message">
                            {(msg) => {
                                return (
                                    <>
                                    <Title level={5}>{msg}</Title>
                                    <TextArea rows={4} placeholder={msg} value={message_content} onChange={e => { 
                                        setMessageContent(e.target.value) 
                                    }}/>
                                    </>
                                )
                            }}
                        </FormattedMessage>
          
                </Col>
            </Row>
        </Modal>

         {/* offer translation modal */}
         <Modal
          className='translation-modal'
          width={'100%'}
          visible={targetModal}
          title={`${targetOffer?.original} -> ${targetOffer?.target}`}
          centered
          footer={
              <>
                  <Button type="primary" 
                        disabled={inProgress}
                        
                        onClick={() => { editTranslation() }}>
                    <FormattedMessage id="Submit"/>
                </Button>
              </>
          }
          onCancel={() => { 
              closeTargetModal()
           }}
         
        >
            <Row gutter={[24,24]}>
            
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        {targetOffer?.rejection_message && (<Alert message={targetOffer?.rejection_message} type="error" className='mb-1'/>)}
                        <Title level={5}><FormattedMessage id="Offer title"/></Title>
                        <Text>{targetOffer?.original_title}</Text>
              
                </Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
      
                        <Title level={5}><FormattedMessage id="Status"/></Title>
                        <Text>{targetOffer?.original_status}</Text>
            
                </Col>

                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
      
                        <Title level={5}><FormattedMessage id="Description"/></Title>
                        {targetOffer?.original_description && (HTMLReactParser(targetOffer?.original_description))}
            
                </Col>
                <Divider />
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <FormattedMessage id="Offer title">
                        {msg => {
                            return (
                            <>
                            <Title level={5}>{msg}</Title>
                            <Input 
                                    name='title'
                                    value={title}
                                    placeholder={msg}
                                    onChange={(e) => {  
                                        setTitle(e.target.value)
                                    }}
                                    />
                            </>
                            )
                        }}
                    </FormattedMessage>
                </Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
      
                    <FormattedMessage id="Status">
                            {msg => {
                                return (
                                <>
                                <Title level={5}>{msg}</Title>
                                <Input 
                                        name='status'
                                        value={status}
                                        placeholder={msg}
                                        onChange={(e) => { setStatus(e.target.value) }}
                                        />
                                </>
                                )
                            }}
                        </FormattedMessage>
            
                </Col>

                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <FormattedMessage id="Description">
                            {msg => {
                                return (
                                <>
                                <Title level={5}>{msg}</Title>
                                <CKEditor
                                    editor={ Editor }
                                    data={description}
                                    name={msg}
                                    onChange={ ( e, editor ) => setDescription(editor.getData()) }
                                />
                                </>
                                )
                            }}
                    </FormattedMessage>
                </Col>


            </Row>
        </Modal>

        <Card className="news-card">
            <Title level={5} strong>
                <FormattedMessage id="Translations waiting for approval"/>
            </Title>
                {!props?.isFetching ? (
                    <Table
                        size="small"
                        dataSource={props?.translations}
                        columns={translationColumns}  
                        pagination={{ pageSize: 5 }}   
                    />
                    ) : (
                        <div style={{minHeight:'300px', textAlign:'center'}}>
                            
                            <Spin style={{marginTop: "75px"}} size="large" />
                        
                        </div>
                )}
        </Card>
    </>
    )
}

export default TranslationGiven