import React from 'react'

import { Row, Col, Avatar, Space, Typography, Table } from "antd"

import { FormattedMessage } from 'react-intl'


const { Title } = Typography 

function ExternalsTable(props) {

    const columns = [
        {
          title: <FormattedMessage id='Name' />,
          selector: 'name',
          render: row => (
            <>
              <div className='d-flex align-items-center'>
                <Space>
                  <Avatar size={32} src={row.picture} />
    
                  <div className='user-info text-truncate ml-1'>
                      <span>{row.name}</span>
                      <small>{row.title}</small>
                  </div>
                </Space>
                </div>
            </>
            
          )
        },
        {
          title: <FormattedMessage id='Email' />,
          dataIndex: 'email',
        
        },
        {
          title: <FormattedMessage id='Phone' />,
          dataIndex: 'phone',
      
        },
        {
          title: 'WeChat ID',
          dataIndex: 'WeChat_ID',
        }
    ]

    return (
        <>
            <Row className='mt-3'>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Title level={4} >
                        <FormattedMessage id="External Persons to this offer"/>
                    </Title>
                    
                    <Table
                        size="small"
                        pagination={{ pageSize: 10 }}
                        columns={columns}
                        dataSource={props?.externals} 
                    />
                    
                </Col>
            </Row>
        </>
    )
}

export default ExternalsTable