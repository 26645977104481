import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, Menu, Popover, Button, Table, Spin, Typography, message, Card, Tag } from "antd"


import { CloseOutlined, EyeOutlined, EyeInvisibleOutlined, EditOutlined, PrinterOutlined, DeleteOutlined, FormOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl'


import axios from "axios"
import domain from "../../../domain"


const { Title } = Typography


function OffersTable(props) {

    const archiveOffer = async (offer_id, archive) => {
        const token = localStorage.getItem("token")
      
        const context = {
          archive : archive
        }
       
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.defaults.xsrfCookieName = "csrftoken"
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`
        }
        await axios.post(`${domain}/seller/dashboard/offer/visibilty/${offer_id}/`, context)
        .then(res => {
          if (res.data.success) {
              message.success(res.data.message)
          } else {
              message.error(res.data.message)
          }
        })
        props.refetch()
      }

    const deleteOffer = async (offer_id) => {
        const token = localStorage.getItem("token")
    
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.defaults.xsrfCookieName = "csrftoken"
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`
        }
        await axios.post(`${domain}/seller/dashboard/offer/delete/${offer_id}/`)
        .then(res => {
          if (res.data.success) {
              message.success(res.data.message)
          } else {
              message.error(res.data.message)
          }
        })
        
        props.refetch()
      }

    const statisticsColumns = [
        {
            title: <FormattedMessage id='Title'/>,
            key: 'title',
            dataIndex: 'title',
           
          },
          {
              title: <FormattedMessage id='Views'/>,
              key: 'views',
              dataIndex: 'views',
              defaultSortOrder: 'descend',
              sorter: (a, b) => a.views - b.views,
            
          },
          {
            title: <FormattedMessage id='Clicks'/>,
            key: 'clicks',
            dataIndex: 'clicks',
            sorter: (a, b) => a.clicks - b.clicks,
     
          },
          {
            title: <FormattedMessage id='Bookmarks'/>,
            key: 'bookmarks',
            dataIndex: 'bookmarks',
            sorter: (a, b) => a.bookmarks - b.bookmarks,
       
          },
          {
            title: <FormattedMessage id='Timelines'/>,
            key: 'packages',
            dataIndex: 'packages',
            sorter: (a, b) => a.packages - b.packages,
          },
          {
            title: <FormattedMessage id='Visibility'/>,
            sorter: (a, b) => a.is_online - b.is_online,
            render: row => {
                return (
                    <Tag  color={row.is_online ? 'green' : 'red'}>
                        {row.is_online ? (
                        <FormattedMessage id='Online'/>
                        ) : (
                        <FormattedMessage id='Archived'/>
                        )}
                    </Tag>
                )}
            },
            {
            title: <FormattedMessage id='Status'/>,
            
            render: row => {
        
                return (
                    <Fragment>
                    {/* drafted -> under inspection */}
                    {row.status === 'd' && (
                        <Tag color='gold'>
                            <FormattedMessage id='Under Inspection'/>
                        </Tag>
                    )}
                    
                    {/* failed -> rejected */}
                    {row.status === 'f' && (
                        <>
                      
                            <a>
                                <Tag id={`popBottom-${row.id}`} color='red'>
                                {/* if not_fail_first is false, then it has been rejected */}
                                {/* otherwise it is under inspection or accepted */}
                                    <CloseOutlined /> <FormattedMessage id='Rejected'/>
                                </Tag>
                                <Popover placement="bottom" title={<FormattedMessage id='Reason of rejection'/>} content={row.rejection_message} trigger="click">
                                    <QuestionCircleOutlined />
                                </Popover>
                            </a>
                        </>
                    )}  
                    {/* verified -> accepted */}
                    {row.status === 'v' && (
                        <Tag color='success'>
                            <FormattedMessage id='Accepted'/>
                        </Tag>
                    )}
                    </Fragment>
                    
                )}
            },
            {
            title: <FormattedMessage id='Actions'/>,
            width:'100px',
            render: row => {
                
                return (
                  <>
            
                    <Dropdown overlay={
                        <Menu>
                            <Menu.Item key={'Edit'}>
                            <Link to={`/offers/edit/${row.offer_id}`}>
                                <EditOutlined/> <FormattedMessage id='Edit'/>
                            </Link>
                            </Menu.Item>
                            <Menu.Item key={'Archive'}>
                            <a onClick={e => archiveOffer(row.offer_id, row.is_online) }>
                                
                                {row.is_online ? (
                                    <>
                                        <EyeInvisibleOutlined />  <FormattedMessage id='Archive'/>
                                    </>
                                ) : (
                                    <>
                                        
                                        <EyeOutlined /> <FormattedMessage id='Public'/>
                                    </>
                                )}
                            </a>
                            </Menu.Item>
                            <Menu.Item key={'Delete'}>
                            <a onClick={e => deleteOffer(row.offer_id) }>
                                <DeleteOutlined/> <FormattedMessage id='Delete'/>
                            </a>
                            </Menu.Item>
                        </Menu>
                    } placement="bottomRight" arrow trigger={['click']}>
                        <Button className='btn-icon' color='primary'>
                            <FormOutlined />
                        </Button>
                    </Dropdown>

                  </>
             
    
              )
            }
          }
    ]

    return (
        <>
                <Card className="news-card">
                <Title level={5} strong>
                    <FormattedMessage id="Offers" />
                </Title>
                    {!props.isFetching ? (
                        <Table
                            size="small"
                            dataSource={props?.statistics}
                            columns={statisticsColumns}  
                            pagination={{ pageSize: 5 }}
                            rowKey="offer_id"   
                        />
                        ) : (
                            <div style={{minHeight:'300px', textAlign:'center'}}>
                                
                                <Spin style={{marginTop: "75px"}} size="large" />
                            
                            </div>
                    )}
                </Card>
        </>
  )
}

export default OffersTable