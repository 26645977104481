import React, { useState, useEffect } from 'react'
import { Tag, Skeleton, Switch, Select, Typography, Card, Avatar, Col, Row, Input } from 'antd';
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom"
import { Helmet } from "react-helmet";
import HTMLReactParser from "html-react-parser"
import moment from "moment"
import { FormattedMessage } from 'react-intl'
import { useGetOptionsQuery, useGetOffersQuery } from "../../services/mainApi"


const { Text, Title } = Typography
const { Option } = Select;


function Offers(props) {
  
  // this will changed only when fetching, will not be affected by filters
  const { data: options, isFetching:fetchB } = useGetOptionsQuery()
  const { data: initialOffers, isFetching } = useGetOffersQuery(props.simplified)

  // these will be filtered
  const [offers, setOffers] = useState(initialOffers) 
  const [searchTerm, setSearchTerm ] = useState("")

  const [selectedCat, selectCategory] = useState(null)
  const [selectedTags, selectTag] = useState([])
  
  const filteredOptions = options?.tags.filter(o => !selectedTags.includes(o));
  
  useEffect(() => {
      if (!props.simplified) {
        props.setBc2(<FormattedMessage id="Offers"/>)
        props.setBc3(<FormattedMessage id="List" />)
      } 
      const filteredSearch = initialOffers?.filter((item) => item.translation.title.toLowerCase().includes(searchTerm.toLowerCase()))  
      
      const filterCat = selectedCat ? filteredSearch?.filter((item) => item.category[localStorage.getItem("symbol")].toLowerCase().includes(selectedCat.toLowerCase())) : filteredSearch
      
      const filterTags = selectedTags.length > 0 ? filterCat?.filter((item) => selectedTags.every(i => item.tags.includes(i))) : filterCat
      setOffers(filterTags)

  }, [searchTerm, initialOffers, selectedCat, selectedTags])

  

  return (
    <>
      {!props.simplified && (
        <>
        <Row  gutter={[24, 24]} className="search-post">
              <Col lg={8} md={12} sm={24} xs={24}>
                <FormattedMessage id="Search into Offers" >
                  {msg => (
                      <Input placeholder={msg} onChange={(e) => setSearchTerm(e.target.value)}></Input>       
                  )}
                </FormattedMessage>
              </Col>
              <Col lg={8} md={6} sm={12} xs={24}>
              
                  <FormattedMessage id='Category'>
                      {(msg) => {
                        return (
                          <Select
                              allowClear
                              showSearch
                              style={{ width: "100%" }}
                              placeholder={msg}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                              }
                              onChange={(e) => selectCategory(e)}
                            >
                              {options?.categories.map((item, i) => ( 
                                <Option key={i} value={item}>{item}</Option>
                              ))}
                          
                        </Select>  
                        )
                      }}
                  </FormattedMessage>
                  
              </Col>
              <Col lg={8} md={6} sm={12} xs={24}>
                  <FormattedMessage id='Tags'>
                      {(msg) => {
                        return (
                            <Select
                                mode="multiple"
                                placeholder={msg}
                                value={selectedTags}
                                onChange={(e) => { selectTag(e) }}
                                style={{ width: '100%' }}
                                    >
                                {filteredOptions?.map(item => (
                                  <Select.Option key={item} value={item}>
                                    {item}
                                  </Select.Option>
                              ))}
                            </Select>
                        )
                      }}
                  </FormattedMessage>
                  
              </Col>
        </Row>
       
        <Helmet>
            <meta charSet="utf-8" />
            <title>IP2B Posts</title>
        </Helmet>
        </>
      ) }
      
        <Row gutter={[24, 24]}  >
        {isFetching && (
            [...Array(6).keys()].map((item, i) => {
              return (
                <Col xs={24} sm={12} lg={8} key={i} >
                    <Card hoverable > 
                        <Skeleton loading={isFetching} active paragraph={{ rows: 6 }}/>                   
                      </Card>
                </Col>
              )
              })
            
        )}
        
        {offers?.map((item, i) => (
              <Col xs={24} sm={12} lg={8} key={i} style={{ "display" : "flex" }} >
                  <Card hoverable  style={{ "display" : "flex" }}> 
                      <Skeleton loading={isFetching} active paragraph={{ rows: 6 }}>
                      <Link key={item.post_id} to={`/offers/detail/${item.offer_id}`}>
                          
                          <Row gutter={[12,12]}>
                              <Col lg={14} sm={24}>
                                <Title className='offer-title' level={4}>{item?.translation?.title}</Title>
                                <Text>{item?.translation?.status}</Text>
                              </Col>
                              <Col lg={10} sm={24}>
                                <img style={{ width: "100%" }} src={item?.thumbnail} als="news" /> 
                              </Col>
                          </Row>
                      
                          <div className='category-container'>
                              <div>
 
                                  <Text style={{color: "#2f2b5f"}} strong>
                                      {item?.category[localStorage.getItem("symbol")]}
                                  </Text>
                              </div>
                    
                          </div>

                          <div className='post-tags'>
                              <Text>
                                {item?.tags.map((tag, i) => <Tag key={i} style={{margin: "8px 8px 0 0"}} color="geekblue">{tag}</Tag>  )}
                              </Text>
                          </div>
                      </Link>

                      </Skeleton>                    
                    </Card>
              </Col>
            ))}
        </Row>
        
    </>
  )
}

export default Offers