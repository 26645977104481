import React, { useState, useEffect } from 'react'
import { Alert, Divider, Modal, Input, Dropdown, Menu, Popover, Button, Row, Col, Table, Spin, Typography, message, Card, Tag } from "antd"
import { FormattedMessage } from 'react-intl'
import { Navigate, Link } from 'react-router-dom'
import moment from 'moment';
import axios from "axios"
import domain from "../../../domain"
import HTMLReactParser from "html-react-parser"

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

import { FullscreenOutlined, ExperimentOutlined, FormOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import {useGetTranslatorDashboardQuery} from "../../../services/mainApi"

const { SubMenu } = Menu;
const { Title, Text } = Typography
const { TextArea } = Input;


function TranslationOffer(props) {
    const [inProgress, setInProgress] = useState(false)
    
 
    const [title, setTitle] = useState("")
    const [status, setStatus] = useState("")
    const [description, setDescription] = useState("")

    const [targetOffer, setTargetOffer] = useState({})
    const [targetModal, setTargetModal] = useState(false)

    const offerColumns = [
        {
            title: <FormattedMessage id='Offer title'/>,
            key: 'title',
            dataIndex: 'title'
        },
        {
            title: <FormattedMessage id='Language given'/>,
            key: 'original',
            dataIndex: 'original'
        },
        {
            title: <FormattedMessage id='Translation required'/>,
            key: 'target',
            dataIndex: 'target'
        },
        {
            title: <FormattedMessage id='Actions'/>,
            render: row => (
                <>
                    <Dropdown overlay={
                        <Menu>
                         
                         <Menu.Item key={'overview'}>
                         <a onClick={e => {
                             
                             setTargetOffer(row)
                             setTargetModal(true)
                         } }>
                             <FullscreenOutlined /> <FormattedMessage id="Overview"/>
                         </a>
                         </Menu.Item>
                     </Menu>
                    } placement="bottomRight" arrow trigger={['click']}>
                        <Button className='btn-icon' color='primary'>
                            <FormOutlined />
                        </Button>
                    </Dropdown>
                </>
            )
        }
    ]



    const closeTargetModal = () => {
        setTitle("")
        setStatus("")
        setDescription("")
        
        setTargetOffer({})
        setTargetModal(false)
    }

    const sendTranslation = async () => {
        setInProgress(true)
      
        const context = {
            title, 
            description,
            status, 
            target: targetOffer?.target,
            original: targetOffer?.original,
            offer_id : targetOffer?.offer_id,
        }

    
        const token = localStorage.getItem("token")
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.defaults.xsrfCookieName = "csrftoken"
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`
        }

        await axios.post(`${domain}/translator/dashboard/create-translation/`, context)
        .then(res => {
        
            message.success(res.data.message)
            props?.refetch() 

            closeTargetModal()
        })
        .catch(err => {
            err?.response?.data?.message?.map(mes => {
                message.error(mes)
            })
        })
     

        setInProgress(false)
    }


    return (
    <>
    {/* offer translation modal */}
        <Modal
          className='translation-modal'
          width={'100%'}
          visible={targetModal}
          title={`${targetOffer?.original} -> ${targetOffer?.target}`}
          centered
          footer={
              <>
                  <Button type="primary" 
                        disabled={inProgress}
                        
                        onClick={() => { sendTranslation() }}>
                    <FormattedMessage id="Submit"/>
                </Button>
              </>
          }
          onCancel={() => { 
              closeTargetModal()
           }}
         
        >
            <Row gutter={[24,24]}>
            
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        {targetOffer?.rejection_message && (<Alert message={targetOffer?.rejection_message} type="error" className='mb-1'/>)}
                        <Title level={5}><FormattedMessage id="Offer title"/></Title>
                        <Text>{targetOffer?.title}</Text>
              
                </Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
      
                        <Title level={5}><FormattedMessage id="Status"/></Title>
                        <Text>{targetOffer?.status}</Text>
            
                </Col>

                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
      
                        <Title level={5}><FormattedMessage id="Description"/></Title>
                        <div className='ck-content'>
                        {targetOffer?.description && (HTMLReactParser(targetOffer?.description))}
                        </div>
                        
            
                </Col>
                <Divider />
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <FormattedMessage id="Offer title">
                        {msg => {
                            return (
                            <>
                            <Title level={5}>{msg}</Title>
                            <Input 
                                    name='title'
                                    value={title}
                                    placeholder={msg}
                                    onChange={(e) => {  
                                        setTitle(e.target.value) 
                                    }}
                                    />
                            </>
                            )
                        }}
                    </FormattedMessage>
                </Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
      
                    <FormattedMessage id="Status">
                            {msg => {
                                return (
                                <>
                                <Title level={5}>{msg}</Title>
                                <Input 
                                        name='status'
                                        value={status}
                                        placeholder={msg}
                                        onChange={(e) => {  setStatus(e.target.value) }}
                                        />
                                </>
                                )
                            }}
                        </FormattedMessage>
            
                </Col>

                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <FormattedMessage id="Description">
                            {msg => {
                                return (
                                <>
                                <Title level={5}>{msg}</Title>
                                <CKEditor
                                    editor={ Editor }
                                    data={description}
                                    name={msg}
                                    onChange={ ( e, editor ) => setDescription(editor.getData()) }
                                />
                                </>
                                )
                            }}
                    </FormattedMessage>
                </Col>


            </Row>
        </Modal>

        <Card className="news-card">
            <Title level={5} strong>
                <FormattedMessage id="Offers to be translated"/>
            </Title>
            {!props?.isFetching ? (
                <Table
                    size="small"
                    dataSource={props?.offers}
                    columns={offerColumns}  
                    pagination={{ pageSize: 5 }}   
                    rowKey="offer_id"
                />
                ) : (
                    <div style={{minHeight:'300px', textAlign:'center'}}>
                        
                        <Spin style={{marginTop: "75px"}} size="large" />
                    
                    </div>
            )}
        </Card>
    </>
    )
}

export default TranslationOffer