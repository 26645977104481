import React, { useEffect } from 'react'
import { Breadcrumb, Divider, Typography, Row, Col, Statistic } from 'antd';
import { Link  } from "react-router-dom"
import { Offers } from "../components"
import { Helmet } from "react-helmet";
import { FormattedMessage } from 'react-intl'


import HotOffers from './offers/HotOffers';

const { Title } = Typography

function Homepage(props) {

  useEffect(() => {
    props.setBc2(<FormattedMessage id="Homepage" />)
    props.setBc3(<FormattedMessage id="List" />)
  }, [])
  return (
    <>   
        

        <Divider orientation="center" style={{margin: "0"}}  >
            <div className='home-heading-container' >
                <Title level={2} className="home-title">
                    <FormattedMessage id='Hot Offers'/>                    
                </Title>
            </div>
        
        </Divider>

        <HotOffers />
        
        <Divider orientation="center" style={{margin: "40px 0 0 0"}}>
            <div className='home-heading-container' >
            <Title level={2} className="home-title">
                <FormattedMessage id='More Offers'/>

                <span className='show-more'>
                    <Link to="/offers">
                        <FormattedMessage id='Read More'/>
                    </Link>
                </span>
                
            </Title>
            </div>
        
        </Divider>
            
        
        <Offers simplified/>
         
    </>
  )
}

export default Homepage