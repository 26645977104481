import React from 'react'
import HTMLReactParser from "html-react-parser"
import { Typography, Breadcrumb, Row, Col, Layout } from "antd"
import { Link } from "react-router-dom"

const { Title, Text } = Typography 
const { Content } = Layout

const html = `<div class=WordSection1>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:1'><b><span lang=DE-AT style='font-size:
24.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-font-kerning:18.0pt'>Impressum<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Informationspflicht laut §5
E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63 Gewerbeordnung und
Offenlegungspflicht laut §25 Mediengesetz.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Kairos Technologies e.U<br>
Matthias Schuller<br>
Zirkusgasse 5, Stiege 6 Tür 30, <br>
1020 Wien, <br>
Österreich<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span lang=DE-AT style='font-size:12.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Unternehmensgegenstand:</span></b><span
lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'> Technologieagentur<br>
<b>GLN:</b> 9110025590767<br>
<b>GISA:</b> 30645264, 30645370, 30645295, 30645332, <br>
<b>Firmenbuchnummer:</b> 481685k<br>
<b>Firmenbuchgericht:</b> Handelsgericht Wien<br>
<b>Firmensitz:</b> 1020 Wien<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span lang=DE-AT style='font-size:12.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Tel.:</span></b><span
lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'> 0660 586 555 1<br>
<b>E-Mail:</b> </span><span lang=DE-AT><a href="mailto:office@kairos.fund"><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman"'>office@kairos.fund</span></a></span><span lang=DE-AT
style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman"'> <o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span lang=DE-AT style='font-size:12.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Mitglied
bei:</span></b><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'> WKO<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span lang=DE-AT style='font-size:12.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Berufsbezeichnung:</span></b><span
lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'> Handelsgewerbe mit Ausnahme der
reglementierten Handelsgewerbe und Handelsagent, Namhaftmachung von Personen,
die an einem Vertragsabschluss über Immobilien interessiert sind, an einen
befugten Immobilientreuhänder (Immobilienmakler, Bauträger) ohne ständig vom
selben Auftraggeber betraut zu sein unter Ausschluss jeder einem
Immobilientreuhänder vorbehaltenen Tätigkeit, Werbeagentur, Beratung über und
Vermittlung von Studienplätzen an Schulen, Universitäten und sonstigen
Bildungseinrichtungen<br>
<b>Verleihungsstaat:</b> Österreich<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span lang=DE-AT style='font-size:12.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Kontaktdaten
des Verantwortlichen für Datenschutz</span></b><span lang=DE-AT
style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman"'> <br>
Sollten Sie Fragen zum Datenschutz haben, finden Sie nachfolgend die
Kontaktdaten der verantwortlichen Person bzw. Stelle:<br>
Matthias Schuller<br>
Zirkusgasse 5/6/30<br>
1020 Wien<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Vertretungsberechtigt: Matthias Schuller<br>
E-Mail-Adresse: </span><span lang=DE-AT><a href="mailto:office@kairos.fund"><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman"'>office@kairos.fund</span></a></span><span lang=DE-AT
style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman"'> <br>
Telefon: 0660 586 555 1<br>
Impressum: </span><span lang=DE-AT><a href="https://ip2b.com/impressum"
target="_self"><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>https://ip2b.com/impressum/impressum</span></a></span><span
lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'> <o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span lang=DE-AT style='font-size:
18.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>EU-Streitschlichtung<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Gemäß Verordnung über
Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten
wir Sie über die Online-Streitbeilegungsplattform (OS-Plattform) informieren.<br>
Verbraucher haben die Möglichkeit, Beschwerden an die Online
Streitbeilegungsplattform der Europäischen Kommission unter </span><span
lang=DE-AT><a
href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=DE"
target="_blank"><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>http://ec.europa.eu/odr?tid=121870333</span></a></span><span
lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'> zu richten. Die dafür notwendigen
Kontaktdaten finden Sie oberhalb in unserem Impressum.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Wir möchten Sie jedoch darauf
hinweisen, dass wir nicht bereit oder verpflichtet sind, an Streitbeilegungsverfahren
vor einer Verbraucherschlichtungsstelle teilzunehmen.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span lang=DE-AT style='font-size:
18.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Haftung
für Inhalte dieser Website<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Wir entwickeln die Inhalte dieser
Webseite ständig weiter und bemühen uns korrekte und aktuelle Informationen
bereitzustellen. &nbsp;Leider können wir keine Haftung für die Korrektheit
aller Inhalte auf dieser Website übernehmen, speziell für jene, die seitens
Dritter bereitgestellt wurden. Als Diensteanbieter sind wir nicht verpflichtet,
die von ihnen übermittelten oder gespeicherten Informationen zu überwachen oder
nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Unsere Verpflichtungen zur
Entfernung von Informationen oder zur Sperrung der Nutzung von Informationen
nach den allgemeinen Gesetzen aufgrund von gerichtlichen oder behördlichen
Anordnungen bleiben auch im Falle unserer Nichtverantwortlichkeit davon
unberührt.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Sollten Ihnen problematische oder
rechtswidrige Inhalte auffallen, bitte wir Sie uns umgehend zu kontaktieren,
damit wir die rechtswidrigen Inhalte entfernen können. Sie finden die
Kontaktdaten im Impressum.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span lang=DE-AT style='font-size:
18.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Haftung
für Links auf dieser Webseite<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Unsere Webseite enthält Links zu
anderen Webseiten für deren Inhalt wir nicht verantwortlich sind. Haftung für
verlinkte Websites besteht für uns nicht, da wir keine Kenntnis rechtswidriger
Tätigkeiten hatten und haben, uns solche Rechtswidrigkeiten auch bisher nicht
aufgefallen sind und wir Links sofort entfernen würden, wenn uns
Rechtswidrigkeiten bekannt werden.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Wenn Ihnen rechtswidrige Links auf
unserer Website auffallen, bitte wir Sie uns zu kontaktieren. Sie finden die
Kontaktdaten im Impressum.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span lang=DE-AT style='font-size:
18.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Urheberrechtshinweis<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Alle Inhalte dieser Webseite
(Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht. Bitte fragen Sie
uns bevor Sie die Inhalte dieser Website verbreiten, vervielfältigen oder
verwerten wie zum Beispiel auf anderen Websites erneut veröffentlichen. Falls
notwendig, werden wir die unerlaubte Nutzung von Teilen der Inhalte unserer
Seite rechtlich verfolgen.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Sollten Sie auf dieser Webseite
Inhalte finden, die das Urheberrecht verletzen, bitten wir Sie uns zu
kontaktieren.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span lang=DE-AT style='font-size:
18.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Bildernachweis<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Die Bilder, Fotos und Grafiken auf
dieser Webseite sind urheberrechtlich geschützt.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Die Bilderrechte liegen bei den
folgenden Fotografen und Unternehmen:<o:p></o:p></span></p>

<ul type=disc>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l3 level1 lfo1;tab-stops:list .5in'><span
     style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
     "Times New Roman";mso-ansi-language:EN-US'>Fotograf Mustermann<o:p></o:p></span></li>
</ul>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Alle Texte sind urheberrechtlich
geschützt.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:11.25pt;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Quelle: Erstellt mit dem </span><span
lang=DE-AT><a href="https://www.adsimple.at/impressum-generator/"
title="Impressum Generator von AdSimple für Österreich"><span style='font-size:
12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Impressum
Generator</span></a></span><span lang=DE-AT style='font-size:12.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'> von
AdSimple<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:1'><b><span lang=DE-AT style='font-size:
24.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-font-kerning:18.0pt'>Datenschutzerklärung<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span lang=DE-AT style='font-size:
18.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Einleitung
und Überblick<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Wir haben diese Datenschutzerklärung
(Fassung 04.11.2021-111870333) verfasst, um Ihnen gemäß der Vorgaben der </span><span
lang=DE-AT><a
href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=111870333"
target="_blank"><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Datenschutz-Grundverordnung (EU)
2016/679</span></a></span><span lang=DE-AT style='font-size:12.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'> und
anwendbaren nationalen Gesetzen zu erklären, welche personenbezogenen Daten
(kurz Daten) wir als Verantwortliche – und die von uns beauftragten
Auftragsverarbeiter (z. B. Provider) – verarbeiten, zukünftig verarbeiten werden
und welche rechtmäßigen Möglichkeiten Sie haben. Die verwendeten Begriffe sind
geschlechtsneutral zu verstehen.<br>
<b>Kurz gesagt:</b> Wir informieren Sie umfassend über Daten, die wir über Sie
verarbeiten.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Datenschutzerklärungen klingen für
gewöhnlich sehr technisch und verwenden juristische Fachbegriffe. Diese
Datenschutzerklärung soll Ihnen hingegen die wichtigsten Dinge so einfach und
transparent wie möglich beschreiben. Soweit es der Transparenz förderlich ist,
werden technische <b>Begriffe leserfreundlich erklärt</b>, <b>Links</b> zu
weiterführenden Informationen geboten und <b>Grafiken</b> zum Einsatz gebracht.
Wir informieren damit in klarer und einfacher Sprache, dass wir im Rahmen
unserer Geschäftstätigkeiten nur dann personenbezogene Daten verarbeiten, wenn
eine entsprechende gesetzliche Grundlage gegeben ist. Das ist sicher nicht
möglich, wenn man möglichst knappe, unklare und juristisch-technische
Erklärungen abgibt, so wie sie im Internet oft Standard sind, wenn es um
Datenschutz geht. Ich hoffe, Sie finden die folgenden Erläuterungen interessant
und informativ und vielleicht ist die eine oder andere Information dabei, die
Sie noch nicht kannten.<br>
Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an die unten bzw. im
Impressum genannte verantwortliche Stelle zu wenden, den vorhandenen Links zu
folgen und sich weitere Informationen auf Drittseiten anzusehen. Unsere
Kontaktdaten finden Sie selbstverständlich auch im Impressum.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span lang=DE-AT style='font-size:
18.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Anwendungsbereich<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Diese Datenschutzerklärung gilt für
alle von uns im Unternehmen verarbeiteten personenbezogenen Daten und für alle
personenbezogenen Daten, die von uns beauftragte Firmen (Auftragsverarbeiter)
verarbeiten. Mit personenbezogenen Daten meinen wir Informationen im Sinne des
Art. 4 Nr. 1 DSGVO wie zum Beispiel Name, E-Mail-Adresse und postalische
Anschrift einer Person. Die Verarbeitung personenbezogener Daten sorgt dafür,
dass wir unsere Dienstleistungen und Produkte anbieten und abrechnen können,
sei es online oder offline. </span><span style='font-size:12.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";mso-ansi-language:
EN-US'>Der Anwendungsbereich dieser Datenschutzerklärung umfasst:<o:p></o:p></span></p>

<ul type=disc>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l7 level1 lfo2;tab-stops:list .5in'><span
     lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
     mso-fareast-font-family:"Times New Roman"'>alle Onlineauftritte (Websites,
     Onlineshops), die wir betreiben<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l7 level1 lfo2;tab-stops:list .5in'><span
     lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
     mso-fareast-font-family:"Times New Roman"'>Social Media Auftritte und
     E-Mail-Kommunikation<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l7 level1 lfo2;tab-stops:list .5in'><span
     lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
     mso-fareast-font-family:"Times New Roman"'>mobile Apps für Smartphones und
     andere Geräte<o:p></o:p></span></li>
</ul>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span lang=DE-AT style='font-size:12.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Kurz gesagt:</span></b><span
lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'> Die Datenschutzerklärung gilt für
alle Bereiche, in denen personenbezogene Daten im Unternehmen über die
genannten Kanäle strukturiert verarbeitet werden. Sollten wir außerhalb dieser
Kanäle mit Ihnen in Rechtsbeziehungen eintreten, werden wir Sie gegebenenfalls
gesondert informieren.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span lang=DE-AT style='font-size:
18.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Rechtsgrundlagen<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>In der folgenden Datenschutzerklärung
geben wir Ihnen transparente Informationen zu den rechtlichen Grundsätzen und
Vorschriften, also den Rechtsgrundlagen der Datenschutz-Grundverordnung, die
uns ermöglichen, personenbezogene Daten zu verarbeiten.<br>
Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU) 2016/679
DES EUROPÄISCHEN PARLAMENTS UND DES RATES vom 27. April 2016. Diese
Datenschutz-Grundverordnung der EU können Sie selbstverständlich online auf
EUR-Lex, dem Zugang zum EU-Recht, unter </span><span lang=DE-AT><a
href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=111870333"
target="_blank"><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=celex%3A32016R0679</span></a></span><span
lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'> nachlesen.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Wir verarbeiten Ihre Daten nur, wenn
mindestens eine der folgenden Bedingungen zutrifft:<o:p></o:p></span></p>

<ol start=1 type=1>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l8 level1 lfo3;tab-stops:list .5in'><b><span
     lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
     mso-fareast-font-family:"Times New Roman"'>Einwilligung</span></b><span
     lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
     mso-fareast-font-family:"Times New Roman"'> (Artikel 6 Absatz 1 lit. a
     DSGVO): Sie haben uns Ihre Einwilligung gegeben, Daten zu einem bestimmten
     Zweck zu verarbeiten. Ein Beispiel wäre die Speicherung Ihrer eingegebenen
     Daten eines Kontaktformulars.<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l8 level1 lfo3;tab-stops:list .5in'><b><span
     lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
     mso-fareast-font-family:"Times New Roman"'>Vertrag</span></b><span
     lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
     mso-fareast-font-family:"Times New Roman"'> (Artikel 6 Absatz 1 lit. b
     DSGVO): Um einen Vertrag oder vorvertragliche Verpflichtungen mit Ihnen zu
     erfüllen, verarbeiten wir Ihre Daten. Wenn wir zum Beispiel einen
     Kaufvertrag mit Ihnen abschließen, benötigen wir vorab personenbezogene
     Informationen.<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l8 level1 lfo3;tab-stops:list .5in'><b><span
     lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
     mso-fareast-font-family:"Times New Roman"'>Rechtliche Verpflichtung</span></b><span
     lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
     mso-fareast-font-family:"Times New Roman"'> (Artikel 6 Absatz 1 lit. c
     DSGVO): Wenn wir einer rechtlichen Verpflichtung unterliegen, verarbeiten
     wir Ihre Daten. Zum Beispiel sind wir gesetzlich verpflichtet Rechnungen
     für die Buchhaltung aufzuheben. </span><span style='font-size:12.0pt;
     font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
     mso-ansi-language:EN-US'>Diese enthalten in der Regel personenbezogene
     Daten.<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l8 level1 lfo3;tab-stops:list .5in'><b><span
     lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
     mso-fareast-font-family:"Times New Roman"'>Berechtigte Interessen</span></b><span
     lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
     mso-fareast-font-family:"Times New Roman"'> (Artikel 6 Absatz 1 lit. f
     DSGVO): Im Falle berechtigter Interessen, die Ihre Grundrechte nicht
     einschränken, behalten wir uns die Verarbeitung personenbezogener Daten
     vor. Wir müssen zum Beispiel gewisse Daten verarbeiten, um unsere Website
     sicher und wirtschaftlich effizient betreiben zu können. </span><span
     style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
     "Times New Roman";mso-ansi-language:EN-US'>Diese Verarbeitung ist somit
     ein berechtigtes Interesse.<o:p></o:p></span></li>
</ol>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Weitere Bedingungen wie die
Wahrnehmung von Aufnahmen im öffentlichen Interesse und Ausübung öffentlicher
Gewalt sowie dem Schutz lebenswichtiger Interessen treten bei uns in der Regel
nicht auf. Soweit eine solche Rechtsgrundlage doch einschlägig sein sollte,
wird diese an der entsprechenden Stelle ausgewiesen.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Zusätzlich zu der EU-Verordnung
gelten auch noch nationale Gesetze:<o:p></o:p></span></p>

<ul type=disc>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l1 level1 lfo4;tab-stops:list .5in'><span
     lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
     mso-fareast-font-family:"Times New Roman"'>In <b>Österreich</b> ist dies
     das Bundesgesetz zum Schutz natürlicher Personen bei der Verarbeitung
     personenbezogener Daten (<b>Datenschutzgesetz</b>), kurz <b>DSG</b>.<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l1 level1 lfo4;tab-stops:list .5in'><span
     lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
     mso-fareast-font-family:"Times New Roman"'>In <b>Deutschland</b> gilt das <b>Bundesdatenschutzgesetz</b>,
     kurz<b> BDSG</b>.<o:p></o:p></span></li>
</ul>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Sofern weitere regionale oder
nationale Gesetze zur Anwendung kommen, informieren wir Sie in den folgenden
Abschnitten darüber.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span lang=DE-AT style='font-size:
18.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Kontaktdaten
des Verantwortlichen<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Sollten Sie Fragen zum Datenschutz
haben, finden Sie nachfolgend die Kontaktdaten der verantwortlichen Person bzw.
Stelle:<br>
Matthias Schuller<br>
Zirkusgasse 5/6/30<br>
1020 Wien<br>
<br>
Vertretungsberechtigt: Matthias Schuller<br>
E-Mail: </span><span lang=DE-AT><a href="mailto:office@kairos.fund"><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman"'>office@kairos.fund</span></a></span><span lang=DE-AT
style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman"'> <br>
Telefon: 0660 586 555 1<br>
Impressum: </span><span lang=DE-AT><a href="www.IP2B.com/Impressum"><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman"'>www.IP2B.com/Impressum</span></a></span><span lang=DE-AT
style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman"'> <o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span lang=DE-AT style='font-size:
18.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Speicherdauer<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Dass wir personenbezogene Daten nur
so lange speichern, wie es für die Bereitstellung unserer Dienstleistungen und
Produkte unbedingt notwendig ist, gilt als generelles Kriterium bei uns. Das
bedeutet, dass wir personenbezogene Daten löschen, sobald der Grund für die
Datenverarbeitung nicht mehr vorhanden ist. In einigen Fällen sind wir
gesetzlich dazu verpflichtet, bestimmte Daten auch nach Wegfall des
ursprüngliches Zwecks zu speichern, zum Beispiel zu Zwecken der Buchführung.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Sollten Sie die Löschung Ihrer Daten
wünschen oder die Einwilligung zur Datenverarbeitung widerrufen, werden die
Daten so rasch wie möglich und soweit keine Pflicht zur Speicherung besteht,
gelöscht.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Über die konkrete Dauer der
jeweiligen Datenverarbeitung informieren wir Sie weiter unten, sofern wir
weitere Informationen dazu haben.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span lang=DE-AT style='font-size:
18.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Rechte
laut Datenschutz-Grundverordnung<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Laut Artikel 13 DSGVO stehen Ihnen
die folgenden Rechte zu, damit es zu einer fairen und transparenten
Verarbeitung von Daten kommt:<o:p></o:p></span></p>

<ul type=disc>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l2 level1 lfo5;tab-stops:list .5in'><span
     lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
     mso-fareast-font-family:"Times New Roman"'>Sie haben laut Artikel 15 DSGVO
     ein Auskunftsrecht darüber, ob wir Daten von Ihnen verarbeiten. Sollte das
     zutreffen, haben Sie Recht darauf eine Kopie der Daten zu erhalten und die
     folgenden Informationen zu erfahren: <o:p></o:p></span></li>
 <ul type=circle>
  <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:
      auto;line-height:normal;mso-list:l2 level2 lfo5;tab-stops:list 1.0in'><span
      lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
      mso-fareast-font-family:"Times New Roman"'>zu welchem Zweck wir die
      Verarbeitung durchführen;<o:p></o:p></span></li>
  <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:
      auto;line-height:normal;mso-list:l2 level2 lfo5;tab-stops:list 1.0in'><span
      lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
      mso-fareast-font-family:"Times New Roman"'>die Kategorien, also die Arten
      von Daten, die verarbeitet werden;<o:p></o:p></span></li>
  <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:
      auto;line-height:normal;mso-list:l2 level2 lfo5;tab-stops:list 1.0in'><span
      lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
      mso-fareast-font-family:"Times New Roman"'>wer diese Daten erhält und
      wenn die Daten an Drittländer übermittelt werden, wie die Sicherheit
      garantiert werden kann;<o:p></o:p></span></li>
  <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:
      auto;line-height:normal;mso-list:l2 level2 lfo5;tab-stops:list 1.0in'><span
      lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
      mso-fareast-font-family:"Times New Roman"'>wie lange die Daten
      gespeichert werden;<o:p></o:p></span></li>
  <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:
      auto;line-height:normal;mso-list:l2 level2 lfo5;tab-stops:list 1.0in'><span
      lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
      mso-fareast-font-family:"Times New Roman"'>das Bestehen des Rechts auf
      Berichtigung, Löschung oder Einschränkung der Verarbeitung und dem
      Widerspruchsrecht gegen die Verarbeitung;<o:p></o:p></span></li>
  <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:
      auto;line-height:normal;mso-list:l2 level2 lfo5;tab-stops:list 1.0in'><span
      lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
      mso-fareast-font-family:"Times New Roman"'>dass Sie sich bei einer
      Aufsichtsbehörde beschweren können (Links zu diesen Behörden finden Sie
      weiter unten);<o:p></o:p></span></li>
  <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:
      auto;line-height:normal;mso-list:l2 level2 lfo5;tab-stops:list 1.0in'><span
      lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
      mso-fareast-font-family:"Times New Roman"'>die Herkunft der Daten, wenn
      wir sie nicht bei Ihnen erhoben haben;<o:p></o:p></span></li>
  <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:
      auto;line-height:normal;mso-list:l2 level2 lfo5;tab-stops:list 1.0in'><span
      lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
      mso-fareast-font-family:"Times New Roman"'>ob Profiling durchgeführt
      wird, ob also Daten automatisch ausgewertet werden, um zu einem
      persönlichen Profil von Ihnen zu gelangen.<o:p></o:p></span></li>
 </ul>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l2 level1 lfo5;tab-stops:list .5in'><span
     lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
     mso-fareast-font-family:"Times New Roman"'>Sie haben laut Artikel 16 DSGVO
     ein Recht auf Berichtigung der Daten, was bedeutet, dass wir Daten richtig
     stellen müssen, falls Sie Fehler finden.<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l2 level1 lfo5;tab-stops:list .5in'><span
     lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
     mso-fareast-font-family:"Times New Roman"'>Sie haben laut Artikel 17 DSGVO
     das Recht auf Löschung („Recht auf Vergessenwerden“), was konkret
     bedeutet, dass Sie die Löschung Ihrer Daten verlangen dürfen.<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l2 level1 lfo5;tab-stops:list .5in'><span
     lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
     mso-fareast-font-family:"Times New Roman"'>Sie haben laut Artikel 18 DSGVO
     das Recht auf Einschränkung der Verarbeitung, was bedeutet, dass wir die
     Daten nur mehr speichern dürfen aber nicht weiter verwenden.<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l2 level1 lfo5;tab-stops:list .5in'><span
     lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
     mso-fareast-font-family:"Times New Roman"'>Sie haben laut Artikel 19 DSGVO
     das Recht auf Datenübertragbarkeit, was bedeutet, dass wir Ihnen auf
     Anfrage Ihre Daten in einem gängigen Format zur Verfügung stellen.<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l2 level1 lfo5;tab-stops:list .5in'><span
     lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
     mso-fareast-font-family:"Times New Roman"'>Sie haben laut Artikel 21 DSGVO
     ein Widerspruchsrecht, welches nach Durchsetzung eine Änderung der
     Verarbeitung mit sich bringt. <o:p></o:p></span></li>
 <ul type=circle>
  <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:
      auto;line-height:normal;mso-list:l2 level2 lfo5;tab-stops:list 1.0in'><span
      lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
      mso-fareast-font-family:"Times New Roman"'>Wenn die Verarbeitung Ihrer
      Daten auf Artikel 6 Abs. 1 lit. e (öffentliches Interesse, Ausübung
      öffentlicher Gewalt) oder Artikel 6 Abs. 1 lit. f (berechtigtes
      Interesse) basiert, können Sie gegen die Verarbeitung Widerspruch
      einlegen. Wir prüfen danach so rasch wie möglich, ob wir diesem
      Widerspruch rechtlich nachkommen können.<o:p></o:p></span></li>
  <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:
      auto;line-height:normal;mso-list:l2 level2 lfo5;tab-stops:list 1.0in'><span
      lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
      mso-fareast-font-family:"Times New Roman"'>Werden Daten verwendet, um
      Direktwerbung zu betreiben, können Sie jederzeit gegen diese Art der
      Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr
      für Direktmarketing verwenden.<o:p></o:p></span></li>
  <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:
      auto;line-height:normal;mso-list:l2 level2 lfo5;tab-stops:list 1.0in'><span
      lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
      mso-fareast-font-family:"Times New Roman"'>Werden Daten verwendet, um
      Profiling zu betreiben, können Sie jederzeit gegen diese Art der
      Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr
      für Profiling verwenden.<o:p></o:p></span></li>
 </ul>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l2 level1 lfo5;tab-stops:list .5in'><span
     lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
     mso-fareast-font-family:"Times New Roman"'>Sie haben laut Artikel 22 DSGVO
     unter Umständen das Recht, nicht einer ausschließlich auf einer
     automatisierten Verarbeitung (zum Beispiel Profiling) beruhenden
     Entscheidung unterworfen zu werden.<o:p></o:p></span></li>
</ul>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span lang=DE-AT style='font-size:12.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Kurz gesagt:</span></b><span
lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'> Sie haben Rechte – zögern Sie
nicht, die oben gelistete verantwortliche Stelle bei uns zu kontaktieren!<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Wenn Sie glauben, dass die
Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre
datenschutzrechtlichen Ansprüche in sonst einer Weise verletzt worden sind,
können Sie sich bei der Aufsichtsbehörde beschweren. Diese ist für Österreich
die Datenschutzbehörde, deren Website Sie unter </span><span lang=DE-AT><a
href="https://www.dsb.gv.at/?tid=111870333" target="_blank"><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman"'>https://www.dsb.gv.at/</span></a></span><span lang=DE-AT
style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman"'> finden. In Deutschland gibt es für jedes Bundesland einen
Datenschutzbeauftragten. Für nähere Informationen können Sie sich an die </span><span
lang=DE-AT><a href="https://www.bfdi.bund.de" target="_blank"><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman"'>Bundesbeauftragte für den Datenschutz und die
Informationsfreiheit (BfDI)</span></a></span><span lang=DE-AT style='font-size:
12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
wenden. Für unser Unternehmen ist die folgende lokale Datenschutzbehörde
zuständig:<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span lang=DE-AT style='font-size:
18.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Österreich
Datenschutzbehörde<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span lang=DE-AT style='font-size:12.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Leiterin: </span></b><span
lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Mag. Dr. Andrea Jelinek<b> <br>
Adresse: </b>Barichgasse 40-42, 1030 Wien<b> <br>
Telefonnr.: </b>+43 1 52&nbsp;152-0<b> <br>
E-Mail-Adresse: </b></span><span lang=DE-AT><a href="mailto:dsb@dsb.gv.at"
target="_blank"><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>dsb@dsb.gv.at</span></a></span><span
lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'> <b><br>
Website: </b></span><span lang=DE-AT><a href="https://www.dsb.gv.at/"
target="_blank"><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>https://www.dsb.gv.at/</span></a></span><span
lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'> <o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span lang=DE-AT style='font-size:
18.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Datenübertragung
in Drittländer<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Wir übertragen oder verarbeiten
Daten nur dann in Länder außerhalb der EU (Drittländer), wenn Sie dieser
Verarbeitung zustimmen, dies gesetzlich vorgeschrieben ist oder vertraglich
notwendig und in jedem Fall nur soweit dies generell erlaubt ist. Ihre
Zustimmung ist in den meisten Fällen der wichtigste Grund, dass wir Daten in
Drittländern verarbeiten lassen. Die Verarbeitung personenbezogener Daten in
Drittländern wie den USA, wo viele Softwarehersteller Dienstleistungen anbieten
und Ihre Serverstandorte haben, kann bedeuten, dass personenbezogene Daten auf
unerwartete Weise verarbeitet und gespeichert werden.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Wir weisen ausdrücklich darauf hin,
dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes
Schutzniveau für den Datentransfer in die USA besteht. Die Datenverarbeitung
durch US-Dienste (wie beispielsweise Google Analytics) kann dazu führen, dass
gegebenenfalls Daten nicht anonymisiert verarbeitet und gespeichert werden.
Ferner können gegebenenfalls US-amerikanische staatliche Behörden Zugriff auf
einzelne Daten nehmen. Zudem kann es vorkommen, dass erhobene Daten mit Daten
aus anderen Diensten desselben Anbieters, sofern Sie ein entsprechendes
Nutzerkonto haben, verknüpft werden. Nach Möglichkeit versuchen wir
Serverstandorte innerhalb der EU zu nutzen, sofern das angeboten wird.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Wir informieren Sie an den passenden
Stellen dieser Datenschutzerklärung genauer über Datenübertragung in
Drittländer, sofern diese zutrifft.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span lang=DE-AT style='font-size:
18.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Sicherheit
der Datenverarbeitung<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Um personenbezogene Daten zu
schützen, haben wir sowohl technische als auch organisatorische Maßnahmen
umgesetzt. Wo es uns möglich ist, verschlüsseln oder pseudonymisieren wir
personenbezogene Daten. Dadurch machen wir es im Rahmen unserer Möglichkeiten
so schwer wie möglich, dass Dritte aus unseren Daten auf persönliche Informationen
schließen können.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Art. 25 DSGVO spricht hier von
“Datenschutz durch Technikgestaltung und durch datenschutzfreundliche
Voreinstellungen” und meint damit, dass man sowohl bei Software (z. B.
Formularen) also auch Hardware (z. B. Zugang zum Serverraum) immer an
Sicherheit denkt und entsprechende Maßnahmen setzt. Im Folgenden gehen wir,
falls erforderlich, noch auf konkrete Maßnahmen ein.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span lang=DE-AT style='font-size:
18.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>TLS-Verschlüsselung
mit https<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>TLS, Verschlüsselung und https
klingen sehr technisch und sind es auch. Wir verwenden HTTPS (das Hypertext
Transfer Protocol Secure steht für „sicheres Hypertext-Übertragungsprotokoll“),
um Daten abhörsicher im Internet zu übertragen.<br>
Das bedeutet, dass die komplette Übertragung aller Daten von Ihrem Browser zu
unserem Webserver abgesichert ist – niemand kann “mithören”.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Damit haben wir eine zusätzliche
Sicherheitsschicht eingeführt und erfüllen Datenschutz durch Technikgestaltung </span><span
lang=DE-AT><a
href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=111870333"
target="_blank"><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Artikel 25 Absatz 1 DSGVO</span></a></span><span
lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>). Durch den Einsatz von TLS
(Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren
Datenübertragung im Internet, können wir den Schutz vertraulicher Daten
sicherstellen.<br>
Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen
Schlosssymbol </span><!--[if mso & !supportInlineShapes & supportFields]><span
lang=DE-AT><span style='mso-element:field-begin;mso-field-lock:yes'></span><span
style='mso-spacerun:yes'> </span>SHAPE <span
style='mso-spacerun:yes'> </span>\* MERGEFORMAT <span style='mso-element:field-separator'></span></span><![endif]--><span
lang=DE-AT><!--[if gte vml 1]><v:rect id="Rechteck_x0020_3" o:spid="_x0000_s1028"
 style='width:12.75pt;height:13.5pt;visibility:visible;mso-wrap-style:square;
 mso-left-percent:-10001;mso-top-percent:-10001;mso-position-horizontal:absolute;
 mso-position-horizontal-relative:char;mso-position-vertical:absolute;
 mso-position-vertical-relative:line;mso-left-percent:-10001;mso-top-percent:-10001;
 v-text-anchor:top' o:gfxdata="UEsDBBQABgAIAAAAIQC2gziS/gAAAOEBAAATAAAAW0NvbnRlbnRfVHlwZXNdLnhtbJSRQU7DMBBF
90jcwfIWJU67QAgl6YK0S0CoHGBkTxKLZGx5TGhvj5O2G0SRWNoz/78nu9wcxkFMGNg6quQqL6RA
0s5Y6ir5vt9lD1JwBDIwOMJKHpHlpr69KfdHjyxSmriSfYz+USnWPY7AufNIadK6MEJMx9ApD/oD
OlTrorhX2lFEilmcO2RdNtjC5xDF9pCuTyYBB5bi6bQ4syoJ3g9WQ0ymaiLzg5KdCXlKLjvcW893
SUOqXwnz5DrgnHtJTxOsQfEKIT7DmDSUCaxw7Rqn8787ZsmRM9e2VmPeBN4uqYvTtW7jvijg9N/y
JsXecLq0q+WD6m8AAAD//wMAUEsDBBQABgAIAAAAIQA4/SH/1gAAAJQBAAALAAAAX3JlbHMvLnJl
bHOkkMFqwzAMhu+DvYPRfXGawxijTi+j0GvpHsDYimMaW0Yy2fr2M4PBMnrbUb/Q94l/f/hMi1qR
JVI2sOt6UJgd+ZiDgffL8ekFlFSbvV0oo4EbChzGx4f9GRdb25HMsYhqlCwG5lrLq9biZkxWOiqY
22YiTra2kYMu1l1tQD30/bPm3wwYN0x18gb45AdQl1tp5j/sFB2T0FQ7R0nTNEV3j6o9feQzro1i
OWA14Fm+Q8a1a8+Bvu/d/dMb2JY5uiPbhG/ktn4cqGU/er3pcvwCAAD//wMAUEsDBBQABgAIAAAA
IQAddxIY8AEAAMMDAAAOAAAAZHJzL2Uyb0RvYy54bWysU8Fu2zAMvQ/YPwi6L47TpF2NOEXRosOA
bivW7QMYWbaF2qJGKXGyrx8lJ1m63YZdBJGUHx8fn5c3u74TW03eoC1lPplKoa3CytimlN+/Pbx7
L4UPYCvo0OpS7rWXN6u3b5aDK/QMW+wqTYJBrC8GV8o2BFdkmVet7sFP0GnLxRqph8AhNVlFMDB6
32Wz6fQyG5AqR6i095y9H4tylfDrWqvwpa69DqIrJXML6aR0ruOZrZZQNASuNepAA/6BRQ/GctMT
1D0EEBsyf0H1RhF6rMNEYZ9hXRul0ww8TT79Y5rnFpxOs7A43p1k8v8PVn3ePpEwVSkvpLDQ84q+
atUGrV7ERVRncL7gR8/uieJ83j2ievHC4l0LttG33rHGvHn++pgiwqHVUDHNPEJkrzBi4BlNrIdP
WHE/2ARM2u1q6mMPVkXs0or2pxXpXRCKk/llfj1bSKG4lF/l80VaYQbF8WNHPnzQ2It4KSUxuwQO
20cfIhkojk9iL4sPpuuSCzr7KsEPYyaRj3xHKdZY7Zk74egkdj5fWqSfUgzsolL6HxsgLUX30fL8
1/l8Hm2XgvniasYBnVfW5xWwiqFKGaQYr3dhtOrGkWnaJPPI8ZY1q02aJ+o5sjqQZaekMQ+ujlY8
j9Or3//e6hcAAAD//wMAUEsDBBQABgAIAAAAIQC3lCoy2wAAAAMBAAAPAAAAZHJzL2Rvd25yZXYu
eG1sTI9BS8NAEIXvgv9hGcGL2I2FqsRsihTEIkJpqj1Ps2MSzM6m2W0S/72jF73MY3jDe99ky8m1
aqA+NJ4N3MwSUMSltw1XBt52T9f3oEJEtth6JgNfFGCZn59lmFo/8paGIlZKQjikaKCOsUu1DmVN
DsPMd8TiffjeYZS1r7TtcZRw1+p5ktxqhw1LQ40drWoqP4uTMzCWm2G/e33Wm6v92vNxfVwV7y/G
XF5Mjw+gIk3x7xh+8AUdcmE6+BPboFoD8kj8neLNFwtQB9G7BHSe6f/s+TcAAAD//wMAUEsBAi0A
FAAGAAgAAAAhALaDOJL+AAAA4QEAABMAAAAAAAAAAAAAAAAAAAAAAFtDb250ZW50X1R5cGVzXS54
bWxQSwECLQAUAAYACAAAACEAOP0h/9YAAACUAQAACwAAAAAAAAAAAAAAAAAvAQAAX3JlbHMvLnJl
bHNQSwECLQAUAAYACAAAACEAHXcSGPABAADDAwAADgAAAAAAAAAAAAAAAAAuAgAAZHJzL2Uyb0Rv
Yy54bWxQSwECLQAUAAYACAAAACEAt5QqMtsAAAADAQAADwAAAAAAAAAAAAAAAABKBAAAZHJzL2Rv
d25yZXYueG1sUEsFBgAAAAAEAAQA8wAAAFIFAAAAAA==
" filled="f" stroked="f">
 <o:lock v:ext="edit" aspectratio="t"/>
 <w:wrap type="none"/>
 <w:anchorlock/>
</v:rect><![endif]--><!--[if gte vml 1]><v:shapetype id="_x0000_t75"
 coordsize="21600,21600" o:spt="75" o:preferrelative="t" path="m@4@5l@4@11@9@11@9@5xe"
 filled="f" stroked="f">
 <v:stroke joinstyle="miter"/>
 <v:formulas>
  <v:f eqn="if lineDrawn pixelLineWidth 0"/>
  <v:f eqn="sum @0 1 0"/>
  <v:f eqn="sum 0 0 @1"/>
  <v:f eqn="prod @2 1 2"/>
  <v:f eqn="prod @3 21600 pixelWidth"/>
  <v:f eqn="prod @3 21600 pixelHeight"/>
  <v:f eqn="sum @0 0 1"/>
  <v:f eqn="prod @6 1 2"/>
  <v:f eqn="prod @7 21600 pixelWidth"/>
  <v:f eqn="sum @8 21600 0"/>
  <v:f eqn="prod @7 21600 pixelHeight"/>
  <v:f eqn="sum @10 21600 0"/>
 </v:formulas>
 <v:path o:extrusionok="f" gradientshapeok="t" o:connecttype="rect"/>
 <o:lock v:ext="edit" aspectratio="t"/>
</v:shapetype><![endif]--></span><!--[if mso & !supportInlineShapes & supportFields]><span
lang=DE-AT><v:shape id="_x0000_i1027" type="#_x0000_t75" style='width:12.75pt;
 height:13.5pt'>
 <v:imagedata croptop="-65520f" cropbottom="65520f"/>
</v:shape><span style='mso-element:field-end'></span></span><![endif]--><span
lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>links oben im Browser, links von der
Internetadresse (z. B. beispielseite.de) und der Verwendung des Schemas https
(anstatt http) als Teil unserer Internetadresse.<br>
Wenn Sie mehr zum Thema Verschlüsselung wissen möchten, empfehlen wir die
Google Suche nach “Hypertext Transfer Protocol Secure wiki” um gute Links zu
weiterführenden Informationen zu erhalten.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span style='font-size:18.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-ansi-language:EN-US'>Kommunikation<o:p></o:p></span></b></p>

<table class=MsoNormalTable border=1 cellpadding=0 style='mso-cellspacing:1.5pt;
 mso-yfti-tbllook:1184;mso-padding-alt:11.25pt 11.25pt 11.25pt 11.25pt'>
 <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes'>
  <td style='padding:11.25pt 11.25pt 11.25pt 11.25pt'>
  <p class=MsoNormal style='margin-bottom:0in;line-height:normal'><b><span
  lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
  mso-fareast-font-family:"Times New Roman"'>Kommunikation Zusammenfassung</span></b><span
  lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
  mso-fareast-font-family:"Times New Roman"'> <br>
  </span><span style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;
  mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Segoe UI Emoji";
  mso-ansi-language:EN-US'>&#128101;</span><span lang=DE-AT style='font-size:
  12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
  Betroffene: Alle, die mit uns per Telefon, E-Mail oder Online-Formular
  kommunizieren<br>
  </span><span style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;
  mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Segoe UI Emoji";
  mso-ansi-language:EN-US'>&#128211;</span><span lang=DE-AT style='font-size:
  12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
  Verarbeitete Daten: z. B. Telefonnummer, Name, E-Mail-Adresse, eingegebene
  Formulardaten. Mehr Details dazu finden Sie bei der jeweils eingesetzten
  Kontaktart<br>
  </span><span style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;
  mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Segoe UI Emoji";
  mso-ansi-language:EN-US'>&#129309;</span><span lang=DE-AT style='font-size:
  12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
  Zweck: Abwicklung der Kommunikation mit Kunden, Geschäftspartnern usw.<br>
  </span><span style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;
  mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Segoe UI Emoji";
  mso-ansi-language:EN-US'>&#128197;</span><span lang=DE-AT style='font-size:
  12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
  Speicherdauer: Dauer des Geschäftsfalls und der gesetzlichen Vorschriften<br>
  </span><span lang=DE-AT style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;
  mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Segoe UI Emoji"'>&#9878;</span><span
  style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Segoe UI Emoji";mso-ansi-language:
  EN-US'>&#65039;</span><span lang=DE-AT style='font-size:12.0pt;font-family:
  "Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
  Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1
  lit. b DSGVO (Vertrag), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)<o:p></o:p></span></p>
  </td>
 </tr>
</table>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:white;mso-color-alt:windowtext'>Wenn
Sie mit uns Kontakt aufnehmen und per Telefon, E-Mail oder Online-Formular
kommunizieren, kann es zur Verarbeitung personenbezogener Daten kommen.</span><span
lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Die Daten werden für die Abwicklung
und Bearbeitung Ihrer Frage und des damit zusammenhängenden Geschäftsvorgangs
verarbeitet. Die Daten während ebensolange gespeichert bzw. so lange es das
Gesetz vorschreibt.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Betroffene
Personen<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Von den genannten Vorgängen sind
alle betroffen, die über die von uns bereit gestellten Kommunikationswege den
Kontakt zu uns suchen.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Telefon<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Wenn Sie uns anrufen, werden die
Anrufdaten auf dem jeweiligen Endgerät und beim eingesetzten
Telekommunikationsanbieter pseudonymisiert gespeichert. Außerdem können Daten
wie Name und Telefonnummer im Anschluss per E-Mail versendet und zur
Anfragebeantwortung gespeichert werden. Die Daten werden gelöscht, sobald der
Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>E-Mail<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Wenn Sie mit uns per E-Mail
kommunizieren, werden Daten gegebenenfalls auf dem jeweiligen Endgerät
(Computer, Laptop, Smartphone,…) gespeichert und es kommt zur Speicherung von
Daten auf dem E-Mail-Server. Die Daten werden gelöscht, sobald der
Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Online
Formulare<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Wenn Sie mit uns mittels
Online-Formular kommunizieren, werden Daten auf unserem Webserver gespeichert
und gegebenenfalls an eine E-Mail-Adresse von uns weitergeleitet. Die Daten
werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche
Vorgaben erlauben.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Rechtsgrundlagen<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Die Verarbeitung der Daten basiert
auf den folgenden Rechtsgrundlagen:<o:p></o:p></span></p>

<ul type=disc>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l4 level1 lfo6;tab-stops:list .5in'><span
     lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
     mso-fareast-font-family:"Times New Roman"'>Art. 6 Abs. 1 lit. a DSGVO
     (Einwilligung): Sie geben uns die Einwilligung Ihre Daten zu speichern und
     weiter für den Geschäftsfall betreffende Zwecke zu verwenden;<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l4 level1 lfo6;tab-stops:list .5in'><span
     lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
     mso-fareast-font-family:"Times New Roman"'>Art. 6 Abs. 1 lit. b DSGVO
     (Vertrag): Es besteht die Notwendigkeit für die Erfüllung eines Vertrags
     mit Ihnen oder einem Auftragsverarbeiter wie z. B. dem Telefonanbieter
     oder wir müssen die Daten für vorvertragliche Tätigkeiten, wie z. B. die
     Vorbereitung eines Angebots, verarbeiten;<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l4 level1 lfo6;tab-stops:list .5in'><span
     lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
     mso-fareast-font-family:"Times New Roman"'>Art. 6 Abs. 1 lit. f DSGVO
     (Berechtigte Interessen): Wir wollen Kundenanfragen und geschäftliche
     Kommunikation in einem professionellen Rahmen betreiben. Dazu sind gewisse
     technische Einrichtungen wie z. B. E-Mail-Programme, Exchange-Server und
     Mobilfunkbetreiber notwendig, um die Kommunikation effizient betreiben zu
     können.<o:p></o:p></span></li>
</ul>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span style='font-size:18.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-ansi-language:EN-US'>Cookies<o:p></o:p></span></b></p>

<table class=MsoNormalTable border=1 cellpadding=0 style='mso-cellspacing:1.5pt;
 mso-yfti-tbllook:1184;mso-padding-alt:11.25pt 11.25pt 11.25pt 11.25pt'>
 <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes'>
  <td style='padding:11.25pt 11.25pt 11.25pt 11.25pt'>
  <p class=MsoNormal style='margin-bottom:0in;line-height:normal'><b><span
  lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
  mso-fareast-font-family:"Times New Roman"'>Cookies Zusammenfassung</span></b><span
  lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
  mso-fareast-font-family:"Times New Roman"'> <br>
  </span><span style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;
  mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Segoe UI Emoji";
  mso-ansi-language:EN-US'>&#128101;</span><span lang=DE-AT style='font-size:
  12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
  Betroffene: Besucher der Website<br>
  </span><span style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;
  mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Segoe UI Emoji";
  mso-ansi-language:EN-US'>&#129309;</span><span lang=DE-AT style='font-size:
  12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
  Zweck: abhängig vom jeweiligen Cookie. Mehr Details dazu finden Sie weiter unten
  bzw. beim Hersteller der Software, der das Cookie setzt.<br>
  </span><span style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;
  mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Segoe UI Emoji";
  mso-ansi-language:EN-US'>&#128211;</span><span lang=DE-AT style='font-size:
  12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
  Verarbeitete Daten: Abhängig vom jeweils eingesetzten Cookie. Mehr Details
  dazu finden Sie weiter unten bzw. beim Hersteller der Software, der das
  Cookie setzt.<br>
  </span><span style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;
  mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Segoe UI Emoji";
  mso-ansi-language:EN-US'>&#128197;</span><span lang=DE-AT style='font-size:
  12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
  Speicherdauer: abhängig vom jeweiligen Cookie, kann von Stunden bis hin zu
  Jahren variieren<br>
  </span><span lang=DE-AT style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;
  mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Segoe UI Emoji"'>&#9878;</span><span
  style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Segoe UI Emoji";mso-ansi-language:
  EN-US'>&#65039;</span><span lang=DE-AT style='font-size:12.0pt;font-family:
  "Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
  Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1
  lit.f DSGVO (Berechtigte Interessen)<o:p></o:p></span></p>
  </td>
 </tr>
</table>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:white;mso-color-alt:windowtext'>Was sind Cookies?</span></b><b><span
lang=DE-AT style='font-size:13.5pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'><o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Unsere Website verwendet
HTTP-Cookies, um nutzerspezifische Daten zu speichern.<br>
Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit
Sie die folgende Datenschutzerklärung besser verstehen.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Immer wenn Sie durch das Internet
surfen, verwenden Sie einen Browser. Bekannte Browser sind beispielsweise
Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten
Websites speichern kleine Text-Dateien in Ihrem Browser. Diese Dateien nennt
man Cookies.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Eines ist nicht von der Hand zu
weisen: Cookies sind echt nützliche Helferlein. Fast alle Websites verwenden
Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch andere
Cookies für andere Anwendungsbereiche gibt. HTTP-Cookies sind kleine Dateien,
die von unserer Website auf Ihrem Computer gespeichert werden. Diese
Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem “Hirn” Ihres
Browsers, untergebracht. Ein Cookie besteht aus einem Namen und einem Wert. Bei
der Definition eines Cookies müssen zusätzlich ein oder mehrere Attribute
angegeben werden.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Cookies speichern gewisse
Nutzerdaten von Ihnen, wie beispielsweise Sprache oder persönliche
Seiteneinstellungen. Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr
Browser die „userbezogenen“ Informationen an unsere Seite zurück. Dank der
Cookies weiß unsere Website, wer Sie sind und bietet Ihnen die Einstellung, die
Sie gewohnt sind. In einigen Browsern hat jedes Cookie eine eigene Datei, in
anderen wie beispielsweise Firefox sind alle Cookies in einer einzigen Datei
gespeichert.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Die folgende Grafik zeigt eine
mögliche Interaktion zwischen einem Webbrowser wie z. B. Chrome und dem
Webserver. Dabei fordert der Webbrowser eine Website an und erhält vom Server
ein Cookie zurück, welches der Browser erneut verwendet, sobald eine andere
Seite angefordert wird.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><!--[if mso & !supportInlineShapes & supportFields]><span
lang=DE-AT><span style='mso-element:field-begin;mso-field-lock:yes'></span><span
style='mso-spacerun:yes'> </span>SHAPE <span
style='mso-spacerun:yes'> </span>\* MERGEFORMAT <span style='mso-element:field-separator'></span></span><![endif]--><span
lang=DE-AT><!--[if gte vml 1]><v:rect id="Rechteck_x0020_2" o:spid="_x0000_s1027"
 style='width:768pt;height:768pt;visibility:visible;mso-wrap-style:square;
 mso-left-percent:-10001;mso-top-percent:-10001;mso-position-horizontal:absolute;
 mso-position-horizontal-relative:char;mso-position-vertical:absolute;
 mso-position-vertical-relative:line;mso-left-percent:-10001;mso-top-percent:-10001;
 v-text-anchor:top' o:gfxdata="UEsDBBQABgAIAAAAIQC2gziS/gAAAOEBAAATAAAAW0NvbnRlbnRfVHlwZXNdLnhtbJSRQU7DMBBF
90jcwfIWJU67QAgl6YK0S0CoHGBkTxKLZGx5TGhvj5O2G0SRWNoz/78nu9wcxkFMGNg6quQqL6RA
0s5Y6ir5vt9lD1JwBDIwOMJKHpHlpr69KfdHjyxSmriSfYz+USnWPY7AufNIadK6MEJMx9ApD/oD
OlTrorhX2lFEilmcO2RdNtjC5xDF9pCuTyYBB5bi6bQ4syoJ3g9WQ0ymaiLzg5KdCXlKLjvcW893
SUOqXwnz5DrgnHtJTxOsQfEKIT7DmDSUCaxw7Rqn8787ZsmRM9e2VmPeBN4uqYvTtW7jvijg9N/y
JsXecLq0q+WD6m8AAAD//wMAUEsDBBQABgAIAAAAIQA4/SH/1gAAAJQBAAALAAAAX3JlbHMvLnJl
bHOkkMFqwzAMhu+DvYPRfXGawxijTi+j0GvpHsDYimMaW0Yy2fr2M4PBMnrbUb/Q94l/f/hMi1qR
JVI2sOt6UJgd+ZiDgffL8ekFlFSbvV0oo4EbChzGx4f9GRdb25HMsYhqlCwG5lrLq9biZkxWOiqY
22YiTra2kYMu1l1tQD30/bPm3wwYN0x18gb45AdQl1tp5j/sFB2T0FQ7R0nTNEV3j6o9feQzro1i
OWA14Fm+Q8a1a8+Bvu/d/dMb2JY5uiPbhG/ktn4cqGU/er3pcvwCAAD//wMAUEsDBBQABgAIAAAA
IQA8T/AZ7AEAAMUDAAAOAAAAZHJzL2Uyb0RvYy54bWysU8Fu2zAMvQ/YPwi6L3a8tF2NOEXRosOA
bi3W9QMYWY6F2qJGKXGyrx8lJ1m63oZdBJGUHx8fn+dX274TG03eoK3kdJJLoa3C2thVJZ9/3H34
JIUPYGvo0OpK7rSXV4v37+aDK3WBLXa1JsEg1peDq2QbgiuzzKtW9+An6LTlYoPUQ+CQVllNMDB6
32VFnp9nA1LtCJX2nrO3Y1EuEn7TaBUemsbrILpKMreQTkrnMp7ZYg7lisC1Ru1pwD+w6MFYbnqE
uoUAYk3mDVRvFKHHJkwU9hk2jVE6zcDTTPO/pnlqwek0C4vj3VEm//9g1bfNIwlTV7KQwkLPK/qu
VRu0ehFFVGdwvuRHT+6R4nze3aN68cLiTQt2pa+9Y4158/z1IUWEQ6uhZprTCJG9woiBZzSxHL5i
zf1gHTBpt22ojz1YFbFNK9odV6S3QShOXl6cfTzPeZOKa4cg9oDy8LkjHz5r7EW8VJKYX4KHzb0P
49PDk9jN4p3pOs5D2dlXCcaMmUQ/Mh7FWGK9Y/aEo5fY+3xpkX5JMbCPKul/roG0FN0XywpcTmez
aLwUzM4uCg7otLI8rYBVDFXJIMV4vQmjWdeOzKpNQo8cr1m1xqR5oqIjqz1Z9kpSZO/raMbTOL36
8/ctfgMAAP//AwBQSwMEFAAGAAgAAAAhAMX/sbnaAAAABwEAAA8AAABkcnMvZG93bnJldi54bWxM
j0FLw0AQhe+C/2EZwYvYjYpFYjZFCmIRoZhqz9PsmASzs2l2m8R/71QQvQzzeMOb72WLybVqoD40
ng1czRJQxKW3DVcG3jaPl3egQkS22HomA18UYJGfnmSYWj/yKw1FrJSEcEjRQB1jl2odypochpnv
iMX78L3DKLKvtO1xlHDX6uskmWuHDcuHGjta1lR+FgdnYCzXw3bz8qTXF9uV5/1qvyzen405P5se
7kFFmuLfMRzxBR1yYdr5A9ugWgNSJP7Mo3d7Mxe9+910nun//Pk3AAAA//8DAFBLAQItABQABgAI
AAAAIQC2gziS/gAAAOEBAAATAAAAAAAAAAAAAAAAAAAAAABbQ29udGVudF9UeXBlc10ueG1sUEsB
Ai0AFAAGAAgAAAAhADj9If/WAAAAlAEAAAsAAAAAAAAAAAAAAAAALwEAAF9yZWxzLy5yZWxzUEsB
Ai0AFAAGAAgAAAAhADxP8BnsAQAAxQMAAA4AAAAAAAAAAAAAAAAALgIAAGRycy9lMm9Eb2MueG1s
UEsBAi0AFAAGAAgAAAAhAMX/sbnaAAAABwEAAA8AAAAAAAAAAAAAAAAARgQAAGRycy9kb3ducmV2
LnhtbFBLBQYAAAAABAAEAPMAAABNBQAAAAA=
" filled="f" stroked="f">
 <o:lock v:ext="edit" aspectratio="t"/>
 <w:wrap type="none"/>
 <w:anchorlock/>
</v:rect><![endif]--></span><!--[if mso & !supportInlineShapes & supportFields]><span
lang=DE-AT><v:shape id="_x0000_i1026" type="#_x0000_t75" style='width:768pt;
 height:768pt'>
 <v:imagedata croptop="-65520f" cropbottom="65520f"/>
</v:shape><span style='mso-element:field-end'></span></span><![endif]--><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-ansi-language:EN-US'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Es gibt sowohl Erstanbieter Cookies
als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden direkt von unserer
Seite erstellt, Drittanbieter-Cookies werden von Partner-Websites (z.B. Google
Analytics) erstellt. Jedes Cookie ist individuell zu bewerten, da jedes Cookie
andere Daten speichert. Auch die Ablaufzeit eines Cookies variiert von ein paar
Minuten bis hin zu ein paar Jahren. Cookies sind keine Software-Programme und
enthalten keine Viren, Trojaner oder andere „Schädlinge“. Cookies können auch
nicht auf Informationen Ihres PCs zugreifen.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>So können zum Beispiel Cookie-Daten
aussehen:<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span lang=DE-AT style='font-size:12.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Name:</span></b><span
lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'> _ga<br>
<b>Wert:</b>&nbsp;GA1.2.1326744211.152111870333-9<br>
<b>Verwendungszweck:</b> Unterscheidung der Websitebesucher<br>
<b>Ablaufdatum:</b>&nbsp;nach 2 Jahren<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Diese Mindestgrößen sollte ein
Browser unterstützen können:<o:p></o:p></span></p>

<ul type=disc>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l5 level1 lfo7;tab-stops:list .5in'><span
     style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
     "Times New Roman";mso-ansi-language:EN-US'>Mindestens 4096 Bytes pro
     Cookie<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l5 level1 lfo7;tab-stops:list .5in'><span
     style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
     "Times New Roman";mso-ansi-language:EN-US'>Mindestens 50 Cookies pro
     Domain<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l5 level1 lfo7;tab-stops:list .5in'><span
     style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
     "Times New Roman";mso-ansi-language:EN-US'>Mindestens 3000 Cookies
     insgesamt<o:p></o:p></span></li>
</ul>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Welche
Arten von Cookies gibt es?<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Die Frage welche Cookies wir im
Speziellen verwenden, hängt von den verwendeten Diensten ab und wird in den
folgenden Abschnitten der Datenschutzerklärung geklärt. An dieser Stelle
möchten wir kurz auf die verschiedenen Arten von HTTP-Cookies eingehen.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Man kann 4 Arten von Cookies
unterscheiden:<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span lang=DE-AT style='font-size:12.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Unerlässliche
Cookies<br>
</span></b><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Diese Cookies sind nötig, um
grundlegende Funktionen der Website sicherzustellen. Zum Beispiel braucht es
diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann auf
anderen Seiten weitersurft und später erst zur Kasse geht. Durch diese Cookies
wird der Warenkorb nicht gelöscht, selbst wenn der User sein Browserfenster
schließt.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span lang=DE-AT style='font-size:12.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Zweckmäßige
Cookies<br>
</span></b><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Diese Cookies sammeln Infos über das
Userverhalten und ob der User etwaige Fehlermeldungen bekommt. Zudem werden
mithilfe dieser Cookies auch die Ladezeit und das Verhalten der Website bei
verschiedenen Browsern gemessen.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span lang=DE-AT style='font-size:12.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Zielorientierte
Cookies<br>
</span></b><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Diese Cookies sorgen für eine
bessere Nutzerfreundlichkeit. Beispielsweise werden eingegebene Standorte,
Schriftgrößen oder Formulardaten gespeichert.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span lang=DE-AT style='font-size:12.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Werbe-Cookies<br>
</span></b><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Diese Cookies werden auch
Targeting-Cookies genannt. Sie dienen dazu dem User individuell angepasste
Werbung zu liefern. Das kann sehr praktisch, aber auch sehr nervig sein.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Üblicherweise werden Sie beim
erstmaligen Besuch einer Website gefragt, welche dieser Cookiearten Sie
zulassen möchten. Und natürlich wird diese Entscheidung auch in einem Cookie
gespeichert.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Wenn Sie mehr über Cookies wissen
möchten und technische Dokumentationen nicht scheuen, empfehlen wir </span><span
lang=DE-AT><a href="https://tools.ietf.org/html/rfc6265" target="_blank"><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman"'>https://tools.ietf.org/html/rfc6265</span></a></span><span
lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>, dem Request for Comments der
Internet Engineering Task Force (IETF) namens “HTTP State Management
Mechanism”.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Zweck
der Verarbeitung über Cookies<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Der Zweck ist letztendlich abhängig
vom jeweiligen Cookie. Mehr Details dazu finden Sie weiter unten bzw. beim
Hersteller der Software, die das Cookie setzt.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Welche
Daten werden verarbeitet?<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Cookies sind kleine Gehilfen für
eine viele verschiedene Aufgaben. Welche Daten in Cookies gespeichert werden,
kann man leider nicht verallgemeinern, aber wir werden Sie im Rahmen der
folgenden Datenschutzerklärung über die verarbeiteten bzw. gespeicherten Daten
informieren.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Speicherdauer
von Cookies<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Die Speicherdauer hängt vom
jeweiligen Cookie ab und wird weiter unter präzisiert. Manche Cookies werden
nach weniger als einer Stunde gelöscht, andere können mehrere Jahre auf einem
Computer gespeichert bleiben.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Sie haben außerdem selbst Einfluss
auf die Speicherdauer. Sie können über ihren Browser sämtliche Cookies
jederzeit manuell löschen (siehe auch unten “Widerspruchsrecht”). Ferner werden
Cookies, die auf einer Einwilligung beruhen, spätestens nach Widerruf Ihrer
Einwilligung gelöscht, wobei die Rechtmäßigkeit der Speicherung bis dahin
unberührt bleibt.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Widerspruchsrecht
– wie kann ich Cookies löschen?<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Wie und ob Sie Cookies verwenden
wollen, entscheiden Sie selbst. Unabhängig von welchem Service oder welcher
Website die Cookies stammen, haben Sie immer die Möglichkeit Cookies zu
löschen, zu deaktivieren oder nur teilweise zuzulassen. Zum Beispiel können Sie
Cookies von Drittanbietern blockieren, aber alle anderen Cookies zulassen.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Wenn Sie feststellen möchten, welche
Cookies in Ihrem Browser gespeichert wurden, wenn Sie Cookie-Einstellungen
ändern oder löschen wollen, können Sie dies in Ihren Browser-Einstellungen
finden:<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-ansi-language:EN-US'><a
href="https://support.google.com/chrome/answer/95647?tid=111870333"
target="_blank"><span lang=DE-AT style='mso-ansi-language:DE-AT'>Chrome:
Cookies in Chrome löschen, aktivieren und verwalten</span></a></span><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman"'> <span lang=DE-AT><o:p></o:p></span></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT><a
href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=111870333"
target="_blank"><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Safari: Verwalten von Cookies und
Websitedaten mit Safari</span></a></span><span lang=DE-AT style='font-size:
12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT><a
href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=111870333"
target="_blank"><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Firefox: Cookies löschen, um Daten
zu entfernen, die Websites auf Ihrem Computer abgelegt haben</span></a></span><span
lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'> <o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-ansi-language:EN-US'><a
href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=111870333"
target="_blank"><span lang=DE-AT style='mso-ansi-language:DE-AT'>Internet
Explorer: Löschen und Verwalten von Cookies</span></a></span><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman"'> <span lang=DE-AT><o:p></o:p></span></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT><a
href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=111870333"
target="_blank"><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Microsoft Edge: Löschen und
Verwalten von Cookies</span></a></span><span lang=DE-AT style='font-size:12.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Falls Sie grundsätzlich keine
Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer
informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem
einzelnen Cookie entscheiden, ob Sie das Cookie erlauben oder nicht. Die
Vorgangsweise ist je nach Browser verschieden. Am besten Sie suchen die
Anleitung in Google mit dem Suchbegriff “Cookies löschen Chrome” oder “Cookies
deaktivieren Chrome” im Falle eines Chrome Browsers.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Rechtsgrundlage<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Seit 2009 gibt es die sogenannten
„Cookie-Richtlinien“. Darin ist festgehalten, dass das Speichern von Cookies
eine <b>Einwilligung</b> (Artikel 6 Abs. 1 lit. a DSGVO) von Ihnen verlangt.
Innerhalb der EU-Länder gibt es allerdings noch sehr unterschiedliche
Reaktionen auf diese Richtlinien. In Österreich erfolgte aber die Umsetzung
dieser Richtlinie in § 96 Abs. 3 des Telekommunikationsgesetzes (TKG). In
Deutschland wurden die Cookie-Richtlinien nicht als nationales Recht umgesetzt.
Stattdessen erfolgte die Umsetzung dieser Richtlinie weitgehend in § 15 Abs.3
des Telemediengesetzes (TMG).<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Für unbedingt notwendige Cookies,
auch soweit keine Einwilligung vorliegt. bestehen <b>berechtigte Interessen</b>
(Artikel 6 Abs. 1 lit. f DSGVO), die in den meisten Fällen wirtschaftlicher
Natur sind. Wir möchten den Besuchern der Website eine angenehme
Benutzererfahrung bescheren und dafür sind bestimmte Cookies oft unbedingt
notwendig.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Soweit nicht unbedingt erforderliche
Cookies zum Einsatz kommen, geschieht dies nur im Falle Ihrer Einwilligung.
Rechtsgrundlage ist insoweit Art. 6 Abs. 1 lit. a DSGVO.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>In den folgenden Abschnitten werden
Sie genauer über den Einsatz von Cookies informiert, sofern eingesetzte
Software Cookies verwendet.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span style='font-size:18.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-ansi-language:EN-US'>Webhosting<o:p></o:p></span></b></p>

<table class=MsoNormalTable border=1 cellpadding=0 style='mso-cellspacing:1.5pt;
 mso-yfti-tbllook:1184;mso-padding-alt:11.25pt 11.25pt 11.25pt 11.25pt'>
 <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes'>
  <td style='padding:11.25pt 11.25pt 11.25pt 11.25pt'>
  <p class=MsoNormal style='margin-bottom:0in;line-height:normal'><b><span
  lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
  mso-fareast-font-family:"Times New Roman"'>Webhosting Zusammenfassung</span></b><span
  lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
  mso-fareast-font-family:"Times New Roman"'> <br>
  </span><span style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;
  mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Segoe UI Emoji";
  mso-ansi-language:EN-US'>&#128101;</span><span lang=DE-AT style='font-size:
  12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
  Betroffene: Besucher der Website<br>
  </span><span style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;
  mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Segoe UI Emoji";
  mso-ansi-language:EN-US'>&#129309;</span><span lang=DE-AT style='font-size:
  12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
  Zweck: professionelles Hosting der Website und Absicherung des Betriebs<br>
  </span><span style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;
  mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Segoe UI Emoji";
  mso-ansi-language:EN-US'>&#128211;</span><span lang=DE-AT style='font-size:
  12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
  Verarbeitete Daten: IP-Adresse, Zeitpunkt des Websitebesuchs, verwendeter
  Browser und weitere Daten. Mehr Details dazu finden Sie weiter unten bzw.
  beim jeweils eingesetzten Webhosting Provider.<br>
  </span><span style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;
  mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Segoe UI Emoji";
  mso-ansi-language:EN-US'>&#128197;</span><span lang=DE-AT style='font-size:
  12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
  Speicherdauer: abhängig vom jeweiligen Provider, aber in der Regel 2 Wochen<br>
  </span><span lang=DE-AT style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;
  mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Segoe UI Emoji"'>&#9878;</span><span
  style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Segoe UI Emoji";mso-ansi-language:
  EN-US'>&#65039;</span><span lang=DE-AT style='font-size:12.0pt;font-family:
  "Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
  Rechtsgrundlagen: Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)<o:p></o:p></span></p>
  </td>
 </tr>
</table>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:white;mso-color-alt:windowtext'>Was ist Webhosting?</span></b><b><span
lang=DE-AT style='font-size:13.5pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'><o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Wenn Sie heutzutage Websites
besuchen, werden gewisse Informationen – auch personenbezogene Daten –
automatisch erstellt und gespeichert, so auch auf dieser Website. Diese Daten
sollten möglichst sparsam und nur mit Begründung verarbeitet werden. Mit
Website meinen wir übrigens die Gesamtheit aller Webseiten auf einer Domain,
d.h. alles von der Startseite (Homepage) bis hin zur aller letzten Unterseite
(wie dieser hier). Mit Domain meinen wir zum Beispiel beispiel.de oder
musterbeispiel.com.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Wenn Sie eine Website auf einem
Bildschirm ansehen möchten, verwenden Sie dafür ein Programm, das sich
Webbrowser nennt. Sie kennen vermutlich einige Webbrowser beim Namen: Google
Chrome, Microsoft Edge, Mozilla Firefox und Apple Safari.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Dieser Webbrowser muss sich zu einem
anderen Computer verbinden, wo der Code der Website gespeichert ist: dem
Webserver. Der Betrieb eines Webservers ist eine komplizierte und aufwendige
Aufgabe, weswegen dies in der Regel von professionellen Anbietern, den
Providern, übernommen wird. Diese bieten Webhosting an und sorgen damit für
eine verlässliche und fehlerfreie Speicherung der Daten von Websites.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Bei der Verbindungsaufnahme des
Browsers auf Ihrem Computer (Desktop, Laptop, Smartphone) und während der
Datenübertragung zu und vom Webserver kann es zu einer Verarbeitung
personenbezogener Daten kommen. Einerseits speichert Ihr Computer Daten,
andererseits muss auch der Webserver Daten eine Zeit lang speichern, um einen
ordentlichen Betrieb zu gewährleisten.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-ansi-language:EN-US'>Zur
Veranschaulichung:<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><!--[if mso & !supportInlineShapes & supportFields]><span
lang=DE-AT><span style='mso-element:field-begin;mso-field-lock:yes'></span><span
style='mso-spacerun:yes'> </span>SHAPE <span
style='mso-spacerun:yes'> </span>\* MERGEFORMAT <span style='mso-element:field-separator'></span></span><![endif]--><span
lang=DE-AT><!--[if gte vml 1]><v:rect id="Rechteck_x0020_1" o:spid="_x0000_s1026"
 alt="Browser und Webserver" style='width:768pt;height:768pt;visibility:visible;
 mso-wrap-style:square;mso-left-percent:-10001;mso-top-percent:-10001;
 mso-position-horizontal:absolute;mso-position-horizontal-relative:char;
 mso-position-vertical:absolute;mso-position-vertical-relative:line;
 mso-left-percent:-10001;mso-top-percent:-10001;v-text-anchor:top' o:gfxdata="UEsDBBQABgAIAAAAIQC2gziS/gAAAOEBAAATAAAAW0NvbnRlbnRfVHlwZXNdLnhtbJSRQU7DMBBF
90jcwfIWJU67QAgl6YK0S0CoHGBkTxKLZGx5TGhvj5O2G0SRWNoz/78nu9wcxkFMGNg6quQqL6RA
0s5Y6ir5vt9lD1JwBDIwOMJKHpHlpr69KfdHjyxSmriSfYz+USnWPY7AufNIadK6MEJMx9ApD/oD
OlTrorhX2lFEilmcO2RdNtjC5xDF9pCuTyYBB5bi6bQ4syoJ3g9WQ0ymaiLzg5KdCXlKLjvcW893
SUOqXwnz5DrgnHtJTxOsQfEKIT7DmDSUCaxw7Rqn8787ZsmRM9e2VmPeBN4uqYvTtW7jvijg9N/y
JsXecLq0q+WD6m8AAAD//wMAUEsDBBQABgAIAAAAIQA4/SH/1gAAAJQBAAALAAAAX3JlbHMvLnJl
bHOkkMFqwzAMhu+DvYPRfXGawxijTi+j0GvpHsDYimMaW0Yy2fr2M4PBMnrbUb/Q94l/f/hMi1qR
JVI2sOt6UJgd+ZiDgffL8ekFlFSbvV0oo4EbChzGx4f9GRdb25HMsYhqlCwG5lrLq9biZkxWOiqY
22YiTra2kYMu1l1tQD30/bPm3wwYN0x18gb45AdQl1tp5j/sFB2T0FQ7R0nTNEV3j6o9feQzro1i
OWA14Fm+Q8a1a8+Bvu/d/dMb2JY5uiPbhG/ktn4cqGU/er3pcvwCAAD//wMAUEsDBBQABgAIAAAA
IQBHTlfs/AEAAOMDAAAOAAAAZHJzL2Uyb0RvYy54bWysU8FuEzEQvSPxD5bvZJOQtnSVTVVaFSEV
qCiIs2PPZq3ueszYySb9esbeJKRwQ1wsz4z3zZv3ZudX264VG6Bg0VVyMhpLAU6jsW5Vye/f7t68
kyJE5Yxq0UEldxDk1eL1q3nvS5hig60BEgziQtn7SjYx+rIogm6gU2GEHhwXa6RORQ5pVRhSPaN3
bTEdj8+LHsl4Qg0hcPZ2KMpFxq9r0PFLXQeIoq0kc4v5pHwu01ks5qpckfKN1Xsa6h9YdMo6bnqE
ulVRiTXZv6A6qwkD1nGksSuwrq2GPANPMxn/Mc1jozzkWVic4I8yhf8Hqz9vHkhYw95J4VTHFn0F
3UTQT4IzBoJmtd4T9oF9WjsjfsCSr2x50q73oWSIR/9Aafrg71E/BeHwplFuBdfBswMD9iFFjNWA
MjzEJEEULzBSEBhNLPtPaJiNWkfMym5r6lIP1kxss4G7o4GwjUJz8vLi7O35mH3WXDsEqYcqD597
CvEDYCfSpZLE/DK82tyHODw9PEndHN7ZtuW8Klv3IsGYKZPpJ8aDGEs0O2ZPOGway8SXBulZip63
rJLh51oRSNF+dKzA5WQ2S2uZg9nZxZQDOq0sTyvKaYaqZJRiuN7EYZXXnuyqyUIPHK9ZtdrmeZKi
A6s9Wd6krMh+69Oqnsb51e9/c/ELAAD//wMAUEsDBBQABgAIAAAAIQDF/7G52gAAAAcBAAAPAAAA
ZHJzL2Rvd25yZXYueG1sTI9BS8NAEIXvgv9hGcGL2I2KRWI2RQpiEaGYas/T7JgEs7NpdpvEf+9U
EL0M83jDm+9li8m1aqA+NJ4NXM0SUMSltw1XBt42j5d3oEJEtth6JgNfFGCRn55kmFo/8isNRayU
hHBI0UAdY5dqHcqaHIaZ74jF+/C9wyiyr7TtcZRw1+rrJJlrhw3Lhxo7WtZUfhYHZ2As18N28/Kk
1xfblef9ar8s3p+NOT+bHu5BRZri3zEc8QUdcmHa+QPboFoDUiT+zKN3ezMXvfvddJ7p//z5NwAA
AP//AwBQSwECLQAUAAYACAAAACEAtoM4kv4AAADhAQAAEwAAAAAAAAAAAAAAAAAAAAAAW0NvbnRl
bnRfVHlwZXNdLnhtbFBLAQItABQABgAIAAAAIQA4/SH/1gAAAJQBAAALAAAAAAAAAAAAAAAAAC8B
AABfcmVscy8ucmVsc1BLAQItABQABgAIAAAAIQBHTlfs/AEAAOMDAAAOAAAAAAAAAAAAAAAAAC4C
AABkcnMvZTJvRG9jLnhtbFBLAQItABQABgAIAAAAIQDF/7G52gAAAAcBAAAPAAAAAAAAAAAAAAAA
AFYEAABkcnMvZG93bnJldi54bWxQSwUGAAAAAAQABADzAAAAXQUAAAAA
" filled="f" stroked="f">
 <o:lock v:ext="edit" aspectratio="t"/>
 <w:wrap type="none"/>
 <w:anchorlock/>
</v:rect><![endif]--></span><!--[if mso & !supportInlineShapes & supportFields]><span
lang=DE-AT><v:shape id="_x0000_i1025" type="#_x0000_t75" style='width:768pt;
 height:768pt'>
 <v:imagedata croptop="-65520f" cropbottom="65520f"/>
</v:shape><span style='mso-element:field-end'></span></span><![endif]--><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-ansi-language:EN-US'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Warum
verarbeiten wir personenbezogene Daten?<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Die Zwecke der Datenverarbeitung
sind:<o:p></o:p></span></p>

<ol start=1 type=1>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l6 level1 lfo8;tab-stops:list .5in'><span
     lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
     mso-fareast-font-family:"Times New Roman"'>Professionelles Hosting der
     Website und Absicherung des Betriebs<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l6 level1 lfo8;tab-stops:list .5in'><span
     lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
     mso-fareast-font-family:"Times New Roman"'>zur Aufrechterhaltung der
     Betriebs- und IT-Sicherheit<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l6 level1 lfo8;tab-stops:list .5in'><span
     lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
     mso-fareast-font-family:"Times New Roman"'>Anonyme Auswertung des
     Zugriffsverhaltens zur Verbesserung unseres Angebots und ggf. zur
     Strafverfolgung bzw. Verfolgung von Ansprüchen<o:p></o:p></span></li>
</ol>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Welche
Daten werden verarbeitet?<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Auch während Sie unsere Website
jetzt gerade besuchen, speichert unser Webserver, das ist der Computer auf dem
diese Webseite gespeichert ist, in der Regel automatisch Daten wie<o:p></o:p></span></p>

<ul type=disc>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l0 level1 lfo9;tab-stops:list .5in'><span
     lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
     mso-fareast-font-family:"Times New Roman"'>die komplette Internetadresse
     (URL) der aufgerufenen Webseite (z. B.
     https://www.beispielwebsite.de/beispielunterseite.html?tid=111870333)<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l0 level1 lfo9;tab-stops:list .5in'><span
     style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
     "Times New Roman";mso-ansi-language:EN-US'>Browser und Browserversion (z.
     B. Chrome 87)<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l0 level1 lfo9;tab-stops:list .5in'><span
     lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
     mso-fareast-font-family:"Times New Roman"'>das verwendete Betriebssystem
     (z. B. Windows 10)<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l0 level1 lfo9;tab-stops:list .5in'><span
     lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
     mso-fareast-font-family:"Times New Roman"'>die Adresse (URL) der zuvor
     besuchten Seite (Referrer URL) (z. B.
     https://www.beispielquellsite.de/vondabinichgekommen.html/)<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l0 level1 lfo9;tab-stops:list .5in'><span
     lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
     mso-fareast-font-family:"Times New Roman"'>den Hostnamen und die
     IP-Adresse des Geräts von welchem aus zugegriffen wird (z. B. COMPUTERNAME
     und 194.23.43.121)<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l0 level1 lfo9;tab-stops:list .5in'><span
     style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
     "Times New Roman";mso-ansi-language:EN-US'>Datum und Uhrzeit<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l0 level1 lfo9;tab-stops:list .5in'><span
     lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
     mso-fareast-font-family:"Times New Roman"'>in Dateien, den sogenannten
     Webserver-Logfiles<o:p></o:p></span></li>
</ul>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Wie
lange werden Daten gespeichert?<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>In der Regel werden die oben
genannten Daten zwei Wochen gespeichert und danach automatisch gelöscht. Wir
geben diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese
Daten beim Vorliegen von rechtswidrigem Verhalten von Behörden eingesehen
werden.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span lang=DE-AT style='font-size:12.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Kurz gesagt:</span></b><span
lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'> Ihr Besuch wird durch unseren
Provider (Firma, die unsere Website auf speziellen Computern (Servern) laufen
lässt), protokolliert, aber wir geben Ihre Daten nicht ohne Zustimmung weiter!<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Rechtsgrundlage<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Die Rechtmäßigkeit der Verarbeitung
personenbezogener Daten im Rahmen des Webhosting ergibt sich aus Art. 6 Abs. 1
lit. f DSGVO (Wahrung der berechtigten Interessen), denn die Nutzung von
professionellem Hosting bei einem Provider ist notwendig, um das Unternehmen im
Internet sicher und nutzerfreundlich präsentieren und Angriffe und Forderungen
hieraus gegebenenfalls verfolgen zu können.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Zwischen uns und dem Hostingprovider
besteht in der Regel ein Vertrag über die Auftragsverarbeitung gemäß Art. 28 f.
DSGVO, der die Einhaltung von Datenschutz gewährleistet und Datensicherheit
garantiert.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span style='font-size:18.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-ansi-language:EN-US'>E-Mail-Marketing<o:p></o:p></span></b></p>

<table class=MsoNormalTable border=1 cellpadding=0 style='mso-cellspacing:1.5pt;
 mso-yfti-tbllook:1184;mso-padding-alt:11.25pt 11.25pt 11.25pt 11.25pt'>
 <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes'>
  <td style='padding:11.25pt 11.25pt 11.25pt 11.25pt'>
  <p class=MsoNormal style='margin-bottom:0in;line-height:normal'><b><span
  lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
  mso-fareast-font-family:"Times New Roman"'>E-Mail-Marketing Zusammenfassung</span></b><span
  lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
  mso-fareast-font-family:"Times New Roman"'> <br>
  </span><span style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;
  mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Segoe UI Emoji";
  mso-ansi-language:EN-US'>&#128101;</span><span lang=DE-AT style='font-size:
  12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
  Betroffene: Newsletter-Abonnenten<br>
  </span><span style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;
  mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Segoe UI Emoji";
  mso-ansi-language:EN-US'>&#129309;</span><span lang=DE-AT style='font-size:
  12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
  Zweck: Direktwerbung per E-Mail, Benachrichtigung über systemrelevante
  Ereignisse<br>
  </span><span style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;
  mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Segoe UI Emoji";
  mso-ansi-language:EN-US'>&#128211;</span><span lang=DE-AT style='font-size:
  12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
  Verarbeitete Daten: Eingegebene Daten bei der Registrierung jedoch mindestens
  die E-Mail-Adresse. Mehr Details dazu finden Sie beim jeweils eingesetzten
  E-Mail-Marketing-Tool.<br>
  </span><span style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;
  mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Segoe UI Emoji";
  mso-ansi-language:EN-US'>&#128197;</span><span lang=DE-AT style='font-size:
  12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
  Speicherdauer: Dauer des Bestehens des Abonnements<br>
  </span><span lang=DE-AT style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;
  mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Segoe UI Emoji"'>&#9878;</span><span
  style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Segoe UI Emoji";mso-ansi-language:
  EN-US'>&#65039;</span><span lang=DE-AT style='font-size:12.0pt;font-family:
  "Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
  Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1
  lit. f DSGVO (berechtigte Interessen)<o:p></o:p></span></p>
  </td>
 </tr>
</table>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:white;mso-color-alt:windowtext'>Was ist E-Mail-Marketing?</span></b><b><span
lang=DE-AT style='font-size:13.5pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'><o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Um Sie stets auf dem Laufenden zu
halten, nutzen wir auch die Möglichkeit des E-Mail-Marketings. Dabei werden,
sofern Sie dem Empfang unserer E-Mails bzw. Newsletter zugestimmt haben, auch
Daten von Ihnen verarbeitet und gespeichert. E-Mail-Marketing ist ein
Teilbereich des Online-Marketings. Dabei werden Neuigkeiten oder allgemeine
Informationen über ein Unternehmen, Produkte oder Dienstleistungen per E-Mail
an eine bestimmte Gruppe an Menschen, die sich dafür interessieren, gesendet.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Wenn Sie an unserem E-Mail-Marketing
(meist per Newsletter) teilnehmen wollen, müssen Sie sich im Normalfall einfach
nur mit Ihrer E-Mail-Adresse anmelden. Dafür füllen Sie ein Online-Formular aus
und senden es ab. Es kann aber auch vorkommen, dass wir Sie etwa um Ihre Anrede
und Ihren Namen bitten, damit wir Sie auch persönlich anschreiben können.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Grundsätzlich funktioniert das
Anmelden zu Newslettern mit Hilfe des sogenannten „Double-Opt-In-Verfahrens“.
Nachdem Sie sich für unseren Newsletter auf unserer Website angemeldet haben,
bekommen Sie eine E-Mail, über die Sie die Newsletter-Anmeldung bestätigen. So
wird sichergestellt, dass Ihnen die E-Mail-Adresse gehört und sich niemand mit
einer fremden E-Mail-Adresse angemeldet hat. Wir oder ein von uns verwendetes
Benachrichtigungs-Tool protokolliert jede einzelne Anmeldung. Dies ist nötig,
damit wir den rechtlich korrekten Anmeldevorgang auch nachweisen können. Dabei
wird in der Regel der Zeitpunkt der Anmeldung, der Zeitpunkt der
Anmeldebestätigung und Ihre IP-Adresse gespeichert. Zusätzlich wird auch
protokolliert, wenn Sie Änderungen Ihrer gespeicherten Daten vornehmen.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Warum
nutzen wir E-Mail-Marketing?<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Wir wollen natürlich mit Ihnen in
Kontakt bleiben und Ihnen stets die wichtigsten Neuigkeiten über unser
Unternehmen präsentieren. Dafür nutzen wir unter anderem E-Mail-Marketing – oft
auch nur “Newsletter” bezeichnet – als wesentlichen Bestandteil unseres
Online-Marketings. Sofern Sie sich damit einverstanden erklären oder es
gesetzlich erlaubt ist, schicken wir Ihnen Newsletter, System-E-Mails oder
andere Benachrichtigungen per E-Mail. Wenn wir im folgenden Text den Begriff
„Newsletter“ verwenden, meinen wir damit hauptsächlich regelmäßig versandte
E-Mails. Natürlich wollen wir Sie mit unseren Newsletter in keiner Weise
belästigen. Darum sind wir wirklich stets bemüht, nur relevante und
interessante Inhalte zu bieten. So erfahren Sie etwa mehr über unser Unternehmen,
unsere Leistungen oder Produkte. Da wir unsere Angebote auch immer verbessern,
erfahren Sie über unseren Newsletter auch immer, wenn es Neuigkeiten gibt oder
wir gerade spezielle, lukrative Aktionen anbieten. Sofern wir einen
Dienstleister, der ein professionelles Versand-Tool anbietet, für unser
E-Mail-Marketing beauftragen, machen wir das, um Ihnen schnelle und sichere
Newsletter bieten zu können. Zweck unseres E-Mail-Marketings ist grundsätzlich,
Sie über neue Angebote zu informieren und auch unseren unternehmerischen Zielen
näher zu kommen.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Welche
Daten werden verarbeitet?<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Wenn Sie über unsere Website
Abonnent unseres Newsletters werden, bestätigen Sie per E-Mail die
Mitgliedschaft in einer E-Mail-Liste. Neben IP-Adresse und E-Mail-Adresse können
auch Ihre Anrede, Ihr Name, Ihre Adresse und Ihre Telefonnummer gespeichert
werden. Allerdings nur, wenn Sie dieser Datenspeicherungen zustimmen. Die als
solche markierten Daten sind notwendig, damit Sie an dem angebotenen Dienst
teilnehmen können. Die Angabe ist freiwillig, die Nichtangabe führt jedoch
dazu, dass Sie den Dienst nicht nutzen können. Zusätzlich können etwa auch
Informationen zu Ihrem Gerät oder zu Ihren bevorzugten Inhalten auf unserer
Website gespeichert werden. Mehr zur Speicherung von Daten, wenn Sie eine
Website besuchen, finden Sie im Abschnitt “Automatische Datenspeicherung”. Ihre
Einwilligungserklärung zeichnen wir auf, damit wir stets nachweisen können,
dass dieser unseren Gesetzen entspricht.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Dauer
der Datenverarbeitung<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Wenn Sie Ihre E-Mail-Adresse aus
unserem E-Mail/Newsletter-Verteiler austragen, dürfen wir Ihre Adresse bis zu
drei Jahren auf Grundlage unserer berechtigten Interessen speichern, damit wir
Ihre damalige Einwilligung noch nachweisen können. Verarbeiten dürfen wir diese
Daten nur, wenn wir uns gegen etwaige Ansprüche wehren müssen.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Wenn Sie allerdings bestätigen, dass
Sie uns die Einwilligung zur Newsletter-Anmeldung gegeben haben, können Sie
jederzeit einen individuellen Löschantrag stellen. Widersprechen Sie der Einwilligung
dauerhaft, behalten wir uns das Recht vor, Ihre E-Mail-Adresse in einer
Sperrliste zu speichern. Solange Sie freiwillig unseren Newsletter abonniert
haben, solange behalten wir selbstverständlich auch Ihre E-Mail-Adresse.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Widerspruchsrecht<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Sie haben jederzeit die Möglichkeit
Ihre Newsletter-Anmeldung zu kündigen. Dafür müssen Sie lediglich Ihre
Einwilligung zur Newsletter-Anmeldung widerrufen. Das dauert im Normalfall nur
wenige Sekunden bzw. einen oder zwei Klicks. Meistens finden Sie direkt am Ende
jeder E-Mail einen Link, um das Newsletter-Abonnement zu kündigen. Wenn der
Link im Newsletter wirklich nicht zu finden ist, kontaktieren Sie uns bitte per
Mail und wir werden Ihr Newsletter-Abo unverzüglich kündigen.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Rechtsgrundlage<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Das Versenden unseres Newsletters
erfolgt auf Grundlage Ihrer <b>Einwilligung</b> (Artikel 6 Abs. 1 lit. a
DSGVO). Das heißt, wir dürfen Ihnen nur dann einen Newsletter schicken, wenn
Sie sich zuvor aktiv dafür angemeldet haben. Gegebenenfalls können wir Ihnen
auch Werbenachrichten auf Grundlage des § 7 Abs. 3 UWG senden, sofern Sie unser
Kunde geworden sind und der Verwendung ihrer E-Mailadresse für Direktwerbung
nicht widersprochen haben.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Informationen zu speziellen
E-Mail-Marketing Diensten und wie diese personenbezogene Daten verarbeiten,
erfahren Sie – sofern vorhanden – in den folgenden Abschnitten.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span style='font-size:18.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-ansi-language:EN-US'>Push-Nachrichten<o:p></o:p></span></b></p>

<table class=MsoNormalTable border=1 cellpadding=0 style='mso-cellspacing:1.5pt;
 mso-yfti-tbllook:1184;mso-padding-alt:11.25pt 11.25pt 11.25pt 11.25pt'>
 <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes'>
  <td style='padding:11.25pt 11.25pt 11.25pt 11.25pt'>
  <p class=MsoNormal style='margin-bottom:0in;line-height:normal'><b><span
  lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
  mso-fareast-font-family:"Times New Roman"'>Push-Nachrichten Zusammenfassung</span></b><span
  lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
  mso-fareast-font-family:"Times New Roman"'> <br>
  </span><span style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;
  mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Segoe UI Emoji";
  mso-ansi-language:EN-US'>&#128101;</span><span lang=DE-AT style='font-size:
  12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
  Betroffene: Push-Nachrichten Abonnenten<br>
  </span><span style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;
  mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Segoe UI Emoji";
  mso-ansi-language:EN-US'>&#129309;</span><span lang=DE-AT style='font-size:
  12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
  Zweck: Benachrichtigung über systemrelevante und interessante Ereignisse<br>
  </span><span style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;
  mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Segoe UI Emoji";
  mso-ansi-language:EN-US'>&#128211;</span><span lang=DE-AT style='font-size:
  12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
  Verarbeitete Daten: Eingegebene Daten bei der Registrierung, meistens auch
  Standortdaten. Mehr Details dazu finden Sie beim jeweils eingesetzten
  Push-Nachrichten-Tool.<br>
  </span><span style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;
  mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Segoe UI Emoji";
  mso-ansi-language:EN-US'>&#128197;</span><span lang=DE-AT style='font-size:
  12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
  Speicherdauer: Daten meist so lange gespeichert, wie es für die
  Bereitstellung der Dienste nötig ist.<br>
  </span><span lang=DE-AT style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;
  mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Segoe UI Emoji"'>&#9878;</span><span
  style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Segoe UI Emoji";mso-ansi-language:
  EN-US'>&#65039;</span><span lang=DE-AT style='font-size:12.0pt;font-family:
  "Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
  Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1
  lit. b DSGVO (Vertrag)<o:p></o:p></span></p>
  </td>
 </tr>
</table>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:white;mso-color-alt:windowtext'>Was sind Push-Nachrichten?</span></b><b><span
lang=DE-AT style='font-size:13.5pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'><o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Wir nutzen auf unserer Website auch
sogenannte Push-Benachrichtigungsdienste, mit denen wir unsere User stets auf
dem Laufenden halten können. Das bedeutet, wenn Sie der Nutzung von solchen
Push-Nachrichten zugestimmt haben, können wir Ihnen mit Hilfe eines
Softwaretools kurze News schicken. Push-Nachrichten sind eine
Textnachrichten-Form, die bei Ihnen direkt auf dem Smartphone oder auf anderen
Geräten wie etwa Tablets oder PCs erscheinen, wenn Sie sich dafür angemeldet
haben. Sie bekommen diese Nachrichten auch, wenn Sie sich nicht auf unserer
Website befinden bzw. unser Angebot nicht aktiv nutzen. Dabei können auch Daten
über Ihren Standort und Ihr Nutzungsverhalten gesammelt und gespeichert werden.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Warum
verwenden wir Push-Nachrichten?<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Einerseits nutzen wir
Push-Nachrichten, um unsere Leistungen, die wir mit Ihnen vertraglich
vereinbart haben, auch vollständig erbringen zu können. Andererseits dienen die
Nachrichten auch unserem Online-Marketing. Wir können Ihnen mit Hilfe dieser
Nachrichten unser Service oder unsere Produkte näherbringen. Besonders wenn es
Neuigkeiten in unserem Unternehmen gibt, können wir Sie darüber sofort
informieren. Wir wollen die Vorlieben und die Gewohnheiten all unserer User so
gut wie möglich kennenlernen, um unser Angebot laufend zu verbessern.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Welche
Daten werden verarbeitet?<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Damit Sie Push-Nachrichten bekommen
können, müssen Sie auch bestätigen, dass Sie diese Nachrichten erhalten wollen.
Die Daten, die während des Prozesses der Einwilligung angesammelt werden,
werden auch gespeichert, verwaltet und verarbeitet. Das ist notwendig, damit
bewiesen und erkannt werden kann, dass ein User dem Empfangen der
Push-Nachrichten zugestimmt hat. Dafür wird ein sogenannter Device-Token oder
Push-Token in Ihrem Browser gespeichert. Für gewöhnlich werden auch die Daten
Ihres Standorts bzw. des Standorts Ihres verwendeten Endgeräts gespeichert.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Damit wir auch stets interessante
und wichtige Push-Nachrichten versenden, wird der Umgang mit den Nachrichten
auch statistisch ausgewertet. So sehen wir dann zum Beispiel, ob und wann Sie
die Nachricht öffnen. Mit Hilfe dieser Erkenntnisse können wir unsere
Kommunikationsstrategie an Ihre Wünsche und Interessen anpassen. Obwohl diese
gespeicherten Daten Ihnen zugeordnet werden können, wollen wir Sie als
Einzelperson nicht überprüfen. Vielmehr interessieren uns die gesammelten Daten
all unserer User, damit wir Optimierungen vornehmen können. Welche Daten genau
gespeichert werden, erfahren Sie in den Datenschutzerklärungen der jeweiligen
Dienstanbieter.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Dauer
der Datenverarbeitung<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Wie lange die Daten verarbeitet und
gespeichert werden, hängt in erster Linie von unserem verwendeten Tool ab.
Weiter unten erfahren Sie mehr über die Datenverarbeitung der einzelnen Tools.
In den Datenschutzerklärungen der Anbieter steht üblicherweise genau, welche
Daten wie lange gespeichert und verarbeitet werden. Grundsätzlich werden
personenbezogene Daten nur so lange verarbeitet, wie es für die Bereitstellung
unserer Dienste nötig ist. Wenn Daten in Cookies gespeichert werden, variiert
die Speicherdauer stark. Die Daten können gleich nach dem Verlassen einer
Website wieder gelöscht werden, sie können aber auch über mehrere Jahre
gespeichert bleiben. Daher sollten Sie sich jedes einzelnen Cookie im Detail
ansehen, wenn Sie über die Datenspeicherung Genaueres wissen wollen. Meistens
finden Sie in den Datenschutzerklärungen der einzelnen Anbieter auch
aufschlussreiche Informationen über die einzelnen Cookies.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Rechtsgrundlage<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Es kann auch sein, dass die
Push-Nachrichten notwendig sind, damit gewisse Pflichten, die in einem Vertrag
stehen, erfüllt werden können. Zum Beispiel, damit wir Ihnen technische oder
organisatorische Neuigkeiten mitteilen können. Dann lautet die Rechtsgrundlage
Art. 6 Abs. 1 lit. b DSGVO.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Wenn dies nicht der Fall ist, werden
die Push-Nachrichten nur auf Grundlage Ihrer Einwilligung verschickt. Unsere
Push-Nachrichten können insbesondere einen werbenden Inhalt haben. Die
Push-Nachrichten können auch abhängig von Ihrem Standort, die Ihr Endgerät
anzeigt, versendet werden. Auch die oben genannten analytischen Auswertungen
basieren auf Grundlage Ihrer Einwilligung, solche Nachrichten empfangen zu
wollen. Rechtsgrundlage ist insoweit Art. 6 Abs. 1 lit. a DSGVO. Sie können
natürlich in den Einstellungen Ihre Einwilligung jederzeit widerrufen bzw.
diverse Einstellungen ändern.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span style='font-size:18.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-ansi-language:EN-US'>Zahlungsanbieter<o:p></o:p></span></b></p>

<table class=MsoNormalTable border=1 cellpadding=0 style='mso-cellspacing:1.5pt;
 mso-yfti-tbllook:1184;mso-padding-alt:11.25pt 11.25pt 11.25pt 11.25pt'>
 <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes'>
  <td style='padding:11.25pt 11.25pt 11.25pt 11.25pt'>
  <p class=MsoNormal style='margin-bottom:0in;line-height:normal'><b><span
  lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
  mso-fareast-font-family:"Times New Roman"'>Zahlungsanbieter
  Datenschutzerklärung Zusammenfassung</span></b><span lang=DE-AT
  style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
  "Times New Roman"'> <br>
  </span><span style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;
  mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Segoe UI Emoji";
  mso-ansi-language:EN-US'>&#128101;</span><span lang=DE-AT style='font-size:
  12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
  Betroffene: Besucher der Website<br>
  </span><span style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;
  mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Segoe UI Emoji";
  mso-ansi-language:EN-US'>&#129309;</span><span lang=DE-AT style='font-size:
  12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
  Zweck: Ermöglichung und Optimierung des Zahlungsvorgangs auf unserer Website<br>
  </span><span style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;
  mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Segoe UI Emoji";
  mso-ansi-language:EN-US'>&#128211;</span><span lang=DE-AT style='font-size:
  12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
  Verarbeitete Daten: Daten wie etwa Name, Adresse, Bankdaten (Kontonummer,
  Kreditkartennummer, Passwörter, TANs usw.), IP-Adresse und Vertragsdaten<br>
  Mehr Details dazu finden Sie beim jeweils eingesetzten Zahlungsanbieter-Tool.<br>
  </span><span style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;
  mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Segoe UI Emoji";
  mso-ansi-language:EN-US'>&#128197;</span><span lang=DE-AT style='font-size:
  12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
  Speicherdauer: abhängig vom verwendeten Zahlungsanbieter<br>
  </span><span lang=DE-AT style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;
  mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Segoe UI Emoji"'>&#9878;</span><span
  style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Segoe UI Emoji";mso-ansi-language:
  EN-US'>&#65039;</span><span lang=DE-AT style='font-size:12.0pt;font-family:
  "Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
  Rechtsgrundlagen: Art. 6 Abs. 1 lit. b DSGVO (Erfüllung eines Vertrags)<o:p></o:p></span></p>
  </td>
 </tr>
</table>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:white;mso-color-alt:windowtext'>Was ist ein Zahlungsanbieter?</span></b><b><span
lang=DE-AT style='font-size:13.5pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'><o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Wir verwenden auf unserer Website
Online-Zahlungssysteme, die uns und Ihnen ein sicheres und reibungsloses
Bezahlverfahren ermöglichen. Dabei können unter anderem auch personenbezogene
Daten an den jeweiligen Zahlungsanbieter gesendet, gespeichert und dort
verarbeitet werden. Bei Zahlungsanbietern handelt es sich um
Online-Zahlungssysteme, die es Ihnen ermöglichen eine Bestellung über
Online-Banking durchzuführen. Dabei wird die Zahlungsabwicklung durch den von
Ihnen gewählten Zahlungsanbieter durchgeführt. Wir erhalten anschließend eine
Information über die getätigte Zahlung. Diese Methode kann jeder User nutzen,
der ein aktives Online-Banking-Konto mit PIN und TAN hat. Es gibt kaum noch
Banken, die solche Zahlungsmethoden nicht anbieten bzw. akzeptieren.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Warum
verwenden wir Zahlungsanbieter auf unserer Website?<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Wir wollen natürlich mit unserer
Website und unserem eingebundenen Onlineshop das bestmögliche Service bietet,
damit Sie sich auf unserer Seite wohl fühlen und unsere Angebote nutzen. Wir
wissen, dass Ihre Zeit kostbar ist und speziell Zahlungsabwicklungen schnell
und reibungslos funktionieren müssen. Aus diesen Gründen bieten wir Ihnen
diverse Zahlungsanbieter an. Sie können Ihren bevorzugten Zahlungsanbieter
wählen und so in gewohnter Manier bezahlen.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Welche
Daten werden verarbeitet?<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Welche Daten genau verarbeitet
werden, hängt natürlich von dem jeweiligen Zahlungsanbieter ab. Doch
grundsätzlich werden Daten wie Name, Adresse, Bankdaten (Kontonummer,
Kreditkartennummer, Passwörter, TANs usw.) gespeichert. Dabei handelt es sich
um notwendige Daten, um überhaupt eine Transaktion durchführen zu können. Zudem
können auch etwaige Vertragsdaten und Userdaten, wie zum Beispiel wann Sie
unsere Website besuchen, für welche Inhalte Sie sich interessieren oder welche
Unterseiten Sie anklicken, gespeichert werden. Auch Ihre IP-Adresse und
Informationen zu Ihrem verwendeten Computer werden von den meisten
Zahlungsanbietern gespeichert.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Die Daten werden in der Regel auf
den Servern der Zahlungsanbietern gespeichert und verarbeitet. Wir als
Websitebetreiber erhalten diese Daten nicht. Wir werden nur darüber informiert,
ob die Zahlung funktioniert hat oder nicht. Für Identitäts- und
Bonitätsprüfungen kann es vorkommen, dass Zahlungsanbieter Daten an die
entsprechende Stelle weiterleiten. Für alle Zahlungsgeschäfte gelten immer die
Geschäfts- und Datenschutzgrundlagen des jeweiligen Anbieters. Schauen Sie sich
daher bitte immer auch die Allgemeinen Geschäftsbedingen und die
Datenschutzerklärung des Zahlungsanbieters an. Sie haben auch jederzeit das
Recht beispielsweise Daten löschen oder korrigieren zu lassen. Bitte setzen Sie
sich bezüglich Ihrer Rechte (Widerrufsrecht, Auskunftsrecht und
Betroffenheitsrecht) mit dem jeweiligen Dienstanbieter in Verbindung.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Dauer
der Datenverarbeitung <o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Über die Dauer der Datenverarbeitung
informieren wir Sie weiter unten sofern wir weitere Informationen dazu haben.
Generell verarbeiten wir personenbezogene Daten nur so lange wie es für die
Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist.
Wenn es wie zum Beispiel im Fall von Buchhaltung gesetzlich vorgeschrieben ist,
kann diese Speicherdauer auch überschritten werden. So bewahren wir zu einem
Vertrag gehörige Buchungsbelege (Rechnungen, Vertragsurkunden, Kontoauszüge
u.a.) 10 Jahre (§ 147 AO) sowie sonstige relevante Geschäftsunterlagen 6 Jahre
(§ 247 HGB) nach Anfallen auf.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Widerspruchsrecht<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Sie haben immer das Recht auf
Auskunft, Berichtigung und Löschung Ihrer personenbezogenen Daten. Bei Fragen
können Sie auch jederzeit Verantwortliche des verwendeten Zahlungsanbieter
kontaktieren. Kontaktdaten finden Sie entweder in unserer spezifischen
Datenschutzerklärung oder auf der Website des entsprechenden Zahlungsanbieters.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Cookies, die Zahlungsanbieter für
ihre Funktionen verwenden, können Sie in Ihrem Browser löschen, deaktivieren
oder verwalten. Je nachdem welchen Browser Sie verwenden, funktioniert dies auf
unterschiedliche Art und Weise. Bitte beachten Sie aber, dass dann eventuell
der Zahlungsvorgang nicht mehr funktioniert.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Rechtsgrundlage<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Wir bieten also zur Abwicklung von
vertraglichen bzw. rechtlichen Beziehungen <b>(Art. 6 Abs. 1 lit. b DSGVO)</b> <b>&nbsp;</b>neben
den herkömmlichen Bank-/Kreditinstitutionen auch andere Zahlungsdienstleister
an. In den Datenschutzerklärungen der einzelnen Zahlungsanbietern (wie zum
Beispiel Amazon Payments, Apple Pay oder Discover) wird Ihnen ein genauer
Überblick über die Datenverarbeitung und Datenspeicherung geboten. Zudem können
Sie sich bei Fragen zu datenschutzrelevanten Themen stets an die Verantwortlichen
richten.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Informationen zu den speziellen
Zahlungsanbietern erfahren Sie – sofern vorhanden – in den folgenden
Abschnitten.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span style='font-size:18.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-ansi-language:EN-US'>Stripe Datenschutzerklärung<o:p></o:p></span></b></p>

<table class=MsoNormalTable border=1 cellpadding=0 style='mso-cellspacing:1.5pt;
 mso-yfti-tbllook:1184;mso-padding-alt:11.25pt 11.25pt 11.25pt 11.25pt'>
 <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes'>
  <td style='padding:11.25pt 11.25pt 11.25pt 11.25pt'>
  <p class=MsoNormal style='margin-bottom:0in;line-height:normal'><b><span
  lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
  mso-fareast-font-family:"Times New Roman"'>Stripe Datenschutzerklärung
  Zusammenfassung</span></b><span lang=DE-AT style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
  <br>
  </span><span style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;
  mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Segoe UI Emoji";
  mso-ansi-language:EN-US'>&#128101;</span><span lang=DE-AT style='font-size:
  12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
  Betroffene: Besucher der Website<br>
  </span><span style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;
  mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Segoe UI Emoji";
  mso-ansi-language:EN-US'>&#129309;</span><span lang=DE-AT style='font-size:
  12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
  Zweck: Optimierung des Zahlungsvorgangs auf unserer Website<br>
  </span><span style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;
  mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Segoe UI Emoji";
  mso-ansi-language:EN-US'>&#128211;</span><span lang=DE-AT style='font-size:
  12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
  Verarbeitete Daten: Daten wie etwa Name, Adresse, Bankdaten (Kontonummer,
  Kreditkartennummer, Passwörter, TANs usw.), IP-Adresse und Vertragsdaten<br>
  Mehr Details dazu finden Sie weiter unter in dieser Datenschutzerklärung<br>
  </span><span style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;
  mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Segoe UI Emoji";
  mso-ansi-language:EN-US'>&#128197;</span><span lang=DE-AT style='font-size:
  12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
  Speicherdauer: Daten werden gespeichert, bis die Zusammenarbeit mit Stripe
  aufgelöst wird<br>
  </span><span lang=DE-AT style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;
  mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Segoe UI Emoji"'>&#9878;</span><span
  style='font-size:12.0pt;font-family:"Segoe UI Emoji",sans-serif;mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Segoe UI Emoji";mso-ansi-language:
  EN-US'>&#65039;</span><span lang=DE-AT style='font-size:12.0pt;font-family:
  "Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
  Rechtsgrundlagen: Art. 6 Abs. 1 lit. b DSGVO (Vertragsabwicklung), Art. 6
  Abs. 1 lit. a DSGVO (Einwilligung)<o:p></o:p></span></p>
  </td>
 </tr>
</table>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:white;mso-color-alt:windowtext'>Was ist Stripe?</span></b><b><span
lang=DE-AT style='font-size:13.5pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'><o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Wir verwenden auf unserer Website
ein Zahlungstool des amerikanischen Technologieunternehmens und
Online-Bezahldienstes Stripe. Für Kunden innerhalb von der EU ist Stripe
Payments Europe (Europe Ltd., 1 Grand Canal Street Lower, Grand Canal Dock,
Dublin, Irland) verantwortlich. Das heißt, wenn Sie sich für Stripe als
Zahlungsart entscheiden, wird Ihre Zahlung über Stripe Payments abgewickelt.
Dabei werden Daten, die für den Zahlungsvorgang nötig sind, an Stripe
weitergeleitet und gespeichert. In dieser Datenschutzerklärung geben wir Ihnen
einen Überblick über diese Datenverarbeitung und Speicherung durch Stripe und
erklären, warum wir Stripe auf unserer Website verwenden.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Das Technologieunternehmen Stripe
bietet Zahlungslösungen für Online-Bezahlungen an. Mit Stripe ist es möglich
Kredit- und Debitkartenzahlungen in unserem Webshop zu akzeptieren. Stripe
übernimmt den gesamten Zahlungsvorgang. Ein großer Vorteil von Stripe ist etwa,
dass Sie während des Zahlungsvorgangs nie unsere Website bzw. den Shop verlassen
müssen und die Zahlungsabwicklung sehr schnell erfolgt.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Warum
verwenden wir Stripe für unsere Website?<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Wir wollen natürlich mit unserer
Website und unserem eingebundenen Onlineshop das bestmögliche Service bietet,
damit Sie sich auf unserer Seite wohl fühlen und unsere Angebote nutzen. Wir
wissen, dass Ihre Zeit kostbar ist und daher speziell Zahlungsabwicklungen
schnell und reibungslos funktionieren müssen. Neben unseren anderen
Zahlungsanbietern haben wir mit Stripe einen Partner gefunden, der eine sichere
und schnelle Zahlungsabwicklung gewährleistet.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Welche
Daten werden von Stripe gespeichert?<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Wenn Sie sich für Stripe als
Zahlungsart entscheiden, werden auch personenbezogene Daten von Ihnen an Stripe
übermittelt und dort gespeichert. Dabei handelt es sich um Transaktionsdaten.
Zu diesen Daten zählen etwa die Zahlungsmethode (also Kreditkarten- Debitkarten
oder Kontonummer), Bankleitzahl, Währung, der Betrag und das Datum der Zahlung.
Bei einer Transaktion kann weiters Ihr Name, Ihre E-Mail-Adresse, Ihre
Rechnungs- oder Versandadresse und manchmal auch Ihr Transaktionsverlauf
übermittelt werden. Diese Daten sind zur Authentifizierung nötig. Weiters kann
Stripe zur Betrugsabwehr, der Finanzberichterstattung und um die eigenen
Dienste vollständig anbieten zu können, auch neben technischen Daten zu Ihrem
Gerät (wie IP-Adresse)&nbsp; Name, Adresse, Telefonnummer und Ihr Land
erfassen.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Stripe verkauft keine Ihrer Daten an
unabhängige Dritte, wie etwa Marketingagenturen oder andere Unternehmen, die
mit dem Stripe-Unternehmen nichts zu tun haben. Die Daten können aber etwa an
interne Abteilungen, einer beschränkten Anzahl externer Stripe-Partner oder zur
Einhaltung gesetzlicher Vorschriften weitergeleitet werden. Stripe verwendet
zur Erfassung von Daten auch Cookies. Hier finden Sie eine Auswahl an Cookies,
die Stripe während des Zahlungsprozesses setzen kann:<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span lang=DE-AT style='font-size:12.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Name:</span></b><span
lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'> m<br>
<b>Wert:</b> edd716e9-d28b-46f7-8a55-e05f1779e84e040456111870333-5<br>
<b>Verwendungszweck:</b> Dieses Cookie erscheint, wenn Sie die Zahlungsmethode
auswählen. Es speichert und erkennt, ob Sie über einen PC, ein Tablet oder ein
Smartphone auf unsere Website zugreifen.<br>
<b>Ablaufdatum:</b> nach 2 Jahren<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span lang=DE-AT style='font-size:12.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Name:</span></b><span
lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'> __stripe_mid<br>
<b>Wert:</b> fc30f52c-b006-4722-af61-a7419a5b8819875de9111870333-1<br>
<b>Verwendungszweck:</b> Um eine Kreditkartentransaktion durchführen zu können,
wird dieses Cookie benötigt. Dazu speichert das Cookie Ihre Sitzungs-ID.<br>
<b>Ablaufdatum:</b> nach einem Jahr<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span lang=DE-AT style='font-size:12.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Name:</span></b><span
lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'> __stripe_sid<br>
<b>Wert:</b> 6fee719a-c67c-4ed2-b583-6a9a50895b122753fe<br>
<b>Verwendungszweck:</b> Auch dieses Cookie speichert Ihre ID und wird für den
Zahlungsprozess auf unserer Website durch Stripe verwendet.<br>
<b>Ablaufdatum</b>: nach Ablauf der Sitzung<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Wie
lange und wo werden die Daten gespeichert?<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Personenbezogene Daten werden
grundsätzlich für die Dauer der Diensterbringung gespeichert. Das heißt, die
Daten werden so lange gespeichert, bis wir die Zusammenarbeit mit Stripe
auflösen. Um allerdings die gesetzlichen und behördlichen Pflichten zu erfüllen
kann Stripe auch über die Dauer der Diensterbringung personenbezogene Daten
speichern. Da Stripe ein weltweit tätiges Unternehmen ist, können die Daten
auch in jedem Land, wo Stripe Dienstleistungen anbietet, gespeichert werden. So
können auch Daten außerhalb Ihres Landes, zum Beispiel in den USA gespeichert
werden.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Wie
kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Bitte beachten Sie, dass bei der
Verwendung dieses Tools Daten von Ihnen auch außerhalb der EU gespeichert und
verarbeitet werden können. Die meisten Drittstaaten (darunter auch die USA)
gelten nach derzeitigem europäischen Datenschutzrecht als nicht sicher. Daten
an unsichere Drittstaaten dürfen also nicht einfach übertragen, dort
gespeichert und verarbeitet werden, sofern es keine passenden Garantien (wie
etwa EU-Standardvertragsklauseln) zwischen uns und dem außereuropäischen
Dienstleister gibt.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Sie haben immer das Recht auf
Auskunft, Berichtigung und Löschung Ihrer personenbezogenen Daten. Bei Fragen
können Sie auch jederzeit das Stripe-Team über </span><span lang=DE-AT><a
href="https://support.stripe.com/contact/email" target="_blank"><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman"'>https://support.stripe.com/contact/email</span></a></span><span
lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'> kontaktieren.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Cookies, die Stripe für ihre
Funktionen verwenden, können Sie in Ihrem Browser löschen, deaktivieren oder
verwalten. Je nachdem welchen Browser Sie verwenden, funktioniert dies auf
unterschiedliche Art und Weise. Bitte beachten Sie aber, dass dann eventuell
der Zahlungsvorgang nicht mehr funktioniert. Die folgenden Anleitungen zeigen,
wie Sie Cookies in Ihrem Browser verwalten:<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT><a
href="https://support.google.com/chrome/answer/95647?tid=111870333"
target="_blank"><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Chrome: Cookies in Chrome löschen,
aktivieren und verwalten</span></a></span><span lang=DE-AT style='font-size:
12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT><a
href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=111870333"
target="_blank"><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Safari: Verwalten von Cookies und
Websitedaten mit Safari</span></a></span><span lang=DE-AT style='font-size:
12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT><a
href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=111870333"
target="_blank"><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Firefox: Cookies löschen, um Daten
zu entfernen, die Websites auf Ihrem Computer abgelegt haben</span></a></span><span
lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'> <o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT><a
href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=111870333"
target="_blank"><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Internet Explorer: Löschen und
Verwalten von Cookies</span></a></span><span lang=DE-AT style='font-size:12.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT><a
href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=111870333"
target="_blank"><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Microsoft Edge: Löschen und
Verwalten von Cookies</span></a></span><span lang=DE-AT style='font-size:12.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>
<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:3'><b><span lang=DE-AT style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman"'>Rechtsgrundlage<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Wir bieten also zur Abwicklung von
vertraglichen bzw. rechtlichen Beziehungen <b>(Art. 6 Abs. 1 lit. b DSGVO)</b> <b>&nbsp;</b>neben
den herkömmlichen Bank-/Kreditinstitutionen auch den Zahlungsdienstleister
Stripe an. Der erfolgreiche Einsatz des Dienstes bedarf ferner Ihrer
Einwilligung <b>(Art. 6 Abs. 1 lit. a DSGVO)</b> <b>&nbsp;</b>, soweit für den
Einsatz die Zulassung von Cookies notwendig ist.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Stripe verarbeitet Daten von Ihnen
u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europäischen
Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in
die USA besteht. Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und
Sicherheit der Datenverarbeitung einhergehen.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Als Grundlage der Datenverarbeitung
bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen Union,
Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer
Datenweitergabe dorthin verwendet Stripe sogenannte Standardvertragsklauseln (=
Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual
Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und
sollen sicherstellen, dass Ihre Daten auch dann den europäischen
Datenschutzstandards entsprechen, wenn diese in Drittländer (wie beispielsweise
in die USA) überliefert und dort gespeichert werden. Durch diese Klauseln
verpflichtet sich Stripe, bei der Verarbeitung Ihrer relevanten Daten, das
europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA
gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf
einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und
die entsprechenden Standardvertragsklauseln u.a. hier: </span><span lang=DE-AT><a
href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de"
target="_blank"><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</span></a></span><span
lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'> <o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Mehr Informationen zu den
Standardvertragsklauseln und über die Daten, die durch die Verwendung von
Stripe verarbeitet werden, erfahren Sie in der Privacy Policy auf </span><span
lang=DE-AT><a href="https://stripe.com/at/privacy" target="_blank"><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman"'>https://stripe.com/at/privacy</span></a></span><span
lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Alle Texte sind urheberrechtlich
geschützt.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:11.25pt;mso-margin-bottom-alt:auto;
line-height:normal'><span lang=DE-AT style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>Quelle: Erstellt mit dem </span><span
lang=DE-AT><a href="https://www.adsimple.at/datenschutz-generator/"
title="Datenschutz Generator von AdSimple für Österreich"><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman"'>Datenschutz Generator</span></a></span><span lang=DE-AT
style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman"'> von AdSimple<o:p></o:p></span></p>

<p class=MsoNormal><span lang=DE-AT><o:p>&nbsp;</o:p></span></p>

</div>
`

function Impressum() {
  return (
    <>
        <Layout className="layout" >
            <Content>
                <Breadcrumb className={"breadcrumbs"} >
                  <Breadcrumb.Item>Impressums</Breadcrumb.Item>
                  <Breadcrumb.Item>Info</Breadcrumb.Item>
                  
              </Breadcrumb>
               <div className="site-layout-content">
                    <Row gutter={[24,24]}>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Title>
                                <Link to={"/login"}>
                                        Go back
                                </Link>
                            </Title>
                            {HTMLReactParser(html)}
                        </Col>
                    </Row>
                </div>
            </Content>
        </Layout>
    </>
  )
}



export default Impressum