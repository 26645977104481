import React from 'react'
import  { Row, Col, Select } from "antd"

import { FormattedMessage } from 'react-intl'


import { useGetOptionsQuery } from "../../../services/mainApi"



const { Option } = Select;

function OfferSeller(props) {
    const { data: options, isFetching } = useGetOptionsQuery()
    
    return (
 
          <>
            <Row className='form-row'>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <FormattedMessage id="Select Seller">
                  {(msg) => {
                    return (
                      <>
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          placeholder={msg}
                          optionFilterProp="children"
                          onChange={e => props?.selectSeller(e)}
                          filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                          optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }
                      >
                      {options?.extra.map(item => ( 
                        <Option key={item.id} value={item.id}>{item.name}</Option>
                      ))}
                  </Select> 
                      </>
                    )
                  }} 
                </FormattedMessage>
              </Col>
            </Row>
          </>
        
  )
}

export default OfferSeller