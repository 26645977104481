import React, { Fragment, useState } from 'react'
import { Select, Modal, Button, InputNumber, Alert, Table, Menu, Dropdown, Skeleton,  Row, Tag, Divider, Col, Typography, Checkbox, message, Avatar, Space } from "antd"
import { DownOutlined, DownloadOutlined, IeOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl'


import { useGetOptionsQuery } from "../../../services/mainApi"
import axios from 'axios';
import domain from '../../../domain';

const { Option } = Select;
const { Title, Text } = Typography


function DetailFiles(props) {
    
    const { data: options, isFetching } = useGetOptionsQuery()

    const [modal, setModal] = useState(false)
    const [inProgress, setInProgress] = useState(false)

    // file id 
    const [fileIndex, setFileIndex] = useState(null)

    // target lang
    const [selectedLang, selectLang] = useState(null)
    const [originalLang, selectOriginalLang] = useState(null)
    const [showPrice, setShowPrice] = useState(null)

    const editWc = (index, value) => {
        const oldFile = props?.files[index]

        const newFile = {
            ...oldFile,
            word_count : value 
        }
        let newFiles = props?.files 
        newFiles[index] = newFile
        
        props?.setFiles(newFiles)
        console.log(props.files)

    }

    const editPrice = (index, value) => {
      const oldFile = props?.files[index]

      const newFile = {
          ...oldFile,
          translation_price : value 
      }
      let newFiles = props?.files 
      newFiles[index] = newFile
      props?.setFiles(newFiles)
    } 

    const Submit = async () => {
        setInProgress(true)
        const context = {
          file_id : props?.files[fileIndex].file_id, 
          target : selectedLang
        }

        const token = localStorage.getItem("token")
        
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.defaults.xsrfCookieName = "csrftoken"
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`
        }

        await axios.post(`${domain}/api/file/translation/request/`, context)
        .then(res => {
            props.setReload(!props.reload)
            message.success(res.data.message)
            setModal(false)
        })
        .catch(err => {
          err?.response?.data?.message?.map(mes => {
              message.error(mes)
          })
        })

        setInProgress(false)


    }

    return (
      <>
      <Modal  
            title={<FormattedMessage id='Request file translation' />}
            centered
            visible={modal}
            onCancel={() => setModal(false) }
            footer={
                <Button type="primary" onClick={() => { Submit() }} 
                        disabled={inProgress || selectedLang === originalLang || selectedLang === null || originalLang === null}>
                    <FormattedMessage id="Submit"/>
                </Button>
            }
      >
            <Row gutter={[24,12]}>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <FormattedMessage id='File to translate'>
                      {(msg) => {
                        return (
                          <>
                            <Text>*{msg}</Text>
                              <Select
                                      showSearch
                                      style={{ width: "100%" }}
                                      placeholder={msg}
                                      optionFilterProp="children"
                                      onChange={e => {
                                        setFileIndex(e)
                                        selectOriginalLang(props?.files[e].language_given)
                                        setShowPrice(props?.files[e].translation_price)
                                      }}
                                      
                                      
                                  >
                                  {props?.files?.map((item, index) => ( 
                                    <Option key={index} value={index}>{item.name}</Option>
                                  ))}
                              </Select> 
                          </>
                        )
                      }}
                    </FormattedMessage>
                </Col>
                
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <FormattedMessage id='Original Language'>
                      {(msg) => {
                        return (
                          <>
                            <Text>{msg}</Text>
                              <Select
                                      showSearch
                                      style={{ width: "100%" }}
                                      placeholder={msg}
                                      value={originalLang}
                                      optionFilterProp="children"
                                      disabled
                                  >
                                  {options?.languages?.map(item => ( 
                                  <Option key={item} value={item}>{item}</Option>
                                  ))}
                              </Select> 
                          </>
                        )
                      }}
                    </FormattedMessage>
                </Col>

                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <FormattedMessage id='Target Language'>
                      {(msg) => {
                        return (
                          <>
                              <Text>*{msg}</Text>
                              <Select
                                      showSearch
                                      style={{ width: "100%" }}
                                      placeholder={msg}
                                      value={selectedLang}
                                      optionFilterProp="children"
                                      onChange={e => selectLang(e)}
                                      filterOption={(input, option) =>
                                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                      }
                                      filterSort={(optionA, optionB) =>
                                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                      }
                                  >
                                  {options?.languages?.map(item => ( 
                                  <Option key={item} value={item}>{item}</Option>
                                  ))}
                              </Select> 
                          </>
                        )
                      }}
                    </FormattedMessage>
                </Col>
                
                {showPrice !== null && (
                  <Col xl={24} lg={24} md={24} sm={24} xs={24} style={{marginTop:"20px"}}>
                      <Text>
                          <FormattedMessage id='Price'/>: {showPrice} IPPs
                      </Text>
                  </Col>
                )}

            </Row>
      </Modal>

      <Row gutter={[12, 12]}>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}> 
                <Title level={5}>
                  <FormattedMessage id="Files"/>
                </Title>
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24} >
                {props?.files?.length > 0 ? (
                  props?.files?.map((file, index)=> {
                    return (
                        
                        <Fragment key={index}>
                          
                          <Title level={5}>
                              {file.name} ({file.language_given})

                              {((props?.access_vars?.info_state === 'a') || 
                                (props?.access_vars?.show_externals)) && (
                                <a href={file.file} style={{marginLeft: '10px'}} download >
                                  <DownloadOutlined/>
                                </a>
                              )}
                              
                          </Title>
                          {(props?.overview  && props?.status === 'd') && (
                            <>  
                            <Row gutter={[12, 12]} className="form-row" key={index}>
                              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                  <InputNumber addonBefore="WC"   
                                      status={Number(props?.info_price) === props?.info_price && props?.info_price % 1 !== 0  ? "error" : "none" }
                                      
                                      min={0} 
                                      step={10}
                                      onChange={e => editWc(index, Math.ceil(e))}/>
                              </Col>
                              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                  <InputNumber addonBefore="TP"   
                                      status={Number(props?.info_price) === props?.info_price && props?.info_price % 1 !== 0  ? "error" : "none" }
                                      addonAfter={`IPP`} 
                                      
                                      min={0} 
                                      step={10}
                                      onChange={e => editPrice(index, Math.ceil(e))}/>
                              </Col>
                            </Row>
                              
                              
                              
                             

                            </>
                          )}
                        </Fragment>
                    )
                  })
                ) : (
                    
                      props?.access_vars ? (
                      <Title level={5}><FormattedMessage id="No files"/></Title>
                    ) : (
                      <Skeleton.Input active={true} size={"medium"} block={true}/>
                    )
                )}
                
            </Col>
              
            {props?.access_vars?.info_state === 'a' && (
              <>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}> 
                      <Button  
                            onClick={e => setModal(true) }
                            type='primary'
                            ghost
                            style={{width: "100%"}}> 
                            <FormattedMessage id="Request Translation"/>
                      </Button>
                  </Col>
    
                  {props?.files_translation_requests.length > 0 && (
                      props?.files_translation_requests.map((item, index) => {
                          return (
                              <Col xl={24} lg={24} md={24} sm={24} xs={24}> 
                                  
                                  <Alert type={item.state === "a" ? "success" : "info"} 
                                          message={`${item.name} (${item.target})`}
                                          description={item.state === "a" ? <FormattedMessage id='Ready.'/> : <FormattedMessage id='Under work..'/>}
                                          action={item.state === "a" && (<a href={item.translated_file} style={{marginLeft: '10px'}} download >
                                                                            <DownloadOutlined/>
                                                                          </a>)}
                                      />
                                     
                              </Col>
                          )
                      })
                  )}
                </>
            )}
            
      </Row>
      </>
    )
}

export default DetailFiles