// ** React Imports
import { useState } from 'react'
import axios from 'axios'
import { FormattedMessage } from 'react-intl'

// ** Third Party Components

import { message, Modal, Input, Select, Typography } from "antd"
import domain from '../../domain'

const { Option } = Select;
const { Title, Text } = Typography

const AddExternal = ({ open, handleModal, countries, refetch }) => {
  

    const [first, setFirst] = useState(null)
    const [last, setLast] = useState(null)
    const [title, setTitle] = useState(null)
    const [email, setEmail] = useState(null)
    const [WeChatId, setWeChatID] = useState(null)
    const [city, setCity] = useState(null)
    const [phone, setPhone] = useState(null)
    const [country, setCountry] = useState(null)


    const Submit = async () => {

    if (first === null || last === null || email === null || country === null || city === null) {
        message.error("Missing fields!")
    } else {
        
        try {
        const context = {
            first_name :  first,
            last_name : last,
            title : title,
            WeChat_ID : WeChatId,
            phone : phone,
            city : city,
            email : email.toLowerCase(),    
            country : country,
        }

        const token = localStorage.getItem("token")
        
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.defaults.xsrfCookieName = "csrftoken"
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`
        }

        await axios.post(`${domain}/api/external/create/`, context)
        .then(res => {
            message.success(res.data.message)
            refetch()
            handleModal(false)
        })
        .catch(err => {
            err?.response?.data?.message?.map(mes => {
                message.error(mes)
            })
        })
        } catch (err) {
        message.error(err)
        
        }
    }

  }
  
  return (
    <Modal
          title={<FormattedMessage id='New Record' />}
          centered
          visible={open}
          onOk={() => Submit()}
          onCancel={() => handleModal(false)}
        >
            <>
            <FormattedMessage id='First name'>
                    {(msg) => {
                        return (
                            <>
                            <Text>*{msg}</Text>
                            <Input 
                    
                                label={msg}
                                placeholder={msg}
                                value={first}
                                onChange={e => { setFirst(e.target.value) }}
                                />
                            </>
                        )
                    }}
            </FormattedMessage>
            <FormattedMessage id='Last name'>
                    {(msg) => {
                        return (
                            <>
                            <Text>*{msg}</Text>
                            <Input 
                         
                                label={msg}
                                placeholder={msg}
                                value={last}
                                onChange={e => setLast(e.target.value)}
                                />
                            </>
                        )
                    }}
                </FormattedMessage>
                <FormattedMessage id='Title'>
                    {(msg) => {
                        return (
                            <>
                            <Text>*{msg}</Text>
                            <Input 
                    
                                label={msg}
                                placeholder='Dr/Ing/Prof'
                                value={title}
                                onChange={e => setTitle(e.target.value)}
                                />
                            </>
                        )
                    }}
                </FormattedMessage>
                <FormattedMessage id='Email'>
                    {(msg) => {
                        return (
                            <>
                            <Text>*{msg}</Text>
                            <Input 
                    
                                label={msg}
                                placeholder='username@email.com' 
                                value={email}
                                onChange={e => { setEmail(e.target.value) }} 
                                />
                            </>
                        )
                    }}
                </FormattedMessage>
                <FormattedMessage id='Phone number'>
                    {(msg) => {
                        return (
                            <>
                            <Text>*{msg}</Text>
                            <Input 

                                label={msg}
                                placeholder={msg}
                                onChange={e => { setPhone(e.target.value) }} 
                                value={phone}
                                />
                            </>
                        )
                    }}
                </FormattedMessage>
                <Text>*WeChatID</Text>
                     
                <Input 
                
                    label={"WeChatID"}
                    placeholder={"WeChatID"}
                    value={WeChatId}
                    onChange={e => { setWeChatID(e.target.value) }}
                    />

                <FormattedMessage id='City'>
                    {(msg) => {
                        return (
                            <>
                            <Text>*{msg}</Text>
                            <Input 
                    
                                label={msg}
                                placeholder={msg} 
                                value={city}
                                onChange={e => { setCity(e.target.value) }}
                                />
                            </>
                        )
                    }}
                </FormattedMessage>

                <FormattedMessage id='Country'>
                    {(msg) => {
                        return (
                            <>
                            <Text>*{msg}</Text>
                            <Select
                                showSearch
                                style={{ width: "100%" }}
                                placeholder={msg}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                onChange={(e) => setCountry(e)}
                                value={country}
                            >
                                {countries?.map((item, i) => ( 
                                <Option key={i} value={item}>{item}</Option>
                                ))}
                                
                            </Select> 
                            </>
                        )
                    }}
                </FormattedMessage>
            </>
        </Modal>
  )
}

export default AddExternal
