import React, { useState, useContext } from 'react'
import { Form, Input, Button, message, Typography } from 'antd';

import { Navigate, useParams } from "react-router-dom";
import Particles from 'react-tsparticles'

import { connect } from 'react-redux'
import * as actions from "../../app/auth/actions"
import { GlobalOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl'
import { IntlContext } from '../../utils/intl/Internalization'


const { Title, Text } = Typography

function Register(props) {    
    console.log(useParams())
    const [password, setPassword] = useState("")
    const [password2, setPassword2] = useState("")
    const { email } = useParams()
    const { encrypted } = useParams() 
    const { status } = useParams() 
    const { symb } = useParams() 

    

    const context = useContext(IntlContext)
    context.switchLanguage(symb)

    const onFinish = (values) => {
        props.register(email, password, encrypted, status)
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const particleColor = '#7367F0'
    
    return (
    <>
    {props.auth.register_success && (
      <Navigate to="/login" push />
    )}
    <Particles
      style={{width:'100%', height:'100vh'}}
      params={{
          particles: {
          number: { value: 80, limit: 120, density: { enable: true, value_area: 800 } },
          color: { value: particleColor },
          shape: {
            type: "circle",
            stroke: { width: 0, color: particleColor },
            polygon: { nb_sides: 5 }
          },
          opacity: {
            value: 0.5,
            random: false,
            anim: { enable: false, speed: 1, opacity_min: 0.1, sync: false }
          },
          size: {
            value: 3,
            random: true,
            anim: { enable: false, speed: 40, size_min: 0.1, sync: false }
          },
          line_linked: {
            enable: true,
            distance: 150,
            color: particleColor,
            opacity: 0.4,
            width: 1
          },
          move: {
            enable: true,
            speed: 2,
            direction: "none",
            random: false,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: { enable: false, rotateX: 600, rotateY: 1200 }
          }
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: { enable: true, mode: "grab" },
            onclick: { enable: true, mode: "push" },
            resize: true
          },
          modes: {
            grab: { distance: 400, line_linked: { opacity: 1 } },
            bubble: { distance: 400, size: 40, duration: 2, opacity: 8, speed: 3 },
            repulse: { distance: 200, duration: 0.4 },
            push: { particles_nb: 4 },
            remove: { particles_nb: 2 }
          }
        },
        retina_detect: true

      }} />
    <div className='login-card'>
      

    <Form
      name="basic"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item wrapperCol={{ offset: 10, span: 4 }}>
        <Title level={2} style={{color: "#7367f0"}}>
              IP2B
        </Title>
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
        <Title level={5} strong >
              <FormattedMessage id='Register to IP2B' />
        </Title>
      
      </Form.Item>
   
      <Form.Item
        label="Email"
        name="email"
        
      >
        <Input disabled placeholder={email} />
      </Form.Item>

      <FormattedMessage id="Password">
          {msg => (
            <Form.Item
              label={msg}
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
              onChange={(e) => setPassword(e.target.value)}
            >
              <Input.Password />
            </Form.Item>
          )}
      </FormattedMessage>
      

      <FormattedMessage id="Password2">
        {msg => (
            <Form.Item
                label={msg}
                name="password2"
                rules={[{ required: true, message: 'Please input your password!' }]}
                onChange={(e) => setPassword2(e.target.value)}
              >
                <Input.Password />
          </Form.Item>
        )}
      </FormattedMessage>
      

 

      <div style={{textAlign: "center", marginBottom: "20px"}}>
        <Button type="primary" 
                disabled={password !== password2 || password.length < 8}
                htmlType="submit" 
                style={{ margin: "10px 0 0 0"}}>
            <FormattedMessage id='Register' />
        </Button>
      </div>

    </Form>
          <a className="ml-1"  href="#/impressum">Impressum</a>
          <a style={{ 'float' : "right"}} onClick={e => props.setLangModal(true)}>
                    <GlobalOutlined /> <FormattedMessage id='Language_Name' />
          </a>
    </div>
    
    </>
  )
}


const mapStateToProps = state => {
    return {
      auth : state.auth
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      register : (email, password, encrypted, status) => dispatch(actions.register(email, password, encrypted, status))
    }
  }
  
  
  
  export default connect(mapStateToProps, mapDispatchToProps)(Register)

