import React from 'react'
import {  Divider, Select, Checkbox, Input, Modal, Avatar, Space, Dropdown, Menu, Popover, Button, Row, Col, Table, Spin, Typography, message, Card, Tag } from "antd"
import { Navigate, Link } from 'react-router-dom'

import { FormattedMessage } from 'react-intl'

import { FullscreenOutlined, ExperimentOutlined, DoubleRightOutlined, EditOutlined, PrinterOutlined, FormOutlined, QuestionCircleOutlined } from '@ant-design/icons';


const { Title, Text } = Typography
const { TextArea } = Input;
const { Option } = Select;


function VerifyOffersTable(props) {

    const offerColumns = [
        {
            title: <FormattedMessage id='Offer title'/>,
            key: 'title',
            dataIndex: 'title'
        },
        {
            title: <FormattedMessage id='Seller'/>,
            sortable: true,
  
            render: row => (
              <div className='d-flex align-items-center'>
              <Space>
                <Avatar size={32} src={row.seller.picture} />
    
                <div className='user-info text-truncate'>
                    <span className='d-block font-weight-bold text-truncate'>{row.seller.username}</span><br/>
                    <small>{row.seller.email}</small>
                </div>
                </Space>
              </div>
            )
        },
        {
            title: <FormattedMessage id='Company'/>,
            sortable: true,

            render: row => (
              <>
                <Col style={{"paddingLeft" : "0"}}>
                    <Text strong >{row.company.name}</Text> <br/>
                    <Text className="date-posted" >
                        <small>{row.company.type}, </small>
                        <small>{row.company.location} </small>
                        <small>{row.company.website}</small>
                    </Text>
                </Col>                
              </>
        )
        },
        {
            title: <FormattedMessage id='Status' />,
            render: row => (
                <>
                    <Tag color={row.assigned ?  ("green") : ("red")} >
                        {row.assigned ? (<FormattedMessage id='Assigned to another Manager'/>) : (<FormattedMessage id='Pending'/>)}
                    </Tag>
                </>
            )
        },
        {
            title: <FormattedMessage id='Actions'/>,
            render: row => (
                <>
                    <Dropdown overlay={
                        <Menu>
                            <Menu.Item key={'Edit'}>
                                <Link to={`/personel/offer-overview/${row.offer_id}`}>
                                    <FullscreenOutlined /> <FormattedMessage id="Overview"/>
                                </Link>
                            </Menu.Item>

                            
                           
                        </Menu>
                    } placement="bottomRight" arrow trigger={['click']}>
                        <Button className='btn-icon' color='primary'>
                            <FormOutlined />
                        </Button>
                    </Dropdown>
                </>
            )
        }
    ]
    
    return (
        <>
            <Card className="news-card">
                <Title level={5} strong>
                    <FormattedMessage id="Offers to be verificated"/>
                </Title>
                {!props?.isFetching ? (
                    <Table
                        size="small"
                        dataSource={props?.offers}
                        columns={offerColumns}  
                        pagination={{ pageSize: 5 }}   
                        rowKey="offer_id"
                    />
                    ) : (
                        <div style={{minHeight:'300px', textAlign:'center'}}>
                            
                            <Spin style={{marginTop: "75px"}} size="large" />
                        
                        </div>
                )}
            </Card>
        </>
    )
}

export default VerifyOffersTable