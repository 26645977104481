import React, { useEffect, useState } from 'react'
import { message, Upload, Button, DatePicker, Select, Input, Row, Space, Col, Typography } from "antd"
import { FormattedMessage, useIntl } from 'react-intl'
import moment from 'moment';
import axios from "axios"
import domain from "../../domain"

import { useGetOptionsQuery } from "../../services/mainApi"


const { Title, Text } = Typography
const { Option } = Select


function Settings(props) {
  const intl = useIntl()

  const { data: options, isFetching } = useGetOptionsQuery()


  const dateFormat = 'YYYY-MM-DD'
  const [loading, setLoading] = useState(false)

  const [first, setFirst] = useState(props?.first_name)
  const [last, setLast] = useState(props?.last_name)
  const [phNumber, setPhNumber] = useState(props?.phone)
  const [title, setTitle] = useState(props?.title)
 
  const [date_born, setBornDate] = useState(moment(props?.date_of_birth, dateFormat))
  const [WeChatId, setWeChatId] = useState(props?.WeChat_ID)


  const [translatorPairs, setTranslatorPairs] = useState(props?.pairs)
  

  const [spokenLanguages, selectSpokenLanguages] = useState(props?.spoken_languages)

  
  const [reset, setReset] = useState(true)
  
  const [thumbnail, setThumbnail] = useState(null)
  const [preview, setPreview] = useState(props?.picture)


  useEffect(() => {
    setPreview(props?.picture)
    setThumbnail(null)
  }, [reset])


  const Submit = async () => {
    const d = new Date(date_born)
    const date = d.getDate()
    const month = d.getMonth() + 1 //Months are zero based
    const year = d.getFullYear()

    const finalDate = `${date}-${month}-${year}`

    const context = {
        first_name : first,
        last_name : last,
        phone : phNumber,
        title : title, 
        born_on : finalDate,
        WeChatID: WeChatId,
        isUploaded : thumbnail !== null,
        translator_pairs : translatorPairs,
        languages : spokenLanguages
    }  
    
    const token = localStorage.getItem("token")
    // these should be at  every post request

    const formData = new FormData()
    if (thumbnail !== null) {
    formData.append("thumbnail", thumbnail, thumbnail.name) 
    }
    formData.append("context", JSON.stringify(context))

    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
    axios.defaults.xsrfCookieName = "csrftoken"
    axios.defaults.headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Token ${token}`
    }

    const hide = message.loading('Loading...', 0)

    await axios.post(`${domain}/users/profile/info/`, formData)
    .then(res => {
        props.refetch()
        hide()
        const translatedMessage = intl.formatMessage({id: "Changes Saved!"})
        message.success(translatedMessage)
    })
    .catch(err => {
        hide()
        message.error("Error 404!")
    })
  }

  function getBase64(img, callback) {
    const reader = new FileReader();
    
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    } else {
      setThumbnail(file)
    }
    
    return isJpgOrPng;
  }

  const handleChange = info => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, thumbnail =>
        {
            setPreview(thumbnail)
            setLoading(false)
        },
      );
    }
  }

 
  return ( 
      
    <>
      <Row gutter={[12, 12]} className='form-row'>
        
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            
            <Space align="center" size={10}>
              <div className='profile-img'>
                <img style={{height:'100px', width: '100px', borderRadius: ".357rem!important"}} className='rounded img-fluid' src={preview} alt='Card image' />
              </div>
              
              <Upload 
                    name="avatar"
                    
                    showUploadList={false}
                   
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                    customRequest={({ file, onSuccess }) => {
                        setTimeout(() => {
                          onSuccess("ok");
                        }, 0);
                      }}>
                <Button style={{marginLeft:"10px"}} type="primary">
                    <FormattedMessage id="Upload"/>
                </Button>
              </Upload>
                
                <Button danger onClick={e => setReset(!reset)}>
                    <FormattedMessage id="Reset"/>
                </Button>
         
            </Space>
              
          </Col>
          {props?.status === 'Translator' || props?.status === "Manager" ? (
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <FormattedMessage id='Translation Pairs'>
                        {(msg) => {
                            return (
                                <>
                                <Text>*{msg}</Text>
                                <Select
                                    mode="multiple"
                                    name={"spoken languages"}
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder={msg}
                                   
                                    onChange={(e) => setTranslatorPairs(e)}
                                    defaultValue={translatorPairs}
                                >
                                    {options?.pairs?.map((item, i) => ( 
                                    <Option key={i} value={item}>{item}</Option>
                                    ))}
                                    </Select> 
                                </>
                            )
                        }}
                    </FormattedMessage>
                </Col>
        ) : null}
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <FormattedMessage id='First name'>
                    {(msg) => {
                        return (
                            <>
                            <Text>*{msg}</Text>
                            <Input 
                    
                                label={msg}
                                placeholder={msg}
                                value={first}
                                onChange={e => setFirst(e.target.value)}
                                />
                            </>
                        )
                    }}
                </FormattedMessage>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <FormattedMessage id='Last name'>
                    {(msg) => {
                        return (
                            <>
                            <Text>*{msg}</Text>
                            <Input 
                         
                                label={msg}
                                placeholder={msg}
                                value={last}
                                onChange={e => setLast(e.target.value)}
                                />
                            </>
                        )
                    }}
                </FormattedMessage>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <FormattedMessage id='Phone number'>
                    {(msg) => {
                        return (
                            <>
                            <Text>*{msg}</Text>
                            <Input 

                                label={msg}
                                placeholder={msg}
                                value={phNumber}
                                onChange={e => setPhNumber(e.target.value)}
                                />
                            </>
                        )
                    }}
                </FormattedMessage>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <FormattedMessage id='Title'>
                    {(msg) => {
                        return (
                            <>
                            <Text>*{msg}</Text>
                            <Input 
                    
                                label={msg}
                                placeholder={msg}
                                value={title}
                                onChange={e => setTitle(e.target.value)}
                                />
                            </>
                        )
                    }}
                </FormattedMessage>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <FormattedMessage id='Born On'>
                    {(msg) => {
                        return (
                            <>
                            <Text>*{msg}</Text>
                            <br></br>
                            <DatePicker 
                                defaultValue={date_born} 
                                format={dateFormat} 
                                onChange={e => { setBornDate(e) }}
                                style={{width: "100%"}}
                              />
                            </>
                        )
                    }}
                </FormattedMessage>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                
                            <Text>*WeChatID</Text>
                     
                             <Input 
                             
                                label={"WeChatID"}
                                placeholder={"WeChatID"}
                                value={WeChatId}
                                onChange={e => setWeChatId(e.target.value)}
                                />
                           
            
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <FormattedMessage id='Spoken Languages'>
                    {(msg) => {
                        return (
                            <>
                            <Text>*{msg}</Text>
                            <Select
                                mode="multiple"
                                name={"spoken languages"}
                                showSearch
                                style={{ width: "100%" }}
                                placeholder={msg}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                onChange={(e) => selectSpokenLanguages(e)}
                            
                                defaultValue={spokenLanguages}
                            >
                                {options?.languages?.map((item, i) => ( 
                                <Option key={i} value={item}>{item}</Option>
                                ))}
                                </Select> 
                            </>
                        )
                    }}
                </FormattedMessage>
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Button onClick={e => Submit()}>
                    <FormattedMessage id="Save Changes" />
                </Button>
            </Col>
      </Row>
    </>
  )
}

export default Settings