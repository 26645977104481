import React, { useState } from 'react'
import  { Checkbox,  Button, Space, message, Row, Col, Input, Upload } from "antd"
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import { FormattedMessage } from 'react-intl'



function OfferGallery(props) {
   
    const [loading, setLoading] = useState(false)
   

    const addGallery = () => {
        props.setGallery([...props.gallery, {file : null, preview: null, reference: "", thumbnail: false}])
    }

    const resetGallery = () => {
        props.setGallery([{file : null, preview: null, reference: "", thumbnail: true}])
    }   


    const checkThumbnail = (index) => {
        props.gallery.map(a => a.thumbnail = false)
        const oldGallery = [...props.gallery]
        oldGallery[index]['thumbnail'] = true 
        props.setGallery(oldGallery)
    }

    const changeReference = (value, index) => {
        let oldGallery = [...props.gallery]
        oldGallery[index]['reference'] = value
        props.setGallery(oldGallery)
    }

    function getBase64Thumbnail(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    function beforeUploadThumbnail(file, index) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        } else {

            let oldGallery = [...props.gallery]
            oldGallery[index]['file'] = file
            props.setGallery(oldGallery)
        }
        
        return isJpgOrPng;
    }
    
    const handleChangeThumbnail = (info, index) => {
    if (info.file.status === 'uploading') {
        setLoading(true)
        return;
    }
    if (info.file.status === 'done') {
        // Get this url from response in real world.
        getBase64Thumbnail(info.file.originFileObj, thumbnail =>
        {
       
            let oldGallery = [...props.gallery]
            oldGallery[index]['preview'] = thumbnail
            props.setGallery(oldGallery)
            setLoading(false)
        },
        );
    }
    }

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
          onSuccess("ok");
        }, 0);
    };


    return (
        <>
            <Row className='form-row'>
                <Space align="start" style={{ "overflowX" : "scroll"}}>
                    {props.gallery.map((e, index) => {
                        return (
                           
                            <Row key={index} style={{"width" : "208px", "paddingBottom" : "25px"}}  gutter={[0,6]}>
                                <Col lg={24}>
                                    <Upload
                                        
                                        name={`gallery-${index}`}
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        
                                        beforeUpload={(file) => { beforeUploadThumbnail(file, index) }}
                                        onChange={(file) => { handleChangeThumbnail(file, index) }}
                                        customRequest={dummyRequest}
                                    >
                                        {e.preview ? <img src={e.preview} alt="avatar" style={{ height: "100%", maxWidth: "100%" }} /> : (
                                        <div>
                                            {loading ? <LoadingOutlined /> : <PlusOutlined />}
                                            <div style={{ marginTop: '20px' }}>
                                                Upload
                                            </div>
                                        </div>)}
                                    </Upload> 
                                </Col>
                                <Col lg={24}>
                                    <FormattedMessage id="Reference" >
                                        {(msg) => (
                                            <Input 
                                                name='status'
                                                value={props.gallery[index]['reference']}
                                                placeholder={msg}
                                                disabled={props.gallery[index]['file'] === null}
                                                onChange={(e) => { changeReference(e.target.value, index) }}
                                                />
                                        )}

                                    </FormattedMessage>
                                    

                                </Col>
                                <Col lg={24}>
                                    <Checkbox 
                                        onChange={() => { checkThumbnail(index) }}
                                        checked={props.gallery[index]['thumbnail']}
                                        disabled={props.gallery[index]['file'] === null}
                                            >
                                        <FormattedMessage id="Thumbnail" /> 
                                    </Checkbox>
                                </Col>
                            </Row> 
                          
                        )
                    })}
                    

                    <Button onClick={() => { addGallery() }}>
                        Add gallery
                    </Button>
                    <Button danger onClick={() => { resetGallery() }}>
                        Reset
                    </Button>
                </Space>
            </Row>
        </>
  )
}

export default OfferGallery