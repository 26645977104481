import React from "react"
import ReactDOM from "react-dom"
import { HashRouter as Router } from "react-router-dom"
import App from "./App"

import { Provider } from "react-redux"
import store from "./app/store"

import { IntlProviderWrapper } from './utils/intl/Internalization'


ReactDOM.render(
    <Router>
        <IntlProviderWrapper>
        {/* we need to wrap the app into the provider store */}   
            <Provider store={store}>
                <App />
            </Provider>
        </IntlProviderWrapper>
    </Router>
    , document.getElementById("root"))

    
