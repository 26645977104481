import React, {  useState } from "react"
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js"
import { FormattedMessage, useIntl } from 'react-intl'

import { Button, Spin, Card, message } from 'antd'

import domain from '../../domain'

export default function CheckoutForm() {
  const intl = useIntl()

  const stripe = useStripe()
  const elements = useElements()

  const [isLoading, setIsLoading] = useState(false)

  

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsLoading(true)

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${domain}/#/` // keep this domain until the landing page is finished 
      }
    })

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
    
      message.error(error.message)
    } else {
      const translatedMessage = intl.formatMessage({id: "An unexpected error occured."})
      message.success(translatedMessage)
    }

    setIsLoading(false)
  }

  return (
    <form className='manual-form' id="payment-form" onSubmit={handleSubmit}>
     
     
        <PaymentElement id="payment-element" />

        {!stripe || !elements ? (
          <Card style={{minHeight:'300px', textAlign:'center', border: "none"}}>            
            <Spin size="large" />
          </Card>
        ) : ('')}

        <button className="transaction" disabled={isLoading || !stripe || !elements} id="submit">
          <span id="button-text">
            {isLoading ? <Spin size="large" /> : "Pay now"}
          </span>
        </button>
        {/* Show any error or success messages */}
    </form>
  )
}