import React, { useState } from 'react'
import moment from 'moment';
import { Tabs, Divider, Select, Checkbox, Input, Modal, Avatar, Space, Dropdown, Menu, Popover, Button, Row, Col, Table, Spin, Typography, message, Card, Tag } from "antd"
import { ExperimentOutlined, DoubleRightOutlined, EditOutlined, PrinterOutlined, FormOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import HTMLReactParser from "html-react-parser"

import { FormattedMessage } from 'react-intl'
import axios from "axios"

import domain from "../../../domain"


const { Title, Text } = Typography
const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;


function VerifyOfferTranslationsTable(props) {
    
    const [inProgress, setInProgress] = useState(false)

    const [translation_modal, setTranslationModal] = useState(false)
    const [translation_item, setTranslationItem] = useState({})
    const [translation_rejection, setTranslationRejection] = useState("")

    const verifyTranslation = async (rejected) => {
        setInProgress(true)

        const context = {
            rejected : rejected,
            rejected_message: translation_rejection,
            translation_id : translation_item.translation_id 
        }

        const token = localStorage.getItem("token")
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.defaults.xsrfCookieName = "csrftoken"
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`
        }

        await axios.post(`${domain}/manager/dashboard/verify/translation/`, context)
        .then(res => {
            if (res.data.error) {
                message.error(res.data.message)
            } else {
                message.success(res.data.message)
                setTranslationModal(false)
                setTranslationItem({})
                setTranslationRejection("")
                props?.refetch()
            }
        })

        setInProgress(false)
    }


    const translationColumns = [
        {
            title: <FormattedMessage id='Offer title'/>,
            key: 'title',
            dataIndex: 'title'
        },
        {
            title: <FormattedMessage id="Date Sent"/>,
            
            defaultSortOrder: 'ascend',
            sorter: (a, b) => moment(b.date_created).unix() - moment(a.date_created).unix(),
            render: row => {
               
                return (
                    <>
                        {row.date_created}
                    </>
                )
            }
        },
        {
            title: <FormattedMessage id='Original language'/>,
            key: 'original_language',
            dataIndex: 'original_language'
        },
        {
            title: <FormattedMessage id='Translation language'/>,
            key: 'target_language',
            dataIndex: 'target_language'
        },
        {
            title: <FormattedMessage id='Actions'/>,
            render: row => (
                <>
                    <Dropdown overlay={
                        <Menu>
                         
                            <Menu.Item key={'message-overview'}>
                            <a onClick={e => {
                                setTranslationItem(row)
                                setTranslationModal(true)
                            }}>
                                <ExperimentOutlined /> <FormattedMessage id="Translation Overview"/>
                            </a>
                            </Menu.Item>
                        </Menu>
                    } placement="bottomRight" arrow trigger={['click']}>
                        <Button className='btn-icon' color='primary'>
                            <FormOutlined />
                        </Button>
                    </Dropdown>
                </>
            )
        }
    ]

    return (
        <>
            {/* translation modal */}
            <Modal         
                className='translation-modal'
                width={'100%'}
                visible={translation_modal}
                title={<FormattedMessage id="Offer Translation"/>}
                centered
                footer={null}
                onCancel={() => { 
                    setTranslationModal(false)
                }}
                >
                <Row gutter={[24,24]}>
                    <Col xl={20} lg={20} md={20} sm={20} xs={20}>
                        <Row gutter={[24,24]}>
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>

                                <Title level={5}><FormattedMessage id="Offer title"/></Title>
                                <Text>{translation_item?.original_title}</Text>

                            </Col>
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>

                            <Title level={5}><FormattedMessage id="Status"/></Title>
                            <Text>{translation_item?.original_status}</Text>

                            </Col>

                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>

                            <Title level={5}><FormattedMessage id="Description"/></Title>
                            {translation_item?.original_description && (HTMLReactParser(translation_item?.original_description))}

                            </Col>
                            <Divider />

                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Title level={5}><FormattedMessage id="Offer title"/></Title>
                            <Text>{translation_item?.title}</Text>
                            </Col>
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Title level={5}><FormattedMessage id="Status"/></Title>
                            <Text>{translation_item?.status}</Text>
                            </Col>
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Title level={5}><FormattedMessage id="Description"/></Title>
                            {translation_item?.description && (HTMLReactParser(translation_item?.description))}
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                        <Row gutter={[24,24]}>
                            <Col l={24} lg={24} md={24} sm={24} xs={24}>
                                <Title level={4}>
                                <FormattedMessage id="Review the offer"/>
                                </Title>
                            </Col>
                            <Col l={24} lg={24} md={24} sm={24} xs={24}>
                            <Tabs defaultActiveKey="1">
                                <TabPane style={{borderBottomStyle:'none'}}
                                tab={
                                    <span>
                                        <FormattedMessage id="Accept" />
                                    </span>
                                }
                                key="1"
                                >
                                
                                    <Button type="primary"
                                            className='mt-1'
                                            disabled={inProgress}
                                            style={{width: "100%", color : "white"}}
                                            onClick={() => verifyTranslation(false)} // false because acccepting
                                            >
                                        <FormattedMessage id="Accept"/>
                                    </Button>
                                </TabPane>
                                <TabPane
                                tab={
                                    <span>
                                        <FormattedMessage id="Reject" />
                                    </span>
                                }
                                key="2"
                                >
                                <TextArea rows={3} 
                                        className='mb-1 mt-1' 
                                        placeholder='Description of rejection'
                                        value={translation_rejection}
                                         onChange={e => { setTranslationRejection(e.target.value) }} />
                                
                                <Button type="primary"
                                        style={{width: "100%"}}
                                        disabled={inProgress || translation_rejection === ""}
                                        danger
                                        onClick={() => verifyTranslation(true)} // true because rejecting
                                        >
                                        <FormattedMessage id="Reject"/>
                                    </Button>

                                </TabPane>
                                </Tabs>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal>
            
            <Card className="news-card">
            <Title level={5} strong>
                <FormattedMessage id="Translations to be verificated"/>
            </Title>
                {!props?.isFetching ? (
                    <Table
                        size="small"
                        dataSource={props?.translations}
                        columns={translationColumns}  
                        pagination={{ pageSize: 5 }}  
                        rowKey="translation_id" 
                    />
                    ) : (
                        <div style={{minHeight:'300px', textAlign:'center'}}>
                            
                            <Spin style={{marginTop: "75px"}} size="large" />
                        
                        </div>
                )}
            </Card>
        </>
    )
}

export default VerifyOfferTranslationsTable