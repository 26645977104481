import React, { useState, useEffect } from 'react'
import { Spin, message, Upload, DatePicker ,Input, Typography, Button, Col, Select, Space, Avatar, Row, Timeline, Modal, Tag } from "antd"

import { FormattedMessage, useIntl } from 'react-intl'
import { FastForwardFilled, SmileOutlined } from '@ant-design/icons';
import axios from 'axios'
import domain from '../../domain'

import { useGetOptionsQuery, useGetUserInfoQuery } from "../../services/mainApi"

import {  CloseOutlined, UploadOutlined, unCheckedChildren, CheckOutlined, DownOutlined, DownloadOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Option } = Select;
const { Title, Text } = Typography

function CustomTimeline(props) {
    const intl = useIntl()

    const { data: options, isFetching } = useGetOptionsQuery()
    const { data: userData } = useGetUserInfoQuery()

    
    const [inProgress, setInProgress] = useState(false)
    const [messages, setMessages] = useState(null)
    const [updateMessages, setUpdate] = useState(false)

    const [messageModal, setMessageModal] = useState(false)

    const [content, setContent] = useState("")
    const [selectedLang, selectLang] = useState(userData?.preferred_language?.name)
    
    const [fileList, setFileList] = useState([])
    const [date, setDate] = useState(null)

    // int to display the number of messages to be shown:
    const [load, setLoad] = useState(5)

    const [loadingMore, setLoadingMore] = useState(false)

    useEffect(() => {
        const intervalId = setInterval(() => { 
        const fetchMessages = async () => {
            await axios.get(`${domain}/api/package/timeline/${props.packageId}/${load}/`)
            .then(res => {
                setMessages(res.data.messages)
                setLoadingMore(false)
            })
        }
        fetchMessages()
    
        }, 5000)
        return () => clearInterval(intervalId)
    }, [updateMessages])
    

    const loadMore = () => {
        setLoadingMore(true)
        // when clicked for first time it will load 5 more messages, when clicked second time load will be 0 meaning all messages
        setLoad(load === 5 ? (10) : (0))
        setUpdate(!updateMessages)
    }


    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
          onSuccess("ok");
        }, 0);
      };
    
    const sendMessage = async () => {
        setInProgress(true)
        if (content && selectedLang !== null) {
            const token = localStorage.getItem("token")
       
            const context = {
                content : content,
                has_file : fileList.length > 0,
                is_meeting : date !== null, 
                sent_from_seller : !props.userPage,
                language : selectedLang, 
                date_for_meeting: date !== null ? date.toISOString() : null,
            }

            const formData = new FormData()

            if (fileList.length > 0) {
                formData.append("file", fileList[0].originFileObj, fileList[0].originFileObj.name)
            }
            formData.append("context", JSON.stringify(context))
            axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
            axios.defaults.xsrfCookieName = "csrftoken"
            axios.defaults.headers = {
                "Content-Type": "multipart/form-data",
                Authorization: `Token ${token}`
            }

            await axios.post(`${domain}/api/package/timeline/${props.packageId}/${load}/`, formData, { timeout : 50000 })
            .then(res => {
                setMessages(res.data.messages) 
                setContent("")
                setFileList([])
                setDate(null)

                setMessageModal(false)
               
                
                setUpdate(!updateMessages)

                const translatedMessage = intl.formatMessage({id: "Message sent!"})
                message.success(translatedMessage)
            })
            .catch(err => {
                message.error(err)
            })    
        } else {
            message.error("Missing fields!")
        }
        setInProgress(false)
    }
    
    return (

        <>
        <Modal
          visible={messageModal}
          title={<FormattedMessage id='Create Message'/>}
          centered
          footer={<>
              <Button disabled={inProgress} type="primary" onClick={() => { sendMessage() }}>
                <FormattedMessage id='Create'/>
              </Button>
          </>}
          onCancel={() => { setMessageModal(false) }}
         
        >

            

            <Row gutter={[12,12]}>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <FormattedMessage id="Content">
                        {(msg) => {
                            return (
                                <>
                                <Text>*{msg}</Text>
                                <TextArea rows={4} placeholder={msg} value={content} onChange={e => { setContent(e.target.value) }}/>
                                </>
                            )
                        }}
                    </FormattedMessage>

           
                </Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <FormattedMessage id="Language">
                        {(msg) => {
                            return (
                                <>
                                <Text>*{msg}</Text>
                                <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder={msg}
                                    optionFilterProp="children"
                                    onChange={e => selectLang(e)}
                                    filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    value={selectedLang}
                                    filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                >
                                {options?.languages?.map(item => ( 
                                <Option key={item} value={item}>{item}</Option>
                                ))}
                            </Select> 
                                </>
                            )
                        }}
                    </FormattedMessage>
                </Col>
                
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Row gutter={[12, 12]}>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <FormattedMessage id='Set date for meeting'>
                                {(msg) => {
                                    return (
                                        <>
                                            <Text>*{msg}</Text>
                                            <br/>
                                            <DatePicker showTime={{ format: 'HH:mm' }}
                                                style={{width: '100%'}}
                                                format="YYYY-MM-DD HH:mm"
                                                minuteStep={30}
                                                onChange={(value, dateString) => { 
                                                    setDate(value) 
                                                }} 
                                                onOk={e => console.log(e)} />
                                        </>
                                    )
                                }}
                            </FormattedMessage>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Text>*<FormattedMessage id='Add File'/></Text>
                            <br/>
                            <Upload 
                               
                                multiple={false}
                                fileList={fileList.length > 0 ? [fileList.slice(-1)[0]] : []}
                                customRequest={dummyRequest}
                                onChange={e => { setFileList(e.fileList) }}
                                >
                                <Button style={{ "width" : "100%" }} icon={<UploadOutlined />}>Upload Documents</Button>
                            </Upload>
                        </Col>
                    </Row>
                    
                        
                </Col>

                
            </Row>



        </Modal>


            <Title level={4} >
                <FormattedMessage id='Timeline' />
            </Title>

            <Button type="primary"
                        disabled={messages === null || !props.online}
                        style={{width: "100%"}}
                        onClick={() => props.online && (setMessageModal(true))}
                        >
                            {messages === null ? (
                                <FormattedMessage id='Loading...'/>
                            ) : (
                                props.online ? (
                                    <FormattedMessage id='Create Message'/>
                                ) : (
                                    <FormattedMessage id='Negotiation Package has Expired!'/>
                                )
                            )}
            </Button>

            <Timeline className='mt-2'>
                {messages?.map((message, index) => {
                    const date = new Date(message?.date_created)
                    const meeting_date = new Date(message?.date_for_meeting)

                    const formated = userData?.preferred_language?.name === 'English' ? (meeting_date.toLocaleString('en-GB')) : (userData?.preferred_language?.name == 'Deutsch' ? (meeting_date.toLocaleString('de-DE')) : (meeting_date.toLocaleString('zh-ZH')))

                    return (
                        <Timeline.Item color={message.sent_from_seller ? ('blue') : ('green')} key={index}>
                            <Row gutter={[12,12]}>
                                <Col xl={24} lg={24} md={24} sm={24} xs={24}> 
                                    <Text strong>{message.sent_from}</Text>
                                    <Text style={{opacity: '0.5', float: 'right'}}>{date.toLocaleString('en-GB')}</Text>
                                </Col>
                       
                                <Col xl={24} lg={24} md={24} sm={24} xs={24}> 
                                    <Text>{message.content}</Text>
                                </Col>

                                {message?.is_meeting && (
                                    <Col xl={24} lg={24} md={24} sm={24} xs={24}> 
                                        <Text>
                                            <FormattedMessage id='Meeting suggestion at'/>: {formated}  
                                        </Text>
                                    </Col>
                                )}

                                {message?.has_file && (
                                    <Col xl={24} lg={24} md={24} sm={24} xs={24}> 
                                        <Text>
                                            {message?.file?.name} 
                                            
                                            <a href={message?.file?.data} style={{marginLeft: '10px'}} download >
                                                <DownloadOutlined/>
                                            </a>
                                          
                                            
                                        </Text>
                                    </Col>
                                )}


                                {/* the logic here stands that if it is in user page, then it means that it is beeing seen by the user */}
                                {/* in that case we want to express the mssages sent from seller with image */}
                                {/* otherwise  */}
                                {((props.userPage && message?.sent_from_status === 's') || (!props.userPage && message?.sent_from_status === 'u')) && (
                                    <Col xl={24} lg={24} md={24} sm={24} xs={24}> 
                                            <Space size={10}>
                                                <Avatar size={32} src={message?.sent_from_picture} />

                                                <div className='user-info text-truncate'>
                                                    <span className='d-block font-weight-bold text-truncate'>{message?.sent_from}</span>
                                                </div>
                                            </Space>
                                        </Col>
                                )}

                                <Col xl={24} lg={24} md={24} sm={24} xs={24}> 
                                    {/* either pending or approved */}

                                    <Tag color={message.status == 'v' ?  "green" : "red" }> 
                                        <FormattedMessage id={message.status === 'v' ? 'Approved' : message.status === "p" ? ("pending") : ("enlisted for translation")}/>
                                    </Tag>
                                </Col>
                            </Row>
                        </Timeline.Item>
                    )
                })}

                {messages === null && (
                    <FormattedMessage id='Loading...'/>
                )}
                {messages?.length === 0 && (
                    <FormattedMessage id='No Messages'/>
                )}
   
                
                <Row className='mt-1'>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24} style={{textAlign: 'center'}}>
                        {loadingMore ? (
                        <>
                            <Spin className='mt-1' />
                        </>
                        ) : (
                            (load !== 0 && messages !== null  && messages.length >= load) && (
                                <a onClick={e => { loadMore() }} className='mt-1'>
                                    {load === 5 ? (
                                        <>
                                            <FormattedMessage id='Load more...'/>
                                        </>
                                    ) : (
                                        <>
                                            <FormattedMessage id='Load all...'/>  
                                        </>
                                    )}
                                </a>
                            )
                        )}
                    </Col>
                </Row>
                
            
                
                
                

            </Timeline>
        </>
    )
}


export default CustomTimeline