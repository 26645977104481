import React, {  useState } from 'react'

import { Switch, Row, message, Divider, Col, Typography } from "antd"
import {  CloseOutlined, unCheckedChildren, CheckOutlined, DownOutlined, DownloadOutlined } from '@ant-design/icons';

import axios from 'axios'
import { FormattedMessage } from 'react-intl'


import domain from "../../../domain"


const { Title } = Typography

function ChangeAutoRenew(props) {

    const [inProgress, setInProgress] = useState(false)

    const changeAutoRenew = async () => {
        setInProgress(true)
        const token = localStorage.getItem("token")
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.defaults.xsrfCookieName = "csrftoken"
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`
        }
        const context = {
          auto_renew : !props.auto_renew,
          offer_id : props.offerId
        }
        // TODO: need to test this
        await axios.post(`${domain}/api/offer/change-auto-renew/`, context)
        .then(res => {
          
            message.success(res.data.message)
            props.setReload(!props.reload)
            
        })
        .catch(err => {
          err?.response?.data?.message?.map(mes => {
              message.error(mes)
          })
        })

        setInProgress(false)
      }

    return (
    <>
        <Row gutter={[6,6]}>
        <Col l={24} lg={24} md={24} sm={24} xs={24}>
            <Title level={4}>
                <FormattedMessage id="Negotiation Package"/>
            </Title>
        </Col>
        <Col l={24} lg={24} md={24} sm={24} xs={24}>
            <Title level={5}>
            <FormattedMessage id="Thank you for purchasing the Negotiation Package!"/>
            </Title>
        </Col>
        </Row>
        <Divider />
        <Row gutter={[6,6]}>
            <Col l={24} lg={24} md={24} sm={24} xs={24}>
            <Title level={5}>
                <FormattedMessage id="Auto Renew"/>
            </Title>
            </Col>
            <Col l={24} lg={24} md={24} sm={24} xs={24}>  
            <Switch 
                defaultChecked={props.auto_renew}
                disabled={inProgress || props?.isFetching}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={e => { changeAutoRenew() }} />
            </Col>
        </Row>
    </>
  )
}

export default ChangeAutoRenew