import * as actionTypes from './actionTypes'

// **  Initial State
const initialState = {
  token : null,
  login_success : false,    // if true user gets redirected to home page 
  register_success : false, // if true user gets redirected to login page
  reset_success : false, 
  forgot_success : false, // if true, then disable the enter email field in the reset password page 
  username: null,
  loading : false,
  error : null
}

export const updateObject = (oldObject, updatedProperties) => {
  return {
      ...oldObject,
      ...updatedProperties
  }
}


const authStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading : true
  })
}

const authRegister = (state, action) => {
  return updateObject(state, {
    register_success : action.register_success,
  })
}

const authForgot = (state, action) => {
  return updateObject(state, {
    forgot_success : action.forgot_success
  })
}

const authReset = (state, action) => {
  return updateObject(state, {
    reset_success : action.reset_success
  })
}


const authSuccess = (state, action) => {
  return updateObject(state, {
    token : action.token,
    username : action.username,
    error : null,
    login_success: true
  })
}

const authFail = (state, action) => {
  return updateObject(state, {
    error : action.error
  })
}

const authLogout = (state, action) => {
  return updateObject(state, {
    token : null,
    login_success : false,  
    username: null,
    loading : false,
    error : null
  })
}

export const AUTH_LOGOUT = "AUTH_LOGOUT"

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action)
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action)
    case actionTypes.AUTH_FAIL:
      return authFail(state, action)
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action)
    case actionTypes.AUTH_REGISTER:
      return authRegister(state, action)
    case actionTypes.AUTH_FORGOT:
      return authForgot(state, action)
    case actionTypes.AUTH_RESET:
      return authReset(state, action)
    default:
      return state
  }
}

export default authReducer
