import React from 'react'
import { Link } from 'react-router-dom'
import { Avatar, Space, Menu, Button, Table, Spin, Typography, Card, Tag } from "antd"

import { FormattedMessage } from 'react-intl'
import { DoubleRightOutlined } from '@ant-design/icons';

const { SubMenu } = Menu;
const { Title, Text } = Typography

function TimelinesTable(props) {
    
    const timelinesColumns = [
        {
          title: <FormattedMessage id='Offer title'/>,
          key: 'offer_title',
          dataIndex: 'offer_title',
         
        },
        {
          title: <FormattedMessage id='User'/>,
          sortable: true,
          width: 200,
          render: row => (
            <div className='d-flex align-items-center'>
            <Space>
            <Avatar size={32} src={row.user_picture} />

            <div className='user-info text-truncate'>
                <span className='d-block font-weight-bold text-truncate'>{row.username}</span><br/>
                <small></small>
                <Tag color={'green'}>
                    {row.user_status}
                </Tag>
            </div>
            </Space>
            
            </div>
          )
        },
        {
          title: <FormattedMessage id='Latest message'/>,
          key: 'last_message',
          dataIndex: 'last_message',
        },
        {
          title: <FormattedMessage id='Type of message'/>,
          width: 300,
          
          render: row => {
            console.log(row)
            return (
                <>
           
                  <Tag color={row?.sent_from_status === 's' ? 'blue' : 'green'}>
                    {row.is_meeting ? (<FormattedMessage id='Meeting suggestion'/>) : (<FormattedMessage id='Message/File'/>)}  {row?.sent_from_status === 's' ? (<FormattedMessage id="Seller"/>) : (<FormattedMessage id="User"/>)}
                  </Tag>
                </>
            )
          }
        },
        {
          title: <FormattedMessage id='Go to timeline'/>,
          width: 150,
          render: row => {
     
            return (
                <>
                  <Link style={{overflowX: 'hide'}}  to={`/personel/timeline/${row.package_id}`}>
                      <Button className='btn-icon' outline color='primary'>
                          <DoubleRightOutlined />
                      </Button>
                  </Link>
                </>
           
  
            )
          }
        }
    ]
    return (
        <>
            
            <Card className="news-card">
            <Title level={5} strong>
                <FormattedMessage id='Open timelines'/>
            </Title>
                {!props?.isFetching ? (
                    <Table
                        size="small"
                        dataSource={props?.timeline_info}
                        columns={timelinesColumns}  
                        pagination={{ pageSize: 5 }}   
                        rowKey="package_id"
                    />
                    ) : (
                        <div style={{minHeight:'300px', textAlign:'center'}}>
                            
                            <Spin style={{marginTop: "75px"}} size="large" />
                        
                        </div>
                )}
            </Card>
                   
        </>
    )
}

export default TimelinesTable