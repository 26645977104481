import React, { useEffect, useState } from 'react'
import  {  Result, Button, Modal, message, Row, Col } from "antd"
import { Navigate } from "react-router-dom";
import axios from 'axios'
import { FormattedMessage, useIntl } from 'react-intl'

import { Link } from "react-router-dom"
import domain from "../../../domain"
import { useGetOptionsQuery } from "../../../services/mainApi"


import OfferGallery from './OfferGallery';
import OfferText from './OfferText';
import OfferSelect from './OfferSelect';
import OfferPatents from './OfferPatents';
import OfferSeller from './OfferSeller';
import OfferInformationPrice from "./OfferInformationPrice";
import OfferFiles from './OfferFiles';
import OfferNDA from './OfferNDA';

import SelectExternals from '../../../utils/Externals/SelectExternals'



// in this component we will either create or edit the offer
function OfferCreate(props) {
    const intl = useIntl()

    const { data: options, isFetching } = useGetOptionsQuery()
    const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("userData")))
    

    const [offerId, setOfferId] = useState(null)
    const [toRedirect, setToRedirect] = useState(false)
    const [inProgress, setInProgress] = useState(false)
    

    const [gallery, setGallery] = useState([{file : null, preview: null, reference: "", thumbnail: true}])


    const [title, setTitle] = useState(null)
    const [status, setStatus] = useState(null)
    const [description, setDescription] = useState(null)
    const [files, setFiles] = useState([])
    
    const [selectedTags, selectTag ] = useState([])
    const [selectedCat, selectCat] = useState(null)
    const [selectedLang, selectLang] = useState(null)

    const [selectedSeller, selectSeller] = useState(null)
    
    const [nda, uploadNda] = useState(undefined)
    const [nda_protected, setNdaProtected] = useState(false)

    const [info_price, setInfoPrice] = useState(null)

    const [patentNumbers, setPatentNumbers] = useState([''])
    

    const [externals, setExternals] = useState([])

   

    useEffect(() => {
        props.setBc2(<FormattedMessage id="Offer"/>)
        props.setBc3(<FormattedMessage id="Create" />)
        document.title = "IP2B Create Post"
    }, [])
   
    const Submit = async () => {
      if (title === null || status === null ||
          description === null || selectedCat === null || 
          info_price === null || 
          selectedLang === null || (nda === undefined && nda_protected)) {
          
          const translatedMessage = intl.formatMessage({id: "Missing fields!"})
          message.error(translatedMessage)
      
        } else {
        setInProgress(true)
        
        const context = {
          title : title,
          description : description,
          status : status, 
          language : selectedLang,
          category :  selectedCat,
          tags : selectedTags, 
          nda_protected : nda_protected,
          hasThumbnail : gallery[0].file !== null,  // because first slide is always thumbnail
          hasGallery : gallery.length > 1, // because slides are added only on the moment thumbnail has been given
          hasFiles : files.length > 0,
          hasPatents : patentNumbers.filter(n => n).length > 0,
          patent_numbers : patentNumbers.filter(n => n),
          selected_seller : selectedSeller,  // need to fix this 
          externals : externals,
          info_price : info_price

        }
        
        const formData = new FormData()
        
        
        if (gallery[0].file !== null) {
            gallery.map((item, index) => {
                if (item.thumbnail) {
                  const metadata = {
                    reference : item.reference,
                    name : item.file.name
                  }
                  formData.append("thumbnail", item.file, JSON.stringify(metadata))
                } else {
                  const metadata = {
                    reference : item.reference,
                    name : item.file.name,
                    index : index,
                  }
                  formData.append("gallery[]", item.file, JSON.stringify(metadata))
                }
               
                
            })
        }
        
        if (files.length > 0) {
          files.map(file => {
            formData.append("files[]", file.originFileObj, file.originFileObj.name)
          })
        }
        
        if (nda_protected) {
          formData.append("nda", nda.originFileObj, nda.originFileObj.name)
        }

        formData.append("context", JSON.stringify(context))

        const token = localStorage.getItem("token")
      
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.defaults.xsrfCookieName = "csrftoken"
        axios.defaults.headers = {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`
        }

        const hide = message.loading('Loading...', 0)
   

        await axios.post(`${domain}/api/offer/create/`, formData, { timeout : 50000 })
        .then(res => {
            setOfferId(res.data.data.offer_id)
            hide()
            setInProgress(false)
            setToRedirect(true)
        })
        .catch(err => {
            hide()
            message.error(err)
        })
       
        
        
        
      }
    }
    
    if (!JSON.parse(localStorage.getItem("userData"))?.is_seller) return (<Navigate to="/offers" push />)
    
    return (
    <>


        <Modal
          visible={toRedirect}
          centered
          footer={null}
          onCancel={() => { window.location.reload(false) }}
        >

          <FormattedMessage id='Your offer has been created!'>
                {(title) => {
                    return (
                      <FormattedMessage id="Do you want to redirect to created offer?">
                          {(subTitle) => {
                              return (
                                
                                <Result
                                    status="success"
                                    title={title}
                                    subTitle={subTitle}
                                    extra={[
                                      <>
                                    <Link to={`/offers/detail/${offerId}`} >          
                                        <Button type="primary" key="console">
                                            <FormattedMessage id='Yes, redirect!' />
                                        </Button>
                                      </Link>
                                      
                                      </>
                                    ]}
                                  />
                              )
                          }}
                    </FormattedMessage>
                    )
                }}
          </FormattedMessage>
        

        </Modal>

        
        <OfferGallery {...{gallery, setGallery}}/>
      

        <OfferText {...{ title, setTitle, description, setDescription, status, setStatus }}/>
     

        <OfferSelect {...{selectCat, selectedCat, 
                          selectedLang, selectLang, 
                          selectedTags, selectTag
                            }} />

        <OfferNDA {...{ nda, uploadNda, nda_protected, setNdaProtected }}/>
        

        <OfferInformationPrice {...{ info_price, setInfoPrice }}/>
        
        <OfferPatents {...{ patentNumbers, setPatentNumbers }}/>
   
        
        
        <OfferFiles {...{ setFiles }}/>
      
          
        
        {userData.status === 'Manager' ? (
          <OfferSeller {...{ selectSeller, }}/>
        ) : (
          <SelectExternals setData={setExternals} countries={options?.countries} externals={options?.extra} isFetching={isFetching}/>
        )}
      
        
        <Row className='form-row'>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Button type="primary" 
                            className='success'
                            disabled={inProgress}
                            style={{width: "100%"}}
                            onClick={() => Submit()}
                            >
                            <FormattedMessage id="Submit" />
                </Button>
            </Col>
        </Row>
     
    </>
  )
}

export default OfferCreate