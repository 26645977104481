import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import  {  Skeleton, Space, Result, Button, Modal, message, Row, Col } from "antd"
import axios from 'axios'
import { Navigate } from "react-router-dom";
import { FormattedMessage } from 'react-intl'

import domain from "../../../../domain"
import { Link } from "react-router-dom"


import { useGetOfferToEditQuery } from "../../../../services/mainApi"

import OfferTextEdit from './OfferTextEdit'
import OfferThumbnailEdit from './OfferThumbnailEdit';
import OfferSlidesEdit from './OfferSlidesEdit';
import OfferSelectEdit from './OfferSelectEdit';


function OfferEdit(props) {
    const { offerId } = useParams() 
    const { data: detail, isFetching } = useGetOfferToEditQuery(offerId )
    
    const [toRedirect, setToRedirect] = useState(false)
    const [inProgress, setInProgress] = useState(false)
    const [title, setTitle] = useState("")
    const [status, setStatus] = useState("")
    const [description, setDescription] = useState("")

    // this is the preview, not saved data
    const [thumbnail, setThumbnail] = useState({})
    const [slides, setSlides] = useState([{ url : "", ref : "", id : null, index : null, changed : false, new : true}])

    const [category, selectCategory] = useState(null)
    const [tags, selectTag] = useState([])
    const [language, selectLanguage] = useState(null)

    useEffect(() => {
        setTitle(detail?.translation?.title)
        setStatus(detail?.translation?.status)
        setDescription(detail?.translation?.description)

        setThumbnail({...detail?.slides[0], changed : false })
        setSlides(detail?.slides.slice(1))

        selectCategory(detail?.category[localStorage.getItem("symbol")])
        selectTag(detail?.tags)
        selectLanguage(detail?.translation?.lang)

    }, [isFetching])

  
    const Submit = async () => {
        setInProgress(true)

        const context = {
            title, 
            status, 
            description,
            language, 
            tags,
            category, 
            thumbnail_ref : thumbnail.ref,
            thumbnail_changed : thumbnail.changed,
           
            slide_ids : slides.filter((obj) => { return obj.id }).map(slide => slide.id)
        }
        

        const formData = new FormData()

        if (thumbnail.changed) {
            formData.append("thumbnail", thumbnail.file, thumbnail.file.name)
        }

        
        slides.map((item, index) => {
            const metadata = {
                ref : item.ref,
                id : item?.id,  
                index : index,
                changed : item.changed !== undefined,
                new : item.new !== undefined, 
                name : item?.file?.name 
            }
            console.log(metadata)
            console.log(item)
            if (item.changed) {
                formData.append("slides[]", item.file, JSON.stringify(metadata))
            } else {
                var blob = new File(["data"], 'file.json');
                formData.append("slides[]", blob, JSON.stringify(metadata))
            }
            
        })


        formData.append("context", JSON.stringify(context))
        
        const token = localStorage.getItem("token")
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.defaults.xsrfCookieName = "csrftoken"
        axios.defaults.headers = {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`
        }


        await axios.post(`${domain}/seller/dashboard/offer/edit/${detail.offer_id}/`, formData, { timeout : 50000 })
        .then(res => {
            console.log("Done")
            setToRedirect(true)
        })
        .catch(err => {
            err?.response?.data?.message?.map(mes => {
                message.error(mes)
            })
        })


        setInProgress(false)
    }

    console.log(detail)
    return (    
        <>
            <Modal
                visible={toRedirect}
                centered
                footer={null}
                
            >

            <FormattedMessage id='Your offer has been created!'>
                    {(title) => {
                        return (
                        <FormattedMessage id="Do you want to redirect to created offer?">
                            {(subTitle) => {
                                return (
                                    
                                    <Result
                                        status="success"
                                        title={title}
                                        subTitle={subTitle}
                                        extra={[
                                        <>
                                        <Link to={`/offers/detail/${offerId}`} >          
                                            <Button type="primary" key="console">
                                                <FormattedMessage id='Yes, redirect!' />
                                            </Button>
                                        </Link>
                                        
                                        </>
                                        ]}
                                    />
                                )
                            }}
                        </FormattedMessage>
                        )
                    }}
            </FormattedMessage>
            

            </Modal>
            {isFetching ? (
            
                    <Skeleton.Input style={{ "height" : "150px"}} active={true} size={'large'} block={true}/>
            
                ) : (
            
                    <OfferThumbnailEdit {...{ thumbnail, setThumbnail }} />
            )}
            {!isFetching && (
                <OfferSlidesEdit {...{ slides, setSlides }} />
            )}

            {isFetching ? (
                <>
                    <Skeleton active />
                </>
            ) : (

                <>
                    <OfferTextEdit {...{isFetching, title, setTitle, status, setStatus, description, setDescription }} />
                </>
            )}

          
            
             <OfferSelectEdit {...{isFetching, category, tags, language, selectCategory, selectTag, selectLanguage}}/>
          
            

             <Row className='form-row'>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Button type="btn" 
                                disabled={inProgress || isFetching}
                                style={{width: "100%"}}
                                onClick={() => Submit()}
                                >
                                Submit
                    </Button>
                </Col>
            </Row>
        
        
        </>
    )
}

export default OfferEdit