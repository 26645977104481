import React, { useState, useEffect } from 'react'
import axios from 'axios'

import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"

import domain from '../../domain'
import CheckoutForm from './CheckoutForm'

import './manual.css'

import { Button, Spin, Card } from 'antd'

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// use the publick  key

const stripePromise = loadStripe("pk_test_51C4v3xI5qmISKwcT00uCfVLRv6EFiedomjboSlt1BR8yJU4WbEIlkaszMhayJtB4kOI4JgQjvXFhXosggJJsgDhs00TZCBWb8Q")

const Checkout = (props) => {
    const [clientSecret, setClientSecret] = useState(null)
    
    useEffect(() => {
        const callIntent = async () => {
            const token = localStorage.getItem("token")
          
            axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
            axios.defaults.xsrfCookieName = "csrftoken"
            axios.defaults.headers = {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`
            }
            const context = {
                package_id : props.package_id
            }
            // Create PaymentIntent as soon as the page loads
            await axios.post(`${domain}/users/intent/open/`, context)
            .then(res => {
                setClientSecret(res.data.clientSecret)
            })
        }
        callIntent()
      }, [])
    
      const appearance = {
        theme: 'flat',
        variables: {
            colorPrimary:  "#7367f0",
        },

      }
      const options = {
        clientSecret,
        appearance
      }

    return (
        <div className="App">
            {/* {clientSecret !== null ? ( */}
            <Elements options={options} stripe={stripePromise}>
                <CheckoutForm />
            </Elements>
 
            
      </div>
    )
}

export default Checkout