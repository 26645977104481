import React, { Fragment } from 'react'
import  { Button, Row, Col, Input, Typography, Form } from "antd"

import { FormattedMessage } from 'react-intl'


const { Title, Text } = Typography

function OfferPatents(props) {

    const addFieldPatent = () => {
        props?.setPatentNumbers([...props?.patentNumbers, ''])
      }
  
    const handleDocumentChanagePatent = (i, e) => {
        const patents = [...props?.patentNumbers]
        patents[i] = e.target.value
        props?.setPatentNumbers(patents)
    }

    const deleteFormPatent = (i) => {
        const patents = [...props?.patentNumbers]
        patents.splice(i, 1)
        props?.setPatentNumbers(patents)
    }


    return (
    <>
        <Row gutter={[12,12]} className="patent-card">
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Title level={4}>
                <FormattedMessage id="Patent Family"/>
                </Title>
                <Text>
                <FormattedMessage id="Input the number of the patent, and our patent family crawler will do the rest!"/>
                </Text>
            </Col>
            {props?.patentNumbers.map((item, index) => {
                return (
                    <Fragment key={index}>
                        
                        <Col xl={22} lg={21} md={20} sm={19} xs={18}>
                        
                            <FormattedMessage id="(for example)">
                                {msg => (
                                    <Input 
                                        name='doc_desc'
                                        placeholder={`AU2017277065A1 ${msg}`}
                                        value={props?.patentNumbers[index]}
                                        onChange={(event) => { handleDocumentChanagePatent(index, event) }}
                                        />
                                )}
                            </FormattedMessage>
                           
                    
                        </Col>
                        <Col xl={2} lg={3} md={4} sm={5} xs={6}>
                            <Button type="danger"
                                    ghost
                                    style={{width: "100%"}}
                                    onClick={() => deleteFormPatent(index)}
                                    >
                                        <FormattedMessage id="Remove" />
                            </Button>
                        </Col>
                        
                    </Fragment>
                )
            })}

        </Row>

        <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Button ghost
                        type='primary'
                        style={{width: "100%"}}
                        onClick={() => addFieldPatent()}
                        >
                            <FormattedMessage id="Add Patent" />
                </Button>
            </Col>
        </Row>
    </>
  )
}

export default OfferPatents