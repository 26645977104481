import React, {  useState } from 'react'

import { message, Modal, Result, Row,  Divider, Col, Typography, Button } from "antd"

import axios from 'axios'
import { FormattedMessage } from 'react-intl'

import domain from "../../../domain"


const { Title } = Typography


function BuyNegotiation(props) {
    const [inProgress, setInProgress] = useState(false)
    const [buyNegotiation, setBuyNegotiation] = useState(false)
    
    const handleNegotiation = async () => {
        setInProgress(true)
        const token = localStorage.getItem("token")
        
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.defaults.xsrfCookieName = "csrftoken"
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`
        }
    
        await axios.post(`${domain}/api/package/buy/negotiation/`, {offer_id : props.offerId})
        .then(res => {
            if (res.data.error) {
            message.error(res.data.message)
            } else {
            message.success(res.data.message)
            
            // refresh the userdata that have ipp 
            // the state of them 

            // this will refresh the entire page
            props.setReload(!props.reload)
            props.refetch()

            }
        })
        setBuyNegotiation(false)
        setInProgress(false)
      }

    return (
    <>
          <Modal
                visible={buyNegotiation}
                centered
                footer={null}
                onCancel={() => { setBuyNegotiation(false) }}
                >

                <FormattedMessage id= 'Are you sure?'>
                    {(title) => {
                        return (
                            <FormattedMessage id={`Buying a negotiation package will cost you 1900 IPP weekly! This will allow you to contact the seller etc`}>
                                {(subTitle) => {
                                    return (
                                    
                                    <Result
                                        
                                        title={title}
                                        subTitle={subTitle}
                                        extra={[
                                            <>
                                        
                                            <Button disabled={inProgress || props?.isFetching } type="primary" key="console" onClick={e => { handleNegotiation() }}>
                                                <FormattedMessage id='Yes, buy it!' />
                                            </Button>
                                        
                                            
                                            </>
                                        ]}
                                        />
                                    )
                                }}
                        </FormattedMessage>
                        )
                    }}
                </FormattedMessage>
            </Modal>
            <Row gutter={[6,6]}>
                <Col l={24} lg={24} md={24} sm={24} xs={24}>
                    <Title level={4}>
                      <FormattedMessage id="Buy Negotiation Package"/>
                    </Title>
                </Col>
                <Col l={24} lg={24} md={24} sm={24} xs={24}>
                  <Button type="primary"
                            disabled={inProgress}
                            style={{width: "100%"}}
                            onClick={() => setBuyNegotiation(true)}
                            >
                            <FormattedMessage id="Buy Negotiation Package"/>
                  </Button>
                </Col>
              </Row>
              <Row gutter={[6,6]}>
                    <Col l={24} lg={24} md={24} sm={24} xs={24}>
                        <Divider/>
                        <Title level={5}>
                            <FormattedMessage id="nego-1"/>
                        </Title>
                        <Divider className='man-divider'/>
                        <Title level={5}>
                            <FormattedMessage id="nego-2"/>
                        </Title>
                        <Divider className='man-divider'/>
                        <Title level={5}>
                            <FormattedMessage id="nego-3"/>
                        </Title>
                        <Divider className='man-divider'/>
                        <Title level={5}>
                            <FormattedMessage id="nego-4"/>
                        </Title>
                        <Divider className='man-divider'/>
                        <Title level={5}>
                            <FormattedMessage id="nego-5"/>
                        </Title>

                    </Col>
              </Row>
    </>
  )
}

export default BuyNegotiation