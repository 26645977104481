import React, { useState, useEffect } from 'react'
import { Tabs, Select, Input, Row, Col, Typography } from "antd"

import { Navigate } from 'react-router-dom'

import {useGetManagerDashboardQuery, useGetOptionsQuery, useGetUserInfoQuery } from "../../../services/mainApi"

import VerifyOffersTable from './VerifyOffersTable';
import VerifyMessagesTable from './VerifyMessagesTable';
import VerifyOfferTranslationsTable from './VerifyOfferTranslationsTable';
import VerifyFileTranslationsTable from "./VerifyFileTranslationsTable"
import VerifyMessagesTranslationsTable from './VerifyMessagesTranslationsTable'
import VerifyNdasTable from './VerifyNdasTable';

import { FormattedMessage } from 'react-intl'



function ManagerDashboard(props) {
    const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("userData"))) 

    const {data : manager, isFetching, refetch} = useGetManagerDashboardQuery()
    const { data: options, isFetching:fetchB } = useGetOptionsQuery()

    const [inProgress, setInProgress] = useState(false)

    useEffect(() => {
        props.setBc2(<FormattedMessage id="Manager" />)
        props.setBc3(<FormattedMessage id="Dashboard" />)
    }, [])

    if (userData?.status !== 'Manager') return (<Navigate to="/offers" push />)
    
    return (
       <>        
        
     
        <Row gutter={[24, 24]} className="form-row">
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <VerifyOffersTable offers={manager?.offers} isFetching={isFetching} />
                </Col>

                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <VerifyNdasTable 
                                        ndas={manager?.ndas}
                                        inProgress={inProgress}
                                        setInProgress={setInProgress} 
                                        refetch={refetch}
                                        isFetching={isFetching} />
                </Col>

                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <VerifyMessagesTable 
                                        messages={manager?.messages} 
                                        options={options}
                                        inProgress={inProgress}
                                        setInProgress={setInProgress}
                                        refetch={refetch}
                                        isFetching={isFetching} />
                </Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <VerifyOfferTranslationsTable 
                                        translations={manager?.translations}
                                        inProgress={inProgress}
                                        setInProgress={setInProgress}
                                        refetch={refetch}
                                        isFetching={isFetching} />
                </Col>

                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <VerifyFileTranslationsTable
                                        files={manager?.file_translations}
                                        inProgress={inProgress}
                                        setInProgress={setInProgress}
                                        refetch={refetch}
                                        isFetching={isFetching} />
                </Col>

                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <VerifyMessagesTranslationsTable 
                                        message_translations_to_verify={manager?.message_translations_to_verify}
                                        inProgress={inProgress}
                                        setInProgress={setInProgress}
                                        refetch={refetch}
                                        isFetching={isFetching}/>
                </Col>
        </Row>
       </>
    )
}

export default ManagerDashboard