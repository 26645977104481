import React, { useState, useEffect } from 'react'
import { Alert, Divider, Modal, Input, Dropdown, Menu, Popover, Button, Row, Col, Table, Spin, Typography, message, Card, Tag } from "antd"
import { FormattedMessage } from 'react-intl'
import { Navigate, Link } from 'react-router-dom'

import {useGetTranslatorDashboardQuery} from "../../../services/mainApi"
import TranslationOffer from './TranslationOffer';
import TranslationGiven from './TranslationGiven';
import TranslationMessage from './TranslationMessage';
import TranslationUpdate from './TranslationUpdate';
import TranslationFiles from './TranslationFiles'




function TranslatorDashboard(props) {
    const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("userData"))) 
    const {data : translator, isFetching, refetch} = useGetTranslatorDashboardQuery()


    useEffect(() => {
        props.setBc2(<FormattedMessage id="Translator" />)
        props.setBc3(<FormattedMessage id="Dashboard" />)
    }, [])

    if ((userData.status !== 'Manager') && (userData.status !== 'Translator')) return (<Navigate to="/offers" push />)
   
    return (
        <>

            <Row gutter={[24, 24]} className="form-row">
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <TranslationOffer {...{ refetch, isFetching, offers : translator?.offers }} />
                </Col>

                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <TranslationFiles {...{ refetch, isFetching, files : translator?.files }} />
                </Col>



                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <TranslationUpdate {...{ refetch, isFetching, needs_update : translator?.needs_update}}/>
                </Col>


                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <TranslationMessage {...{ refetch, isFetching, messages : translator?.messages }}/>
                </Col>


                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <TranslationGiven {...{ refetch, isFetching, translations : translator?.translations }}/>
                </Col>
                
                
            </Row>
        </>
    )
}

export default TranslatorDashboard