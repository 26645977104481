import React, { useEffect, useState } from 'react'
import { Layout, Breadcrumb, Button } from 'antd';
import { connect } from 'react-redux'

import { Navigate } from "react-router-dom";
import { GlobalOutlined, ReloadOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl'
import Navbar from "./Navbar"

import { useGetUserInfoQuery } from '../services/mainApi'
import * as actions from "../app/auth/actions"


import "antd/dist/antd.min.css"
import "../main.less"

const { Content, Footer } = Layout;

const CustomLayout = (props) => {
    const intl = useIntl()
    const token = localStorage.getItem("token")
    
    useEffect(() => {
      const func = () => {
        const info_message = intl.formatMessage({id: "Your session has expired!"})
        props.onTryAutoSignup(info_message)
      }
      func()
    })
    if (!token) return (<Navigate to="/login" push />)
    
    return (
      <Layout className="layout" theme="dark">
          
            <Navbar />
              <Content theme="dark">
               
                  <div className={"breadcrumbs"}>
                    <Breadcrumb  style={{ "lineHeight" : "32px" }} separator="/">
                        <Breadcrumb.Item>{props.bc1}</Breadcrumb.Item>
                        <Breadcrumb.Item>{props.bc2}</Breadcrumb.Item>
                        <Breadcrumb.Item>{props.bc3}</Breadcrumb.Item>
                    </Breadcrumb>

                    <div>
                        <Button type='primary' 
                                onClick={() => { window.location.reload() }}>
                            <ReloadOutlined /> <FormattedMessage id='Refresh' />
                        </Button>
                    </div>      

                        
                   
                  </div>
                  
               
               
                  <div className="site-layout-content">
                    
                    {props.children}
                    
                  </div>
              </Content>      
              <Footer theme="dark" style={{ textAlign: 'center' }}>
                COPYRIGHT © {new Date().getFullYear()}{' '}
                <a href='https://ip2b.com' target='_blank' rel='noopener noreferrer'>
                  IP2B
                </a>
                <br/>
                <a onClick={e => props.setLangModal(true)}>
                  <GlobalOutlined /> <FormattedMessage id='Language_Name' />
                </a>
              </Footer>
        
          
      </Layout>
  )
}


const mapStateToProps = state => {
  return {
    auth : state.auth
  }
}



const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: (info_message) => dispatch(actions.authCheckState(info_message))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomLayout);
