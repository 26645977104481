import React from 'react'
import  { Button, Row, Col,  Upload } from "antd"
import { UploadOutlined} from '@ant-design/icons';

import { FormattedMessage } from 'react-intl'


function OfferFiles(props) {
 
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
          onSuccess("ok");
        }, 0);
      };

    return (
        <>
            <Row gutter={[12, 12]} className="document-card">
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Upload 
                        customRequest={dummyRequest}
                        onChange={e => { props?.setFiles(e.fileList) }}
                        
                        >
                        <Button icon={<UploadOutlined />}> <FormattedMessage id="Upload Documents"/></Button>
                    </Upload>
                </Col>
            </Row>
        </>
    )

}

export default OfferFiles