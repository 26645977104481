import React, { useState } from 'react'
import  { Checkbox,  Button, Space, message, Row, Col, Input, Upload } from "antd"
import { LoadingOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';

import { FormattedMessage } from 'react-intl'


function OfferSlidesEdit(props) {
    const [loading, setLoading] = useState(false)

    const addSlides = () => {
        props.setSlides([...props.slides, {file : null, url: null, ref: "", changed : false, new : true}])
    }

    const deleteSlide = (i) => {
        const slides = [...props?.slides]
        slides.splice(i, 1)
        props?.setSlides(slides)
    }

    function getBase64Thumbnail(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    function beforeUploadSlide(file, index) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        } else {
   
            let oldSlide = props?.slides[index]
            const newSlide = {
                ...oldSlide,
                file : file, 
                changed : true, 
            }
            let newSlides = props?.slides 
            newSlides[index] = newSlide
            props.setSlides(newSlides)
        }
        
        return isJpgOrPng;
    }


    const handleChangeSlide = (info, index) => {
        if (info.file.status === 'uploading') {
            setLoading(true)
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64Thumbnail(info.file.originFileObj, thumbnail =>
            {
           
                let oldSlide = props?.slides[index]
                const newSlide = {
                    ...oldSlide,
                    url : thumbnail,
                }
                let newSlides = props?.slides 
                newSlides[index] = newSlide
                props.setSlides(newSlides)
                setLoading(false)
            },
            );
        }
        }


    const changeReferenceSlide = (ref, index ) => {
        
   
        let slide = {...props?.slides[index], ref}

        let newSlides = [...props?.slides]
        newSlides[index] = slide 
        props?.setSlides(newSlides)


    }


    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
          onSuccess("ok");
        }, 0);
    };
    return (
        <>
            <Row className='form-row'>
                <Space align="start" style={{ "overflowX" : "scroll"}}>
                    {props?.slides?.map((e, index) => {
                        return (
                            <>
                                
                                <Row style={{"width" : "208px", "paddingBottom" : "25px"}}  gutter={[0,12]}>
                                    <Col lg={24}>
                                        <Upload
                                        
                                            name={`gallery-${index}`}
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={false}
                                            
                                            beforeUpload={(file) => { beforeUploadSlide(file, index) }}
                                            onChange={(file) => { handleChangeSlide(file, index) }}
                                            customRequest={dummyRequest}
                                        >
                                            {e.url ? <img src={e.url} alt="avatar" style={{ height: "100%", maxWidth: "100%" }} /> : (
                                            <div>
                                                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                                                <div style={{ marginTop: '20px' }}>
                                                    Upload
                                                </div>
                                            </div>)}
                                        </Upload> 
                                    </Col>
                                    
                                    <Col lg={24} style={{display: "inline-flex"}}>
                                    
                                        <Input 
                                            style={{ marginRight: "5px"}}
                                            name='status'
                                            value={props?.slides[index]['ref']}
                                            placeholder={`Reference`}
                                            disabled={props?.slides[index]['url'] === null}
                                            onChange={(e) => { changeReferenceSlide(e.target.value, index) }}
                                            />
                                            <Button  
                                                onClick={() => { deleteSlide(index) }}
                                                type='primary' danger >
                                                <CloseOutlined />
                                            </Button>
                                
                                    </Col>
                                    
                                   
                               
                                </Row>
                            </>
                        )
                    })}
                    

                    <Button primary onClick={() => { addSlides() }}>
                        Add Slides
                    </Button>
                
                </Space>
            </Row>
        </>
    )
}

export default OfferSlidesEdit