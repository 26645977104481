import React, { useEffect, useState } from 'react'
import { Layout, message, Avatar, Menu, Breadcrumb } from 'antd';
import { Link } from "react-router-dom"
import { connect } from 'react-redux'
import {
    DesktopOutlined,
    StarOutlined,
    PoweroffOutlined,
    ApiOutlined,
    FileAddOutlined,
    UserOutlined,
    FireOutlined,
    AppstoreOutlined,
    EditOutlined,
    MacCommandOutlined,
    FormOutlined, 
    GlobalOutlined,
    CopyOutlined
  } from '@ant-design/icons';
import * as actions from "../app/auth/actions"
import { Navigate } from "react-router-dom";
import { FormattedMessage } from 'react-intl'

import { useGetUserInfoQuery } from "../services/mainApi"

import logo from '../images/logo.png'

const { Header } = Layout;
const { SubMenu } = Menu;


function Navbar(props) {
    const [loggedIn, setLoggedIn] = useState(localStorage.getItem("token") !== null)

    const { data, isFetching } = useGetUserInfoQuery()

    const [userData, setUserData] = useState({})

    useEffect(() => {
        
        if (isFetching) {
            const rawData = localStorage.getItem("userData") 

            // if not null
            if (rawData !== undefined && rawData !== null) {
                setUserData(JSON.parse(rawData))
            }

        } else {
            setUserData(data)
            localStorage.setItem("userData", JSON.stringify(data))
        }

    }, [isFetching])


    return (
        <Header className='navbar'>
            <img className="logo" src={logo}/>
            
            {!loggedIn && <Navigate to={`/login`} push />}
            <Menu mode="horizontal" >
                <Menu.Item key={"homepage"} icon={<FireOutlined />}>
                    <Link to="/">
                    
                        <FormattedMessage id="Homepage"/>
                        
                    </Link>
                </Menu.Item>

                    <Menu.Item key={"offers"} icon={<CopyOutlined />}>
                        <Link to="/offers">
                            <FormattedMessage id="Offers"/>
                        </Link>
                    </Menu.Item>

                    
                    
                    {userData?.is_seller ? (
                        <>
                            <Menu.Item key="create-offer" icon={<FormOutlined />}>
                                    <Link to="/offers/create">
                            
                                        <FormattedMessage id="Create Offer"/>
                                    </Link>  
                            </Menu.Item>
                            <Menu.Item key="seller-dashboard" icon={<AppstoreOutlined />}>
                                    <Link to="/personel/seller-dashboard">
                                        <FormattedMessage id="Seller Dashboard"/>
                                    </Link>  
                            </Menu.Item>
                        </>
                    ) : null}
                    
            
                    {userData?.status === 'Manager' ? (
                        <>
                            <Menu.Item key="manager-dashboard" icon={<MacCommandOutlined />}>
                                    <Link to="/personel/manager-dashboard">
                            
                                        <FormattedMessage id="Manager Dashboard"/>
                                    </Link>  
                            </Menu.Item>
                        
                        </>
                    ) : null}

                    {(userData?.status === 'Manager') || (userData?.status === 'Translator') ? (
                        <>
                            <Menu.Item key="translator-dashboard" icon={<GlobalOutlined />}>
                                    <Link to="/personel/translator-dashboard">
                                        <FormattedMessage id="Translator Dashboard"/>
                                    </Link>  
                            </Menu.Item>
                        </>
                    ) : null}
            
                    
                    

                    <SubMenu key="posts"  title={userData?.header} icon={ <Avatar shape="square" size={32} src={userData?.picture} />} style={{ marginLeft: 'auto' }}>
                        <Menu.Item key={"profile"} icon={<UserOutlined />} >
                            <Link to="/profile" >
                                
                                <FormattedMessage id="Profile" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item key={"recharge"} icon={<ApiOutlined />}>
                            <Link to="/recharge-ipp">
                                
                                <FormattedMessage id="Recharge IPP" />

                            </Link>
                        </Menu.Item>
                        <Menu.Item key={"logout"} icon={<PoweroffOutlined />}>
                            <Link to="#" onClick={() => { 
                                props.logout() 
                                setLoggedIn(false)
                                return (
                                    <Navigate to={`/login`} push />
                                )
                            }}>
                                
                                <FormattedMessage id="Logout" />

                            </Link>
                        </Menu.Item>
                    
                    </SubMenu>

                    
                
                    
                
            </Menu>
        </Header>
    )
}

const mapStateToProps = state => {
    return {
      auth : state.auth
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        logout : () => dispatch(actions.logout())
    }
  }
  

  export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
