import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Modal, Alert, Button, Row, Tag, Col, Card, Typography, Space, Form, message } from "antd";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js"
import Checkout from './Checkout'
import PaymentStatus from './PaymentStatus';
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"


const { Title, Text } = Typography


const stripePromise = loadStripe("pk_test_51C4v3xI5qmISKwcT00uCfVLRv6EFiedomjboSlt1BR8yJU4WbEIlkaszMhayJtB4kOI4JgQjvXFhXosggJJsgDhs00TZCBWb8Q")



function RechargeIPP(props) {

    const payments = JSON.parse(localStorage.getItem('userData')).payment_packages


    const [modal, setModal] = useState(false)
    const [package_id, setPackageId] = useState(null)
    const [ipps, setIpps] = useState(null)

    useEffect(() => {
        props.setBc2("IPP")
        props.setBc3(<FormattedMessage id="Recharge" />)

        

    }, [])



    return (
        <>
        
           <FormattedMessage id='Buying'>
                {(msg) => {
                    return (
                        <Modal title={<div className='bright-header' style={{textAlign: "center"}}>{msg} {ipps} IPPS </div>} 
                            visible={modal} 
                            onOk={() => { setModal(false) }} 
                            onCancel={() => { setModal(false) }}
                            footer={null}
                            >
                            
                            <Checkout package_id={package_id}/>

                        </Modal>
                    )
                }}
           </FormattedMessage>
           
            
            <Row gutter={[24,24]}>
                <Elements stripe={stripePromise}>
                    <PaymentStatus />
                </Elements>
                {payments.map((item, i) => {
                    return (
                        <Col xl={8} lg={8} md={8} sm={12} xs={24} key={i}>
                            <Card hoverable style={{textAlign: "center"}} size={20}>
                                <Space align="center" direction='vertical'>
                                   

                                    <img style={{ height: '75px'}} src={item.image}></img>  
                                    <Title level={4} strong className='buy-ipp-header'>
                                        {item.name}
                                    </Title>
                                    <Text level={4} >
                                        {item.ipps} IPP <FormattedMessage id='for'/> {item.price}€
                                    </Text>
                                  
                                    <Button style={{width: "100%", color : "white", background: "#7367f0", border:"none"}} onClick={e => {
                                        setModal(true)
                                        setPackageId(item.package_id)
                                        setIpps(item.ipps)
                                    }}>
                                        
                                        <FormattedMessage id='Buy'/>
                                    </Button>  
                                  

                                        
                                </Space>
                              
                            </Card>
                        </Col>
                    )
                })}
            </Row>

        </>
    )
}

export default RechargeIPP