import React, {  useState } from 'react'

import { Button, Tabs, Input, Row, message, Col, Typography } from "antd"


import axios from 'axios'
import { FormattedMessage } from 'react-intl'

import domain from "../../../domain"


const { Title, Text } = Typography
const { TabPane } = Tabs;
const { TextArea } = Input;

function OfferOverview(props) {
    // this will be the reject message
    const [rejectionMessage, setRejectionMessage] = useState(null)
    const [inProgress, setInProgress] = useState(false)

    const handleOverviewSubmit = async (rejected) => {
        setInProgress(true)
        const token = localStorage.getItem("token")
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.defaults.xsrfCookieName = "csrftoken"
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`
        }
        const context = {
            rejected : rejected,
            message : rejectionMessage, 
            files : props?.files, 
        }
        await axios.post(`${domain}/manager/dashboard/overview/${props.offerId}/`, context)
        .then(res => {
            if (res.data.error) {
                message.error(res.data.message)
            } else {
                message.success(res.data.message)
            }
        })
        .catch(err => {
            message.error(err)
        })


        setInProgress(false)
        props.setReload(!props.reload)
    }

    return (
    <>
        <Row gutter={[6,6]}>
            <Col l={24} lg={24} md={24} sm={24} xs={24}>
                <Title level={4}>
                <FormattedMessage id="Review the offer"/>
                </Title>
            </Col>

            {/* offer status */}
            {(props.status === 'd') ? (
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Tabs defaultActiveKey="1">
                    <TabPane style={{borderBottomStyle:'none'}}
                    tab={
                        <span>
                            <FormattedMessage id="Accept"/>
                        </span>
                    }
                    key="1"
                    >
                    
                        <Button type="btn"
                            disabled={inProgress}
                            className='mt-1'
                            style={{width: "100%", color : "white", background: "#7367f0" }}
                            onClick={() => handleOverviewSubmit(false)} // false because acccepting
                            >
                            <FormattedMessage id="Accept"/>
                        </Button>
                    </TabPane>
                    <TabPane
                    tab={
                        <span>
                        
                            <FormattedMessage id="Reject"/>
                        </span>
                    }
                    key="2"
                    >
                    <FormattedMessage id='Description of rejection'>
                        {msg => (
                            <TextArea rows={3} className='mb-1 mt-1' placeholder={msg} onChange={e => { setRejectionMessage(e.target.value) }} />
                        )}

                    </FormattedMessage>
                    
                    <Button type="primary"
                            style={{width: "100%"}}
                            disabled={inProgress}
                            danger
                            onClick={() => handleOverviewSubmit(true)} // true because rejecting
                            >
                            <FormattedMessage id="Reject"/>
                        </Button>

                    </TabPane>
                    </Tabs>
                </Col>
            ) : (
                <Col l={24} lg={24} md={24} sm={24} xs={24}>
                    {props?.status == 'v' ? ('Offer Passed!') : ('Offer Rejected!')}
                </Col>
            )}
        </Row>
    </>
  )
}

export default OfferOverview