
import React, { useState, useEffect } from 'react'
import { Tabs, Divider, Select, Checkbox, Input, Modal, Avatar, Space, Dropdown, Menu, Popover, Button, Row, Col, Table, Spin, Typography, message, Card, Tag } from "antd"
import { FormattedMessage } from 'react-intl'
import { Navigate, Link } from 'react-router-dom'

import axios from "axios"
import domain from "../../../domain"
import HTMLReactParser from "html-react-parser"

import { ExperimentOutlined, DoubleRightOutlined, EditOutlined, PrinterOutlined, FormOutlined, QuestionCircleOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;
const { Title, Text } = Typography
const { TextArea } = Input;

function VerifyMessagesTranslationsTable(props) {

    const [inProgress, setInProgress] = useState(false)

    const [message_modal, setMessageModal] = useState(false)
    const [message_item, setMessageItem] = useState({})

    const [rejection, setRejection] = useState("")


    const messageTranslationColumns = [
        {
            title: <FormattedMessage id='Translated Content'/>,
            key: 'target_content',
            dataIndex: 'target_content'
        },
        {
            title: <FormattedMessage id='Original Content'/>,
            key: 'original_content',
            dataIndex: 'original_content'
        },
        {
            title: <FormattedMessage id='Original Language'/>,
            key: 'original',
            dataIndex: 'original'
        },
        {
            title: <FormattedMessage id='Translation language'/>,
            key: 'target',
            dataIndex: 'target'
        },
        {
            title: <FormattedMessage id='Actions'/>,
            render: row => (
                <>
                    <Dropdown overlay={
                        <Menu>
                         
                         <Menu.Item key={'overview'}>
                         <a onClick={e => {
                            setMessageItem(row)
                            setMessageModal(true)
                         } }>
                            <ExperimentOutlined /> <FormattedMessage id="Overview"/>
                         </a>
                         </Menu.Item>
                     </Menu>
                    } placement="bottomRight" arrow trigger={['click']}>
                        <Button className='btn-icon' color='primary'>
                            <FormOutlined />
                        </Button>
                    </Dropdown>
                </>
            )
        }
    ]

    const verifyTranslation = async (rejected) => {
        setInProgress(true)

        const context = {
            ...message_item, 
            rejected,
            rejection_message : rejection,
        }
        const token = localStorage.getItem("token")
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.defaults.xsrfCookieName = "csrftoken"
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`
        }

        await axios.post(`${domain}/manager/dashboard/request/translation/verify/`, context)
        .then(res => {
       
                message.success(res.data.message)
                setMessageModal(false)
                setMessageItem({})
                setRejection("")
                props?.refetch()
        })
        .catch(err => {
            err?.response?.data?.message?.map(errors => {
                message.error(errors.join(", "))
            })
        
        })


        setInProgress(false)
    }

    return (
        <>
            <Modal
                visible={message_modal}
                title={<FormattedMessage id="Message"/>}
                centered
                width={"50%"}
                footer={null}
                onCancel={() => { setMessageModal(false) }}
                
            >
                <Row gutter={[24,24]}>
                    <Col xl={14} lg={14} md={14} sm={14} xs={14}>
                        <Row gutter={[24,24]}>
        
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>

                                    <Title level={5}><FormattedMessage id="Original Message"/></Title>
                                    <Text>{message_item.original_content}</Text>

                            </Col>
                            <Col xl={24} lg={24} md={24} sm={24} xs={24} className='mt-1'>

                                    <FormattedMessage id="Edit Message">
                                        {(msg) => {
                                            return (
                                                <>
                                                <Title level={5}>{msg}</Title>
                                                <TextArea rows={4} placeholder={msg} value={message_item?.target_content} onChange={e => { 
                                                    setMessageItem({...message_item, target_content : e.target.value}) 
                                                }}/>
                                                </>
                                            )
                                        }}
                                    </FormattedMessage>

                            </Col>
                        </Row>
                    </Col>
                    <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                        <Row gutter={[24,24]}>
                            <Col l={24} lg={24} md={24} sm={24} xs={24}>
                                <Title level={4}>
                                <FormattedMessage id="Review the offer"/>
                                </Title>
                            </Col>
                            <Col l={24} lg={24} md={24} sm={24} xs={24}>
                                <Tabs defaultActiveKey="1">
                                <TabPane style={{borderBottomStyle:'none'}}
                                tab={
                                    <span>
                                        <FormattedMessage id="Accept" />
                                    </span>
                                }
                                key="1"
                                >
                                
                                    <Button type="primary"
                                        className='mt-1'
                                        disabled={inProgress}
                                        style={{width: "100%" }}
                                        onClick={() => verifyTranslation(false)} // false because acccepting
                                        >
                                        <FormattedMessage id="Accept"/>
                                    </Button>
                                </TabPane>
                                <TabPane
                                tab={
                                    <span>
                                        <FormattedMessage id="Reject" />
                                    </span>
                                }
                                key="2"
                                >
                                <TextArea rows={3} className='mb-1 mt-1' placeholder='Description of rejection' onChange={e => { setRejection(e.target.value) }} />
                                
                                <Button type="primary"
                                        style={{width: "100%"}}
                                        disabled={inProgress || rejection === ""}
                                        danger
                                        onClick={() => verifyTranslation(true)} // true because rejecting
                                        >
                                        <FormattedMessage id="Reject"/>
                                    </Button>

                                </TabPane>
                                </Tabs>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                

            </Modal>
        
            <Card className="news-card">
                <Title level={5} strong>
                    <FormattedMessage id="Translations of messages to be verificated"/>
                </Title>
                    {!props?.isFetching ? (
                        <Table
                            size="small"
                            dataSource={props?.message_translations_to_verify}
                            columns={messageTranslationColumns}  
                            pagination={{ pageSize: 5 }} 
                            rowKey="request_id"  
                        />
                        ) : (
                            <div style={{minHeight:'300px', textAlign:'center'}}>
                                
                                <Spin style={{marginTop: "75px"}} size="large" />
                            
                            </div>
                    )}
            </Card>
        </>
    )
}

export default VerifyMessagesTranslationsTable