import axios from 'axios'
import { FormattedMessage, useIntl } from 'react-intl'

import * as actionTypes from './actionTypes'
import { message } from "antd"
import domain from '../../domain'


export const authStart = () => {
    return {
        type : actionTypes.AUTH_START
    }
}

export const authSuccess = (token, email) => {
    return {
      type : actionTypes.AUTH_SUCCESS,
      token : token,
      email : email,
      login_success : true
    }
}

export const authFail = error => {
    
    message.error(error)

    return {
      type : actionTypes.AUTH_FAIL,
      error : error
    }
  }


export const authLogout = () => {
    return {
      type : actionTypes.AUTH_LOGOUT
    }
  }


async function getExtraData() {
    axios.defaults.headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }

    await axios.get(`${domain}/users/profile/info/`)
    .then(res =>  {
      localStorage.setItem("symbol", res.data.preferred_language.symbol)
      localStorage.setItem('userData', JSON.stringify(res.data))
    })
    .catch(err => {
     
      message.error(err)
    })
}


// one hour from now 
export const checkAuthTimeout = expirationTime => {
  return dispatch => {
      setTimeout(() => {
          dispatch(logout());
      }, expirationTime * 1000)
  }
}




export const login = (email, password) => {
    return async dispatch => {
        
      dispatch(authStart())


      axios.defaults.headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

      await axios.post(`${domain}/users/rest-login/`, {
        email: email.toLowerCase(),
        password: password
      }) // maybe this async await will work?
      .then(async res => {
        
        message.success(res.data.message)
        
        await getExtraData()
        const token = res.data.data.key
        const expirationDate = new Date(new Date().getTime() + 3600 * 1000);

        localStorage.setItem('token', token)
        localStorage.setItem('email', email)
        localStorage.setItem('expirationDate', expirationDate);
      
        dispatch(authSuccess(token, email))
        
      })
      .catch(err => {
        
          message.error(err?.response?.data?.message)
      })
      
    }
}


export const register = (email, password, encrypted, status) => {
    return async dispatch => { 
        let success = false 
        const context = {
            status : status, 
            password : password 
        }

        axios.defaults.headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }

        await axios.post(`${domain}/users/rest-register/invite/${encrypted}/`, context)
        .then(res => {
           
            message.success(res.data.message)
            success = true 
        })
        .catch(err => {
       
          err?.response?.data?.message?.map(mes => {
              message.error(mes)
          })
        })

        dispatch({
          type: actionTypes.AUTH_REGISTER,
          register_success : success
        })
    }
}


export const forgotPassword = (email) => {
    return async dispatch => {
        let success = false 

        axios.defaults.headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
    
        await axios.post(`${domain}/users/password/forgot/`, { email })
        .then(res => {
          
            message.success(res.data.message)
            success = true 
        })
        .catch(err => {
          err?.response?.data?.message?.map(mes => {
              message.error(mes)
          })
        })
    
        dispatch({
          type: actionTypes.AUTH_FORGOT,
          forgot_success : success
        })


    }
}


export const resetPassword = (password, encrypted) => {
  return async dispatch => {
    let success = false 
    const context = {
        password,
        encrypted, 
    }
    axios.defaults.headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }

    await axios.post(`${domain}/users/password/reset/`, context)
    .then(res => {
       
        message.success(res.data.message)
        success = true 
    })
    .catch(err => {
   
      err?.response?.data?.message?.map(mes => {
          message.error(mes)
      })
    })

    dispatch({
      type: actionTypes.AUTH_RESET,
      reset_success : success
    })


  }
}

export const logout = () => {
    return dispatch => {
      // ** Remove userData, email, token from storage
      localStorage.removeItem('token')
      localStorage.removeItem('email')
      localStorage.removeItem('userData')
      localStorage.removeItem('expirationDate')
      dispatch(authLogout())
    }
}


export const authCheckState = (info_message) => {
  return dispatch => {
      const token = localStorage.getItem('token');
      const email = localStorage.getItem('email');

      if (token === null || 
          token === undefined) {
          dispatch(logout());
      } else {

          const expirationDate = new Date(localStorage.getItem('expirationDate'));
          // check if expiration date has passed, if not then renew it to 1 more hour, else logout
          if (expirationDate <= new Date() ) {
              
              message.info(info_message)
              dispatch(logout());
          } else {
              // renew it for 30 more seconds 
              const newExpirationDate = new Date(new Date().getTime() + 3600 * 1000);
              localStorage.setItem('expirationDate', newExpirationDate);
          }
      }
  }
}