import React, { useState } from 'react'

import { Tabs, Modal, Row, Col, Input, Upload, Dropdown, Menu, Popover, Button, Table, Spin, Typography, message, Card, Tag } from "antd"
import { FullscreenOutlined, FormOutlined, DownOutlined, UploadOutlined, DownloadOutlined } from '@ant-design/icons';

import { FormattedMessage } from 'react-intl'
import moment from 'moment';
import axios from 'axios';
import domain from '../../../domain';

const { Title, Text } = Typography
const { TextArea } = Input;
const { TabPane } = Tabs;

function VerifyFileTranslationsTable(props) {
    const [modal, setModal] = useState(false)
    const [file, setFile] = useState(null)

    const [rejection, setRejection] = useState('')

    const filesColumns = [
        {
            title: <FormattedMessage id='File name'/>,
            key: 'name',
            dataIndex: 'name'
        }, 
        {
            title: <FormattedMessage id='Language given'/>,
            key: 'original',
            dataIndex: 'original'
        },
        {
            title: <FormattedMessage id='Translation required'/>,
            key: 'target',
            dataIndex: 'target'
        },
        {
            title: <FormattedMessage id='Date Requested'/>,
            defaultSortOrder: 'ascend',
            sorter: (a, b) => moment(b.date_requested).unix() - moment(a.date_requested).unix(),
            render: row => {
               
                const date_requested = new Date(row.date_requested)
                const symbol = localStorage.getItem("symbol")
                return (
                    <>
                        {date_requested.toLocaleString(symbol)}
                    </>
                )
            }
           
        },
        {
            title: <FormattedMessage id='Original File'/>,
            render: row => {
                return (
                   
                    <a href={row.original_file} 
                        style={{marginLeft: '10px'}} 
                        download >
                        Download <DownloadOutlined/>
                    </a>
                   
                )
            }
           
        },
        {
            title: <FormattedMessage id='Translated File'/>,
            render: row => {
                return (
                    // if not drafted, show the translated file
                   
                    <a href={row.translated_file} style={{marginLeft: '10px'}} download >
                        Download <DownloadOutlined/>
                    </a>
                )
            }
           
        },
        {
            title: <FormattedMessage id='Actions'/>,
            width:'100px',
            render: row => {

                return (
                    <>
                        <Dropdown overlay={
                            <Menu>
                             
                                <Menu.Item key={'Ban'}>
                                    <a onClick={e => {
                                            setFile(row)
                                            setModal(true)
                                        } }>
                                        <FullscreenOutlined /> <FormattedMessage id='Overview'/>
                                    </a>
                                </Menu.Item>


                            </Menu>
                            } placement="bottomRight" arrow trigger={['click']}>
                            <Button className='btn-icon' color='primary'>
                                <FormOutlined />
                            </Button>
                        </Dropdown>

                    </>
                )
            }
        }
    ]

    const verifyFile = async (rejected) => {
        props?.setInProgress(true)
       
        const context = {
            rejection,
            rejected
        }

        const token = localStorage.getItem("token")
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.defaults.xsrfCookieName = "csrftoken"
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`
        }

        await axios.post(`${domain}/api/file/translation/verify/${file.request_id}/`, context)
        .then(res => {
            message.success(res.data.message)
            props?.refetch()
            setModal(false)
            setRejection("")
        })
        .catch(err => {
            err?.response?.data?.message?.map(mes => {
                message.error(mes)
            })
            
        })
        props?.setInProgress(false)

    }

    return (     
        <>
            <Modal
                visible={modal}
                title={null}
                centered 
                footer={null}
                onCancel={() => { setModal(false) }}
                >
                <Row gutter={[24,24]}>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    
                        <Tabs centered defaultActiveKey="1">
                                <TabPane 
                                    tab={
                                        <span>
                                        
                                            <FormattedMessage id="Accept"/>
                                        </span>
                                    }
                                    key="1"
                                    >
                                    
                                    <Button type="primary"
                                        className='mt-1'
                                        disabled={props?.inProgress}
                                        style={{width: "100%" }}
                                        onClick={() => verifyFile(false)} // false because acccepting
                                        >
                                        <FormattedMessage id="Accept"/>
                                    </Button>
                                </TabPane>
                                
                                <TabPane
                                tab={
                                    <span>
                                        <FormattedMessage id="Reject"/>
                                    </span>
                                }
                                key="2"
                                >
                                <TextArea rows={3} className='mb-1 mt-1' placeholder='Reason of rejection' onChange={e => { setRejection(e.target.value) }} />
                                
                                <Button type="primary"
                                        style={{width: "100%"}}
                                        disabled={props?.inProgress || rejection === ""}
                                        danger
                                        onClick={() => verifyFile(true)} // true because rejecting
                                        >
                                        <FormattedMessage id="Reject"/>
                                    </Button>

                                </TabPane>
                        </Tabs>

                    </Col>

                </Row>
            </Modal>

            <Card className="news-card">
                <Title level={5} strong>
                    <FormattedMessage id="Verify File Translations" />
                </Title>
                    {!props.isFetching ? (
                        <Table
                            size="small"
                            dataSource={props?.files}
                            columns={filesColumns}  
                            pagination={{ pageSize: 5 }} 
                            rowKey="request_id"  
                        />
                        ) : (
                            <div style={{minHeight:'300px', textAlign:'center'}}>
                                
                                <Spin style={{marginTop: "75px"}} size="large" />
                            
                            </div>
                    )}
            </Card>

        </>
    )
}

export default VerifyFileTranslationsTable